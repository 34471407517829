var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { createPlaybook, deletePlaybook, forceDeletePlaybook, getPlaybooks, } from "./common/endpoints";
import BaseTable2 from "./base_table2";
import classNames from "./class_names";
import RightDrawer from "./right_drawer";
import EditPlaybook from "./edit_playbook";
import BaseModal from "./base_modal";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { UserContext } from "./App";
import { useMutation, useQuery, useQueryClient } from "react-query";
import WaitingSpinner from "./waiting_spinner";
import { CenterScreen } from "./settings/styled_components";
const PlaybookTable = () => {
    var _a;
    const navigate = useNavigate();
    const { userIsStaff } = useContext(UserContext);
    const location = useLocation();
    const queryClient = useQueryClient();
    const { data: playbooks, isLoading: playbooksLoading, isError: playbooksError, } = useQuery(["playbooks"], () => getPlaybooks());
    const [selectedPlaybookId, setSelectedPlaybookId] = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.playbookId) || null);
    const [playbookDeleteErrors, setPlaybookDeleteErrors] = useState("");
    const [playbookIdToBeForceDeleted, setPlaybookIdToBeForceDeleted] = useState(null);
    const [showAddPlaybookModal, setShowAddPlaybookModal] = useState(false);
    const selectedPlaybook = useMemo(() => playbooks === null || playbooks === void 0 ? void 0 : playbooks.find((playbook) => playbook.id === selectedPlaybookId), [playbooks, selectedPlaybookId]);
    const createPlaybookWrapper = (name) => __awaiter(void 0, void 0, void 0, function* () {
        return yield createPlaybook(name, { teams: [], relevantStages: [] });
    });
    const playbookDelete = useMutation({
        mutationFn: deletePlaybook,
        onSuccess: (props) => {
            var _a, _b;
            if (props.calls) {
                setPlaybookDeleteErrors(`This action will remove playbooks from: ${props.calls.length} call(s), like: ${((_a = props.calls[0]) === null || _a === void 0 ? void 0 : _a.name) || ""} at ${((_b = props.calls[0]) === null || _b === void 0 ? void 0 : _b.call_time) || ""}`);
                setPlaybookIdToBeForceDeleted(selectedPlaybookId);
            }
            else {
                queryClient.setQueryData(["playbooks"], (oldData) => {
                    return oldData
                        ? oldData.filter((playbook) => playbook.id !== selectedPlaybookId)
                        : [];
                });
                setSelectedPlaybookId(null);
            }
        },
    });
    const playbookForceDelete = useMutation({
        mutationFn: forceDeletePlaybook,
        onSuccess: (props) => {
            queryClient.setQueryData(["playbooks"], (oldData) => {
                return oldData
                    ? oldData.filter((playbook) => playbook.id !== selectedPlaybookId)
                    : [];
            });
            setPlaybookIdToBeForceDeleted(null);
            setPlaybookDeleteErrors("");
            setSelectedPlaybookId(null);
        },
    });
    const playbookCreate = useMutation({
        mutationFn: createPlaybookWrapper,
        onSuccess: (playbook) => {
            queryClient.setQueryData(["playbooks"], (oldData) => {
                return oldData ? [...oldData, playbook] : [playbook];
            });
            setShowAddPlaybookModal(false);
            setSelectedPlaybookId(playbook.id);
        },
    });
    if (playbooksLoading || playbooksError || !playbooks) {
        return (_jsxs(CenterScreen, { children: [_jsx(WaitingSpinner, { text: "Loading tracker sets..." }), ";"] }));
    }
    return (_jsxs("div", Object.assign({ className: classNames("p-8", selectedPlaybookId ? "w-3/4" : "w-full") }, { children: [_jsx(BaseTable2, { title: "Tracker sets", columns: [
                    {
                        header: "Name",
                        accessorKey: "name",
                    },
                    {
                        header: "Trackers",
                        accessorKey: "playbook_item_groups.length",
                    },
                ], rows: playbooks, expandCallback: (playbook) => {
                    setSelectedPlaybookId(playbook.id);
                }, addCallback: () => {
                    setShowAddPlaybookModal(true);
                }, additionalActions: userIsStaff
                    ? [
                        {
                            label: (_jsxs("div", Object.assign({ className: "flex flex-row gap-1 font-bold" }, { children: ["Import/Export", _jsx(ArrowTopRightOnSquareIcon, { className: "w-5 h-5" })] }))),
                            disabled: false,
                            callback: () => navigate("/import_export/trackers"),
                        },
                    ]
                    : [] }), selectedPlaybook && (_jsx(RightDrawer, Object.assign({ title: selectedPlaybook.name, isOpen: !!selectedPlaybook, onClose: () => setSelectedPlaybookId(null), deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield playbookDelete.mutateAsync(selectedPlaybook.id);
                }) }, { children: _jsx(EditPlaybook, { playbook: selectedPlaybook, refetchCallback: () => queryClient.invalidateQueries("playbooks") }) }))), _jsx(BaseModal, { title: "Add Tracker set", description: "A tracker set defines the agenda for the call, helping you stay on track and collect the information you need. The individual trackers will be populated by content automatically as the call happens.", open: showAddPlaybookModal, setOpen: setShowAddPlaybookModal, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    yield playbookCreate.mutateAsync(fields[0].value);
                }), fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: "",
                        placeholder: "Name of this tracker set",
                        required: true,
                    },
                ], submitText: "Create Tracker set", reactQuery: true }), playbookIdToBeForceDeleted ? (_jsx(BaseModal, { title: "Delete playbook warning", description: playbookDeleteErrors, open: !!playbookIdToBeForceDeleted, setOpen: () => setPlaybookIdToBeForceDeleted(null), fields: [], submitCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    playbookForceDelete.mutate(playbookIdToBeForceDeleted);
                }), submitText: "Confirm Delete" })) : null] })));
};
export default PlaybookTable;
