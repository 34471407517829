var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flexRender } from "@tanstack/react-table";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { Checkbox } from "../components/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button } from "../components/button";
import { getDepartments } from "./endpoints";
import { z } from "zod";
import { useMemo } from "react";
export const addSearchParamsToQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    let filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    let order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    let json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
export function getColumns(onRowClick) {
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: row.original.name })) })), _jsx(TooltipContent, { children: row.original.name })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "parent_department_name",
            accessorKey: "parent_department_name",
            header: "Parent department",
            cell: ({ row, cell }) => flexRender(row.original.parent_department_name, cell.getContext()),
            accessorFn: (row) => row.parent_department_name,
        },
        {
            id: "actions",
            accessorKey: "actions",
            header: "Update department",
            cell: ({ row }) => (_jsx(Button, Object.assign({ variant: "link", size: "icon", onClick: () => onRowClick(row.original) }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5" }) }))),
            enableHiding: false,
        },
    ];
}
export const FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "parent_department_name",
        name: "Parent department name",
        type: "string",
    },
];
export const SORTABLE_FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
];
export const departmentsFetcher = (queryClient, userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    const departmentsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getDepartments(JSON.stringify({
                table: "Department",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getdepartments", cursor, userQuery, ids],
    });
    const results = departmentsQueryResponse.data.map((department) => {
        return {
            label: department.name,
            value: department.id.toString(),
        };
    });
    results.push({
        label: "None",
        value: "None",
    });
    return { results };
});
export const DEPARTMENT_SCHEMA = z.object({
    Name: z.string().min(1),
    "Parent department": z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
});
export const checkNameAlreadyExists = (name, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const json_query = JSON.stringify({
        table: "Department",
        order_by_fields: ["name"],
        structured_filter: {
            where_condition: {
                field: "name",
                operator: WiserBackendQueryOperator.EQUALS,
                value: name,
            },
        },
    });
    const response = yield queryClient.fetchQuery({
        queryKey: ["getdepartments", name],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () { return yield getDepartments(json_query); }),
    });
    return response.data.length > 0;
});
export const useFormFieldMeta = (queryClient) => {
    const formFieldMeta = useMemo(() => ({
        Name: {
            type: "text",
            placeholder: "Name",
            defaultValue: "",
            required: true,
            onBlur: (event, form) => __awaiter(void 0, void 0, void 0, function* () {
                const nameExists = yield checkNameAlreadyExists(event.target.value, queryClient);
                if (nameExists) {
                    form.setError("Name", {
                        type: "validate",
                        message: "This name is already in use",
                    });
                }
                else {
                    form.clearErrors("Name");
                }
            }),
        },
        "Parent department": {
            type: "async_search_select",
            placeholder: "Parent department",
            defaultValue: undefined,
            optionsFetcher: (userQuery) => departmentsFetcher(queryClient, userQuery),
        },
    }), []);
    return formFieldMeta;
};
