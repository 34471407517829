var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import { EmailLink } from "../../../common/email_link";
import { getEmailContentFromText } from "../../../post_call/utils";
import { ContactAvatar } from "../../../common/contact_avatar";
import { Button } from "../../../components/button";
import { ChevronDoubleRightIcon, DocumentDuplicateIcon, } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useQuery } from "react-query";
import { executeBricks } from "../../../common/endpoints";
export const ContactDraftEmailSideView = ({ contact, contactDraftEmailBrick, onClose, }) => {
    var _a;
    const [contactEmailDraftBrickOutput, setContactEmailDraftBrickOutput] = useState(null);
    useQuery({
        queryKey: [
            "contactDraftEmailOutputExecution",
            contact.id,
            contactDraftEmailBrick.id,
        ],
        queryFn: () => executeBricks([contactDraftEmailBrick.id], (brickOutput) => {
            if (brickOutput.research_block_id === contactDraftEmailBrick.id) {
                setContactEmailDraftBrickOutput(brickOutput);
            }
        }, 
        /* callId= */ undefined, 
        /* accountId= */ undefined, 
        /* forceRefresh= */ false, 
        /* contactId= */ contact.id, 
        /* runBrickDependenciesForType= */ ["CONTACT"]),
        refetchOnWindowFocus: false,
    });
    const [copyClicked, setCopyClicked] = useState(false);
    const emailContent = getEmailContentFromText((_a = contactEmailDraftBrickOutput === null || contactEmailDraftBrickOutput === void 0 ? void 0 : contactEmailDraftBrickOutput.markdown) !== null && _a !== void 0 ? _a : "");
    const subject = emailContent === null || emailContent === void 0 ? void 0 : emailContent.subject;
    const body = emailContent === null || emailContent === void 0 ? void 0 : emailContent.body;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col p-6 gap-4 items-start w-full overflow-y-auto" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsx(ContactAvatar, { contact: contact }), _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "text-black h-5 w-5 hover:border", onClick: onClose }, { children: _jsx(ChevronDoubleRightIcon, { className: "h-3.5 w-3.5 shrink-0", "aria-hidden": "true" }) }))] })), !(contactEmailDraftBrickOutput === null || contactEmailDraftBrickOutput === void 0 ? void 0 : contactEmailDraftBrickOutput.markdown) ? (_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 animate-pulse" }, { children: "Generating draft email..." }))) : (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(EmailLink, { subject: subject, body: body, recipients: [contact.email], label: _jsxs("div", Object.assign({ className: "flex py-1 px-2.5 justify-center items-center gap-1.5 self-stretch rounded bg-red-100 text-black text-xs hover:bg-transparent" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/gmail_logo.png", className: "shrink-0" }), "Open draft in Gmail"] })) }) })), _jsx("div", Object.assign({ className: "p-5 rounded-xl border border-wds-gray-1 shadow-md bg-white overflow-y-auto text-sm link-styles" }, { children: _jsx(ReactMarkdown, { children: contactEmailDraftBrickOutput === null || contactEmailDraftBrickOutput === void 0 ? void 0 : contactEmailDraftBrickOutput.markdown }) }))] }))] })), contactEmailDraftBrickOutput &&
                contactEmailDraftBrickOutput.markdown && (_jsx("div", Object.assign({ className: "flex-none w-full p-4 border rounded-b-lg border-wds-gray-3 bg-white" }, { children: _jsxs(Button, Object.assign({ onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _b;
                        navigator.clipboard.writeText((_b = contactEmailDraftBrickOutput.markdown) !== null && _b !== void 0 ? _b : "");
                        setCopyClicked(true);
                        yield new Promise((resolve) => setTimeout(resolve, 500));
                        setCopyClicked(false);
                    }), className: "flex w-full py-3 px-2.5 items-center justify-center gap-2 self-stretch rounded-2xl bg-gray-800 text-white hover:bg-gray-700" }, { children: [copyClicked ? "Copied" : "Copy", copyClicked ? (_jsx(CheckCircleIcon, { className: "w-4 h-4 shrink-0" })) : (_jsx(DocumentDuplicateIcon, { className: "w-4 h-4 shrink-0" }))] })) })))] }));
};
