var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { cva } from "class-variance-authority";
import classNames from "../class_names";
const badgeVariants = cva("inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2", {
    variants: {
        variant: {
            default: "border-transparent bg-primary text-primary-foreground hover:bg-primary-dark",
            secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary-dark",
            destructive: "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive-dark",
            outline: "text-foreground",
            light: "bg-wds-blue-1 border-wds-blue-2 text-wds-blue-4 font-extrabold hover:bg-wds-blue-2",
            green: "bg-green-100 border-green-200 text-green-600 font-bold hover:bg-wds-green-2",
            gray: "bg-wds-gray-1 border-wds-gray-3 text-wds-gray-6 font-bold hover:bg-wds-gray-2",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
/**
 * Alternative standardised badge component that can be styled with
 * classNames instead specific style classes for
 * textClass and classes in the existing Badge component.
 * @example
 * ```
 * <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
 *  someText
 * </Badge>
 * ```
 */
function Badge(_a) {
    var { className, variant } = _a, props = __rest(_a, ["className", "variant"]);
    return (_jsx("div", Object.assign({ className: classNames(badgeVariants({ variant }), className) }, props)));
}
export { Badge, badgeVariants };
