import { jsx as _jsx } from "react/jsx-runtime";
import classNames from "../../../class_names";
/**
 * This component renders the center panel containing each account research block.
 * It can either be a brick, or a special block like calls table, contacts table etc.
 */
export const AccountWikiBricks = ({ accountBlocks, allBricks, blocksRefCallback, setSelectedSideViewOptions, setHighlightedBlockIds, renderedInTab, }) => {
    return (_jsx("div", Object.assign({ className: "flex flex-col items-center bg-wds-gray-1 border-b border-wds-gray-3 rounded-r-2xl w-full max-h-full overflow-y-auto" }, { children: _jsx("div", Object.assign({ className: classNames("flex flex-col items-start gap-2.5 w-full overflow-y-auto", renderedInTab ? "pt-6" : "p-6") }, { children: accountBlocks.map((accountBlock) => {
                return (_jsx("div", Object.assign({ className: "flex items-start self-stretch link-styles scroll-mt-4", ref: (divContainer) => {
                        blocksRefCallback(accountBlock.id.toString(), divContainer);
                    } }, { children: accountBlock.renderer({
                        onReadMoreClick: () => {
                            if (!accountBlock.brick) {
                                return;
                            }
                            setSelectedSideViewOptions({
                                type: "account_brick",
                                brick: accountBlock.brick,
                            });
                            setHighlightedBlockIds(new Set([accountBlock.id]));
                        },
                        onContactDraftEmailClick: (contact) => {
                            const contactDraftEmailBrick = allBricks.find((brick) => brick.research_block_type === "CONTACT" &&
                                brick.delivery_targets.includes("ContactEmailDrafts"));
                            if (!contactDraftEmailBrick) {
                                return;
                            }
                            setSelectedSideViewOptions({
                                type: "contact_draft_email",
                                contact,
                                contactDraftEmailBrick,
                            });
                        },
                        onContactMoreDetailsClick: (contact) => {
                            setSelectedSideViewOptions({
                                type: "contact_detail",
                                contact,
                            });
                        },
                    }) }), accountBlock.id));
            }) })) })));
};
