import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "../../../components/table";
import { Button } from "../../../components/button";
import { Link } from "react-router-dom";
import { ContactAvatar } from "../../../common/contact_avatar";
export const AccountContactsTable = ({ contacts, contactEmailDraftBrickExists, onMoreDetailsClick, onDraftEmailClick, }) => {
    if (contacts.length === 0) {
        return _jsx("span", Object.assign({ className: "text-wds-gray-4" }, { children: "No contacts found" }));
    }
    return (_jsxs(Table, Object.assign({ className: "w-full" }, { children: [_jsx(TableHeader, Object.assign({ className: "border-t border-b border-wds-gray-3 bg-wds-gray-1" }, { children: _jsxs(TableRow, { children: [_jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "Name" })), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "More details" })), _jsx(TableHead, Object.assign({ className: "font-bold text-wds-gray-5 w-1/5" }, { children: "Action" }))] }) })), _jsx(TableBody, { children: contacts.map((contact) => {
                    return (_jsxs(TableRow, Object.assign({ className: "hover:bg-transparent" }, { children: [_jsx(TableCell, { children: _jsx(ContactAvatar, { contact: contact }) }), _jsx(TableCell, Object.assign({ className: "p-0" }, { children: _jsx("div", Object.assign({ className: "flex items-center py-4 px-4 gap-2.5 self-stretch grow" }, { children: _jsxs(Button, Object.assign({ onClick: () => onMoreDetailsClick(contact), variant: "light", className: "flex justify-between self-stretch items-center gap-0.5 grow rounded-md" }, { children: ["Contact, work history and...", _jsx(ChevronRightIcon, { className: "w-4 h-4 shrink-0" })] })) })) })), _jsx(TableCell, Object.assign({ className: "p-0" }, { children: _jsxs("div", Object.assign({ className: "flex gap-2.5 self-stretch grow items-center py-4 px-4" }, { children: [contactEmailDraftBrickExists && (_jsxs(Button, Object.assign({ variant: "tableAction", size: "tableAction", onClick: () => onDraftEmailClick(contact) }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/gmail_logo.png", className: "shrink-0" }), "Draft"] }))), contact.linkedin_profile_url &&
                                            contact.linkedin_profile_url.length > 0 && (_jsx(Link, Object.assign({ to: contact.linkedin_profile_url, target: "_blank" }, { children: _jsxs(Button, Object.assign({ variant: "tableAction", size: "tableAction" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png", className: "shrink-0" }), "Profile"] })) })))] })) }))] }), contact.id));
                }) })] })));
};
