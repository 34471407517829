import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { CallsTable } from "./calls_table";
import { convertNewSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
import { useInfiniteQuery, useQuery } from "react-query";
import { getActiveTenant, getBricksByType, getCallsForTableView, getCrmFields, getPlaybookItemGroups, } from "../common/endpoints";
import { useMemo, useState } from "react";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { debounce } from "../common/debounce";
import { useCustomSearchParams } from "../crm/use_memoised_search_params";
import "react-mosaic-component/react-mosaic-component.css";
import { Mosaic } from "react-mosaic-component";
import { ChatWithTable } from "../live_cards_chat_bot/chat_with_table";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { CALLS_TABLE } from "../live_cards_chat_bot/chat_source_menu";
import useTableChatMosaic from "../live_cards_chat_bot/use_table_chat_mosaic";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
export default function IndexPage() {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const { searchParams, layouts, isLoading: layoutsLoading, } = useCustomSearchParams("calls", DEFAULT_TABLE_LAYOUT);
    const trackersAsBricksUIEnabled = useFeatureFlagEnabled("TRACKER_BRICK_INSIGHTS_UI");
    const filterQuery = convertNewSearchParamsIntoFilterQuery(searchParams, trackersAsBricksUIEnabled ? "CallExtendedWithBricks" : "CallExtended", debouncedQuery);
    const [visibleColumns, setVisibleColumns] = useState();
    const { chatCollapsed, updateChatCollapsed, mosaicState, onResize, onRelease, } = useTableChatMosaic();
    const pageSize = parseInt(searchParams.get("pageSize") || "50") || 50;
    const { data, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(["callsTable", filterQuery, pageSize], ({ pageParam = 0 }) => getCallsForTableView(filterQuery, pageParam + 1, pageSize), {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    //flatten the array of arrays from the useInfiniteQuery hook
    const calls = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const allTrackerBricksQuery = trackersAsBricksUIEnabled
        ? useQuery("getBricksByType", () => getBricksByType("TRACKER"), {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        })
        : null;
    const allTrackerBricks = allTrackerBricksQuery === null || allTrackerBricksQuery === void 0 ? void 0 : allTrackerBricksQuery.data;
    const playbookItemGroupsQuery = !trackersAsBricksUIEnabled
        ? useQuery("getPlaybookItemGroups", getPlaybookItemGroups, {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        })
        : null;
    const playbookItemGroups = playbookItemGroupsQuery === null || playbookItemGroupsQuery === void 0 ? void 0 : playbookItemGroupsQuery.data;
    const { data: crmFields, isLoading: crmFieldsLoading } = useQuery("getCrmFields", getCrmFields, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const { total, wiser_joined_count } = useMemo(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0) {
            return {
                total: data.pages[0].total,
                wiser_joined_count: data.pages[0].wiser_joined_count,
            };
        }
        return { total: 0, wiser_joined_count: 0 };
    }, [data === null || data === void 0 ? void 0 : data.pages]);
    const everythingIsLoaded = !isLoading &&
        !crmFieldsLoading &&
        !layoutsLoading &&
        data &&
        (playbookItemGroups || allTrackerBricks) &&
        activeTenant &&
        layouts &&
        crmFields &&
        layouts.length > 0;
    const renderTableAndChat = () => {
        if (!everythingIsLoaded) {
            return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
        }
        const CallsTableElement = (_jsx(CallsTable, { calls: calls, total: total, wiser_joined_count: wiser_joined_count, playbookItemGroups: playbookItemGroups, allTrackerBricks: allTrackerBricks, layouts: layouts, searchQuery: searchQuery, setSearchQuery: setSearchQuery, onVisibleColumnsChange: setVisibleColumns, tenant: activeTenant, searchParams: searchParams, filterQuery: filterQuery, fetchNextPage: fetchNextPage, isFetching: isFetchingNextPage, crmFields: crmFields }));
        return (_jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => {
                if (id === "table") {
                    return CallsTableElement;
                }
                if (id === "chat" && (visibleColumns === null || visibleColumns === void 0 ? void 0 : visibleColumns.length)) {
                    return (_jsx(ChatWithTable, { filterQuery: getConvertedJsonQuery(filterQuery), visibleColumns: visibleColumns, collapsed: chatCollapsed, setCollapsed: updateChatCollapsed, chatSource: CALLS_TABLE }));
                }
                return _jsx(_Fragment, {});
            }, initialValue: mosaicState, onChange: onResize, onRelease: onRelease, value: mosaicState, className: "h-[83vh] my-mosaic" }));
    };
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsx("div", Object.assign({ className: "w-60 text-black text-3xl p-2" }, { children: "Calls" })), renderTableAndChat()] })));
}
