import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
export const EmailLink = ({ subject, body, label, recipients, openImmediately, }) => {
    // URL encode subject and body
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    // Construct the mailto link
    const mailtoLink = useMemo(() => {
        let mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodedSubject}&body=${encodedBody}`;
        if (recipients) {
            mailtoLink += `&to=${recipients.join(",")}`;
        }
        return mailtoLink;
    }, [subject, body, recipients]);
    useEffect(() => {
        if (openImmediately) {
            window.open(mailtoLink, "_blank");
        }
    }, [mailtoLink, openImmediately]);
    return (_jsx("a", Object.assign({ href: mailtoLink, target: "_blank", rel: "noopener noreferrer" }, { children: label })));
};
