var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "../components/button";
import { getCallsTableCsv } from "../common/endpoints";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
export function CallsTableToolbarActions({ table, filterQueryJson, }) {
    const [exporting, setExporting] = useState(false);
    return (_jsx("div", Object.assign({ className: "flex items-center gap-2" }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "sm", disabled: exporting, onClick: () => __awaiter(this, void 0, void 0, function* () {
                setExporting(true);
                const visibleColumns = table.getVisibleLeafColumns().map((column) => {
                    var _a, _b;
                    return {
                        accessor: column.id,
                        header: (_b = (_a = column.columnDef.header) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "",
                    };
                });
                const csv = yield getCallsTableCsv(filterQueryJson, null, visibleColumns);
                const blob = new Blob([csv], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                window.open(url);
                setExporting(false);
            }), className: "text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: !exporting ? (_jsx(ArrowDownTrayIcon, { className: "h-4 w-4", "aria-hidden": "true" })) : (_jsx(ArrowPathIcon, { className: "h-4 w-4 animate-spin", "aria-hidden": "true" })) })) })));
}
