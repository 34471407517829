import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import BaseModal from "../base_modal";
import SelectionTable from "../selection_table";
import { useNavigate } from "react-router-dom";
const GetResearchBlockLabels = (brickType) => {
    switch (brickType) {
        case "ACCOUNT":
            return {
                title: "Add account research block",
                description: "A research block represents a single block of information that will be included in the account details.",
                createLabel: "Create a new account research block",
            };
        case "CALL":
            return {
                title: "Add pre-call briefing block",
                description: "A briefing block represents a single block of information that will be included in the pre-call briefing.",
                createLabel: "Create new pre-call briefing block",
            };
        case "CONTACT":
            return {
                title: "Add contact research block",
                description: "A contact block represents a single block of information or an email prompt for a contact.",
                createLabel: "Create a new contact prompt",
            };
        case "TRACKER":
            return {
                title: "Add tracker",
                description: "A tracker is an individual notetaker on a call.",
                createLabel: "Create a new tracker prompt",
            };
        default:
            return {
                title: "Custom Prompt",
                description: "",
                createLabel: "",
            };
    }
};
const AddBriefingBlockModal = (props) => {
    const { researchBlockType, open, onClose, onAddBriefingBlocks, allBlocks, existingBlockIds, createCallback, linkToBlock = false, brickSetId = null, } = props;
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const navigate = useNavigate();
    const applicableResearchBlocks = useMemo(() => {
        return allBlocks.filter((block) => block.research_block_type === researchBlockType);
    }, [allBlocks, researchBlockType]);
    const expandCallback = (block) => {
        navigate("/research", {
            state: {
                editingBlockId: block.id,
                templateId: brickSetId,
                brickType: "CALL",
            },
        });
    };
    const researchBlockLabels = GetResearchBlockLabels(researchBlockType);
    return (_jsx(BaseModal, Object.assign({ title: researchBlockLabels.title, description: researchBlockLabels.description, open: open, setOpen: onClose, fields: [], submitCallback: () => {
            onAddBriefingBlocks(selectedBlocks.map((block) => block.id));
        }, submitText: "Add" }, { children: _jsx(SelectionTable, { columns: [
                {
                    accessorKey: "name",
                    header: "Name",
                },
                {
                    accessorKey: "prompt",
                    header: "Prompt",
                    cell: ({ row }) => {
                        if (row.original.prompt.length > 45) {
                            return row.original.prompt.slice(0, 45) + "...";
                        }
                        return row.original.prompt;
                    },
                },
            ], rows: applicableResearchBlocks, setSelectedRows: setSelectedBlocks, canSelectRow: (row) => !existingBlockIds.includes(row.id), createCallback: createCallback, createLabel: researchBlockLabels.createLabel, expandCallback: linkToBlock ? expandCallback : undefined }) })));
};
export default AddBriefingBlockModal;
