import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { getCrmAccountDescription } from "../../../common/call_account_utils";
import { getAccountDescription } from "../../../common/endpoints";
import { ChatBox } from "../../../home/chat_box_v3";
import { ACCOUNT_SOURCES_AND_WEB_DATA, PAST_CALLS_CHAT_SOURCE, } from "../../../live_cards_chat_bot/chat_source_menu";
import AccountLogo from "../account_logo";
export const AccountInfoAndChatBox = ({ account, setChatMessages, }) => {
    return (_jsxs("div", Object.assign({ className: "relative z-0 flex px-8 pt-6 flex-col items-start gap-6 self-stretch bg-white w-full" }, { children: [_jsx(AccountInfo, { account: account }), _jsx("div", Object.assign({ className: "flex flex-col w-full justify-center self-stretch items-start gap-3" }, { children: _jsx(ChatBox, { accountId: account.id, defaultChatSource: ACCOUNT_SOURCES_AND_WEB_DATA, chatSources: [ACCOUNT_SOURCES_AND_WEB_DATA, PAST_CALLS_CHAT_SOURCE], setChatMessages: setChatMessages }) }))] })));
};
const AccountInfo = ({ account }) => {
    var _a, _b;
    const DEFAULT_DESCRIPTION_TEXT = "No description available for this account";
    const { data: llmBasedAccountDescription, isFetching: accountDescriptionFetching, } = useQuery({
        queryKey: ["accountDescription", account.id],
        queryFn: () => getAccountDescription(account.id),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    return (_jsxs("div", Object.assign({ className: "flex justify-center items-start gap-5 grow self-stretch" }, { children: [_jsx(AccountLogo, { account: account, className: "flex h-16 w-16 items-center justify-center flex-col rounded-xl" }), _jsx("div", Object.assign({ className: "flex flex-col justify-center items-start gap-2 grow self-stretch" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1.5 grow self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-2xl font-semibold" }, { children: (_a = account.name) !== null && _a !== void 0 ? _a : account.website })), accountDescriptionFetching ? (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse" }, { children: "Generating account description" }))) : (_jsx("span", Object.assign({ className: "line-clamp-3 font-normal text-base" }, { children: llmBasedAccountDescription &&
                                llmBasedAccountDescription.description &&
                                llmBasedAccountDescription.description.length > 0
                                ? llmBasedAccountDescription.description
                                : ((_b = getCrmAccountDescription(account)) !== null && _b !== void 0 ? _b : DEFAULT_DESCRIPTION_TEXT) })))] })) }))] })));
};
