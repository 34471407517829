import { jsx as _jsx } from "react/jsx-runtime";
import { AccountBrickSideView } from "./account_brick_side_view";
import { ContactDetailSideView } from "./contact_detail_side_view";
import { ContactDraftEmailSideView } from "./contact_draft_email_side_view";
/**
 * This component renders a side panel in the account wiki dashboard.
 * It currently supports the following types of side views:
 * 1. Brick outputs
 * 2. Contact details
 * 3. Contact draft email
 *
 *
 * In order to add a new side view, make sure to add the type definition, and
 * the renderer below.
 */
export const AccountResearchDashboardSidePanel = (props) => {
    const renderSideViewContent = () => {
        if (props.sideViewOptions.type === "account_brick") {
            return (_jsx(AccountBrickSideView, { account: props.account, accountBlocks: props.accountBlocks, brick: props.sideViewOptions.brick, onClose: props.onSideViewClose }));
        }
        if (props.sideViewOptions.type === "contact_detail") {
            return (_jsx(ContactDetailSideView, { contact: props.sideViewOptions.contact, onClose: props.onSideViewClose }));
        }
        if (props.sideViewOptions.type === "contact_draft_email") {
            return (_jsx(ContactDraftEmailSideView, { contact: props.sideViewOptions.contact, contactDraftEmailBrick: props.sideViewOptions.contactDraftEmailBrick, onClose: props.onSideViewClose }, props.sideViewOptions.contact.id));
        }
        return null;
    };
    return (_jsx("div", Object.assign({ className: "slide-in-animation duration-200 ml-2 flex flex-col items-center w-1/3 bg-wds-gray-1 border border-wds-gray-3 rounded-2xl shrink-0 max-w-full max-h-full overflow-y-auto" }, { children: renderSideViewContent() })));
};
