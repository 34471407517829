import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
/**
 * @example
 * ```
 *
 <Collapsible>
  <CollapsibleTrigger>Hello trigger</CollapsibleTrigger>
  <CollapsibleContent>
    Hello trigger content
  </CollapsibleContent>
</Collapsible>
```
 */
const Collapsible = CollapsiblePrimitive.Root;
const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;
const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;
export { Collapsible, CollapsibleTrigger, CollapsibleContent };
