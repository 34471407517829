var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { executeBricks, getAllBricks } from "../../common/endpoints";
const useContactBrickOutputs = (accountContact) => {
    const [contactBricks, setContactBricks] = useState([]);
    const [contactBrickOutputs, setContactBrickOutputs] = useState([]);
    const [contactBrickOutputsLoading, setContactBrickOutputsLoading] = useState(false);
    const runAndSetContactBrickOutputs = () => __awaiter(void 0, void 0, void 0, function* () {
        if (contactBricks.length === 0) {
            return;
        }
        setContactBrickOutputsLoading(true);
        try {
            yield executeBricks(contactBricks.map((brick) => brick.id), (brickOutput) => {
                var _a, _b;
                const newBrick = Object.assign(Object.assign({}, brickOutput), { delivery_targets: (_b = (_a = contactBricks.find(
                    // @ts-ignore
                    (brick) => brick.id === brickOutput.block_id)) === null || _a === void 0 ? void 0 : _a.delivery_targets) !== null && _b !== void 0 ? _b : [] });
                setContactBrickOutputs((prev) => [...prev, newBrick]);
            }, undefined, undefined, false, accountContact.id);
        }
        finally {
            setContactBrickOutputsLoading(false);
        }
    });
    useEffect(() => {
        getAllBricks()
            .then((bricksResponse) => bricksResponse.bricks.filter((brick) => brick.research_block_type === "CONTACT"))
            .then(setContactBricks);
    }, [accountContact.id]);
    return {
        runAndSetContactBrickOutputs,
        contactBrickOutputs,
        contactBrickOutputsLoading,
        contactBricks,
    };
};
export default useContactBrickOutputs;
