import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getActiveTenant, getCrmAccount } from "../../common/endpoints";
import { CenterScreen } from "../../settings/styled_components";
import { SettingsLoader } from "../../common/loaders";
import { AccountPageHeader } from "./account_page_header";
import { AccountBlocks } from "./account_blocks";
import { useAccountResearchBlocks } from "./use_account_research_blocks";
import { ACCOUNT_SOURCES_AND_WEB_DATA, PAST_CALLS_CHAT_SOURCE, } from "../../live_cards_chat_bot/chat_source_menu";
import { ChatBox } from "../../home/chat_box_v3";
import useFeatureFlagEnabled from "../../use_feature_flag_enabled";
import { AccountResearchDrawerHeader } from "./v2/drawer_header";
import { AccountResearchDrawerBody } from "./v2/drawer_body";
export const AccountPage = ({ accId }) => {
    // We get the accId in the case this is loaded from the calls table
    const { accountIdParam } = useParams();
    const accountId = accId ? Number(accId) : Number(accountIdParam);
    const accountResearchUiV2Enabled = useFeatureFlagEnabled("ACCOUNT_RESEARCH_UI_V2");
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: account, isLoading: accountLoading } = useQuery({
        queryKey: ["crmAccount", accountId],
        queryFn: () => getCrmAccount(accountId, /* includeNonCrmAccounts= */ true),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { accountBlocks: accountBlocksToRender, scrollToBlock: scrollToBlockHandler, staticBlocksCount, blocksRefCallback, accountBrickSetsWithBricks, allBricks, setHighlightedBlockIds, } = useAccountResearchBlocks({
        account,
    });
    if (accountLoading || !account || !activeTenant) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    if (accountResearchUiV2Enabled) {
        return (_jsxs("div", Object.assign({ className: "flex w-full flex-col items-center h-[calc(100vh - 3.125rem)] lg:h-screen" }, { children: [_jsx(AccountResearchDrawerHeader, {}), _jsx(AccountResearchDrawerBody, { account: account, accountBlocks: accountBlocksToRender, scrollToBlock: scrollToBlockHandler, blocksRefCallback: blocksRefCallback, accountBrickSetsWithBricks: accountBrickSetsWithBricks, setHighlightedBlockIds: setHighlightedBlockIds, allBricks: allBricks !== null && allBricks !== void 0 ? allBricks : [] })] })));
    }
    return (_jsx("div", Object.assign({ className: "flex flex-row w-full h-[calc(100vh - 3.125rem)] lg:h-screen bg-neutral-100 overflow-hidden font-['Lato']" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full items-start grow p-1 pb-0" }, { children: [_jsx(AccountPageHeader, { tenant: activeTenant, account: account, accountBlocksToRender: accountBlocksToRender, scrollToBlockHandler: scrollToBlockHandler }), _jsxs("div", Object.assign({ className: "flex flex-col w-full py-4 px-8 gap-4 items-start overflow-y-auto h-lvh" }, { children: [_jsx(ChatBox, { accountId: accountId, defaultChatSource: ACCOUNT_SOURCES_AND_WEB_DATA, chatSources: [ACCOUNT_SOURCES_AND_WEB_DATA, PAST_CALLS_CHAT_SOURCE] }), _jsx(AccountBlocks, { accountBlocksToRender: accountBlocksToRender, blocksRefCallback: blocksRefCallback, staticBlocksCount: staticBlocksCount })] }))] })) })));
};
