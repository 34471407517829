var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { useNavigate } from "react-router-dom";
import { DEFAULT_USERS_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import { useMutation, useQueryClient, } from "react-query";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
import { useMemo, useState } from "react";
import { FIELDS, getColumns, getEmailMessage, SORTABLE_FIELDS, useFormFieldMeta, USER_SCHEMA, } from "./user.helpers";
import { UserForm } from "./user_form";
import { UsersExport } from "./users_export";
import { Button } from "../components/button";
import { PlusIcon } from "@heroicons/react/24/outline";
import BaseModal2 from "../base_modal2";
import { createUser, sendUserInviteEmail } from "./endpoints";
import { z } from "zod";
import { useToast } from "../components/use_toast";
import { UserTableFloatingBar } from "./user_table_floating_bar";
import LiveCardTextEditor from "../live_card_rich_text";
export function UserTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const { toast } = useToast();
    const queryClient = useQueryClient();
    const users = data.users;
    const total = data.total;
    const [selectedUser, setSelectedUser] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [sendInvitationModalOpen, setSendInvitationModalOpen] = useState(false);
    const [emailBody, setEmailBody] = useState(getEmailMessage(data.tenant.name, "WiserAI", data.tenant.onboarding_guide_url));
    // Memoize the columns so they don't re-render on every render
    const columns = useMemo(() => getColumns((row) => {
        setSelectedUser(row);
        setDrawerOpen(true);
    }), []);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = useState(getColumnOrder(columns, matchingLayout));
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(FIELDS);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const createMutation = useMutation({
        mutationFn: (values) => __awaiter(this, void 0, void 0, function* () {
            return yield createUser(values);
        }),
        onSuccess: (data) => {
            // We cannot add user manually here since given the filters it may not be in the list
            queryClient.invalidateQueries();
            toast({
                title: "User created",
                description: "User created successfully",
                variant: "green",
            });
            setCreateModalOpen(false);
            setSelectedUser(data);
            setSendInvitationModalOpen(true);
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `${error}`,
                variant: "destructive",
            });
        },
    });
    const emailMutation = useMutation({
        mutationFn: (email) => __awaiter(this, void 0, void 0, function* () {
            return yield sendUserInviteEmail({
                from_email: "support@getwiser.io",
                to_email: email,
                subject: "Your account in Wiser is ready!",
                body: emailBody,
            });
        }),
        onSuccess: () => {
            toast({
                title: "Success",
                description: "Email sent successfully",
                variant: "green",
            });
            setSelectedUser(undefined);
            setSendInvitationModalOpen(false);
        },
        onError: () => {
            toast({
                title: "Unknown error while sending email",
                variant: "destructive",
            });
        },
    });
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const { table } = useDataTable({
        data: users,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: {
                precall_notification_duration: false,
                precall_email_enabled: false,
                precall_slack_enabled: false,
                custom_bot_name: false,
                no_live_bot: false,
                timezone: false,
                total_internal_meetings: false,
            },
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    const formFieldMeta = useFormFieldMeta(queryClient);
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: data.fetchNextPage, total: total, totalFetched: users.length, isFetching: data.isFetching, floatingBar: _jsx(UserTableFloatingBar, { table: table }) }, { children: _jsxs(DataTableToolbarV2, Object.assign({ table: table, columnIdMap: {}, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_USERS_TABLE_LAYOUT, onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me users", sortRuleBuilderPanelTitle: "Sort users by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, onSearchQueryChange: data.setSearchQuery, matchingLayout: matchingLayout }, { children: [_jsx(UsersExport, { filterQuery: data.filterQuery }), _jsxs(Button, Object.assign({ onClick: () => setCreateModalOpen(true) }, { children: ["Add user", _jsx(PlusIcon, { className: "h-4 w-4 ml-2 stroke-2 text-wds-white" })] }))] })) })), selectedUser && drawerOpen && (_jsx(Drawer, Object.assign({ open: drawerOpen, onOpenChange: () => setDrawerOpen(false) }, { children: _jsxs(DrawerContent, Object.assign({ className: "sm:max-w-none sm:w-1/4" }, { children: [_jsx(DrawerTitle, Object.assign({ className: "text-wds-black" }, { children: "User Details" })), selectedUser && (_jsx(UserForm, { user: selectedUser, setSelectedUser: setSelectedUser, setSendInvitationModalOpen: setSendInvitationModalOpen }))] })) }))), createModalOpen && (_jsx(BaseModal2, { title: "Create user", description: "Create a new user", open: createModalOpen, setOpen: (open) => setCreateModalOpen(open), submitText: "Create", submitCallback: (values) => __awaiter(this, void 0, void 0, function* () {
                    yield createMutation.mutateAsync(values);
                }), zodSchema: USER_SCHEMA, formFieldMeta: formFieldMeta })), sendInvitationModalOpen && selectedUser && (_jsx(BaseModal2, Object.assign({ title: "Send invitation", description: "Send an invitation to the user", open: sendInvitationModalOpen, setOpen: (open) => setSendInvitationModalOpen(open), submitText: "Send", submitCallback: () => __awaiter(this, void 0, void 0, function* () {
                    yield emailMutation.mutateAsync(selectedUser.email);
                }), zodSchema: z.object({}), formFieldMeta: {} }, { children: _jsx(LiveCardTextEditor, { content: getEmailMessage(data.tenant.name, (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.name) || "WiserAI", data.tenant.onboarding_guide_url), setContent: setEmailBody }) })))] }));
}
