var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { useMutation } from "react-query";
import { awaitBrickExecution, makeBrickPreview } from "../common/endpoints";
import { Tabs, TabsList, TabsTrigger } from "../components/tabs";
import { RadioGroup, RadioGroupItem } from "../components/radio_group";
import { Button } from "../components/button";
import WaitingSpinner from "../waiting_spinner";
const BrickPreviews = ({ brickId, newPrompt, brickName }) => {
    const [brickPreviews, setBrickPreviews] = useState(new Map());
    const [selectedTab, setSelectedTab] = useState("output");
    const [selectedExample, setSelectedExample] = useState("");
    const [forceRefresh, setForceRefresh] = useState(false);
    // 1. Get three most recent brick outputs for the brick
    // 2. Make a new brick (preview type) with the newPrompt
    // 3. Run the new brick on the three most recent output sources
    // 4. Wait for the updates to come in
    // 5. Display the diffs
    const brickOutputCallback = (brickOutput, idToName) => {
        setBrickPreviews((prev) => {
            var _a, _b;
            const newPreviews = new Map(prev);
            let callAccountName = "";
            let sourceId = 0;
            if (brickOutput.call_id) {
                callAccountName = "Call ";
                sourceId = brickOutput.call_id;
            }
            else if (brickOutput.account_id) {
                callAccountName = "Account ";
                sourceId = brickOutput.account_id;
            }
            callAccountName += (_a = idToName[sourceId]) !== null && _a !== void 0 ? _a : sourceId;
            const isPreview = brickOutput.research_block_type.includes("PREVIEW");
            const update = isPreview
                ? { newOutput: brickOutput }
                : { oldOutput: brickOutput };
            const brickPreviewRow = (_b = newPreviews.get(callAccountName)) !== null && _b !== void 0 ? _b : {};
            const updatedRow = Object.assign(Object.assign({}, brickPreviewRow), update);
            newPreviews.set(callAccountName, updatedRow);
            return newPreviews;
        });
    };
    const mutation = useMutation((newPrompt) => __awaiter(void 0, void 0, void 0, function* () {
        return yield makeBrickPreview(brickId, newPrompt, forceRefresh);
    }), {
        onSuccess: (previewLaunchedResponse) => __awaiter(void 0, void 0, void 0, function* () {
            const cb = (brickOutput) => brickOutputCallback(brickOutput, previewLaunchedResponse.id_to_name);
            previewLaunchedResponse.brick_outputs.forEach(cb);
            yield awaitBrickExecution(previewLaunchedResponse, cb);
        }),
        onError: (error) => {
            console.error("Error launching brick preview:", error);
        },
        onSettled: () => {
            setForceRefresh(false);
        },
    });
    useEffect(() => {
        if (!brickId)
            return;
        mutation.mutate(newPrompt);
    }, [newPrompt]);
    useEffect(() => {
        if (forceRefresh) {
            if (!brickId)
                return;
            mutation.mutate(newPrompt);
        }
    }, [forceRefresh]);
    useEffect(() => {
        if (!selectedExample && brickPreviews.size > 0) {
            setSelectedExample(Array.from(brickPreviews.keys())[0]);
        }
    }, [brickPreviews, selectedExample]);
    const [oldValue, newValue] = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        const example = brickPreviews.get(selectedExample);
        if (!example) {
            return ["", ""];
        }
        const oldOutputValue = (_c = (selectedTab === "prompt"
            ? (_a = example.oldOutput) === null || _a === void 0 ? void 0 : _a.full_prompt
            : (_b = example.oldOutput) === null || _b === void 0 ? void 0 : _b.markdown)) !== null && _c !== void 0 ? _c : "";
        const newOutputValue = (_f = (selectedTab === "prompt"
            ? (_d = example.newOutput) === null || _d === void 0 ? void 0 : _d.full_prompt
            : (_e = example.newOutput) === null || _e === void 0 ? void 0 : _e.markdown)) !== null && _f !== void 0 ? _f : "";
        return [oldOutputValue, newOutputValue];
    }, [brickPreviews, selectedTab, selectedExample]);
    const tabs = [
        { label: "Output", value: "output" },
        { label: "Prompt", value: "prompt" },
    ];
    return (_jsxs("div", { children: [_jsxs(Tabs, Object.assign({ defaultValue: "output", onValueChange: (value) => setSelectedTab(value), className: "border-b border-wds-gray-3" }, { children: [_jsx(RadioGroup, Object.assign({ onValueChange: setSelectedExample, value: selectedExample }, { children: Array.from(brickPreviews.keys()).map((callAccountName) => (_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(RadioGroupItem, { value: callAccountName }), _jsxs("span", Object.assign({ className: "text-wds-gray-5" }, { children: ["(", callAccountName, ")"] }))] }), callAccountName))) })), _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center" }, { children: [_jsx(TabsList, Object.assign({ className: "mx-4 border-none" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value }, { children: tab.label }), tab.value))) })), _jsx("div", Object.assign({ className: "font-semibold" }, { children: brickName })), _jsxs(Button, Object.assign({ onClick: () => {
                                    setForceRefresh(true);
                                }, type: "button", variant: "destructive", disabled: mutation.isLoading }, { children: [mutation.isLoading && (_jsx(WaitingSpinner, { text: "Running new prompt..." })), !mutation.isLoading && "Refresh"] }))] }))] })), _jsx(ReactDiffViewer, { leftTitle: selectedTab === "output" ? "Old Output" : "Old Prompt", rightTitle: selectedTab === "output" ? "New Output" : "New Prompt", splitView: true, oldValue: oldValue, newValue: newValue, 
                // See https://github.com/praneshr/react-diff-viewer/tree/v3.0.0#text-block-diff-comparison
                compareMethod: DiffMethod.WORDS })] }));
};
export default BrickPreviews;
