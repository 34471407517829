var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import useChatMessages from "./use_chat_messages";
import { useStreamSocket, } from "../common/stream_socket";
import { useLlmOutputFeedback } from "../llm_output_feedback/use_llm_output_feedback";
import { InformationCircleIcon, SparklesIcon, XMarkIcon, } from "@heroicons/react/24/solid";
import Message from "./message";
import { Button } from "../components/button";
import WiserSparklesIcon from "../wiser_sparkles_icon";
import classNames from "../class_names";
import { useLocation } from "react-router-dom";
/**
 * This component is responsible for rendering the chatbox and the insights panel
 * for the calls page.
 *
 * TODO: Make this component more generic and reusable for other tables.
 * @param filterQuery: The filter query of the table.
 * @param visibleColumns: The visible columns of the table.
 * @returns
 */
export const ChatWithTable = ({ filterQuery, visibleColumns, collapsed, setCollapsed, chatSource, }) => {
    const clientId = useMemo(() => Math.floor(Math.random() * 1000000), []);
    const location = useLocation();
    const { chatMessages, addChatMessage, handleChatMessage, handleEchoChat, handleChatProgress, } = useChatMessages();
    const streamSocket = useStreamSocket();
    const [socketReady, setSocketReady] = useState(false);
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ callId: 0 });
    useEffect(() => {
        if (collapsed) {
            return;
        }
        streamSocket.addListener("chat_message", handleChatMessage);
        streamSocket.addListener("echo_back_chat", (message) => handleEchoChat(message, clientId));
        streamSocket.addListener("chat_progress", handleChatProgress);
        // We just need this to tell that the stream is started; every session
        // begins by sending a note or a placeholder.
        streamSocket.addListener("manual_note", () => { });
        streamSocket.addListener("display_notes", () => { });
        streamSocket.setOpenCallback(() => {
            setSocketReady(true);
        });
        streamSocket.setCloseCallback(() => {
            setSocketReady(false);
        });
        // TODO: not clear exactly why we need to set this, but we do:
        streamSocket.connectToStreamSocket(0);
        return () => {
            streamSocket.close();
        };
    }, [collapsed]);
    const addUserMessageToChatAndRespond = (userMessage, forceSource) => __awaiter(void 0, void 0, void 0, function* () {
        addChatMessage({
            sender: "Bot",
            messageType: "Loading",
        });
        streamSocket.send(JSON.stringify({
            type: "get_best_card_from_string_input",
            inputString: userMessage,
            forceSource: forceSource !== null && forceSource !== void 0 ? forceSource : chatSource.id,
            clientId: clientId,
            chatAgainstTableFilter: filterQuery,
            visibleColumns: visibleColumns,
        }));
    });
    const ChatHeader = () => {
        return (_jsxs("div", Object.assign({ className: classNames("flex w-full items-center self-stretch justify-between py-2 px-4 text-white rounded", 
            // Tailwind doesn't support radial gradient utility class yet
            "bg-[radial-gradient(141.42%_141.42%_at_100%_0%,_theme(colors.purple.600)_0%,_theme(colors.blue.600)_100%)]") }, { children: [_jsx("div", Object.assign({ className: "text-base font-bold" }, { children: "Ask Wiser anything" })), _jsx("div", Object.assign({ className: "flex self-stretch items-center shrink-0" }, { children: _jsx("button", Object.assign({ onClick: () => setCollapsed(true), className: "hidden md:flex" }, { children: _jsx(XMarkIcon, { className: "h-5 w-5 shrink-0" }) })) }))] })));
    };
    useEffect(() => {
        // The account page opens in a drawer & can be in the dom at the same time as chat with table
        // We make sure chat with table is collapsed when the account page is opened so that messages from the account page don't show up here
        // What about calls page: there the messages are scoped to call_id & run in a separate stream
        if (location.pathname.startsWith("/crm/home/accounts/account")) {
            setCollapsed(true);
        }
    }, [location]);
    if (collapsed) {
        return (_jsx("div", Object.assign({ className: "flex h-[77.5vh] flex-col justify-start items-center bg-white p-4 rounded-lg border-wds-gray-3 border" }, { children: _jsx("button", Object.assign({ onClick: () => setCollapsed(false) }, { children: _jsx(WiserSparklesIcon, {}) })) })));
    }
    return (_jsx("div", Object.assign({ className: "border border-wds-gray-3 rounded-b-lg h-[77.5vh]" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-4 justify-between items-center grow self-stretch p-2 h-full" }, { children: [_jsxs("div", { children: [_jsx(ChatHeader, {}), _jsx(InsightsToast, {})] }), chatMessages.length === 1 && _jsx(InsightsWelcome, {}), chatMessages.length > 1 && (_jsx(InsightsChatMessages, { chatMessages: chatMessages, addUserMessageToChatAndRespond: addUserMessageToChatAndRespond, allLlmOutputFeedback: allLlmOutputFeedback !== null && allLlmOutputFeedback !== void 0 ? allLlmOutputFeedback : [], updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation })), _jsx(InsightsChatBox, { socketReady: socketReady, selectedChatSource: chatSource, addUserMessageToChatAndRespond: addUserMessageToChatAndRespond })] })) })));
};
const InsightsToast = () => {
    return (_jsxs("div", Object.assign({ className: "flex py-3 pl-4 pr-16 items-center gap-2 self-stretch rounded-lg border-l-4 border-l-wds-blue-4 bg-wds-blue-1 shadow-lg mt-2" }, { children: [_jsx(InformationCircleIcon, { className: "w-5 h-5 text-wds-blue-4" }), _jsx("span", { children: "Wiser will use any filters you've applied to scope its responses. If something doesn't look right, you can try adjusting the filters above." })] })));
};
const InsightsWelcome = () => {
    return (_jsx("div", Object.assign({ className: "flex flex-col items-center gap-8 mt-[16vh]" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-center gap-4" }, { children: [_jsx("img", { className: "h-20 w-20 shrink-0", src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png" }), _jsx("span", Object.assign({ className: "text-2xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500" }, { children: "What can Wiser help you with today?" }))] })) })));
};
const InsightsChatMessages = ({ chatMessages, addUserMessageToChatAndRespond, allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, }) => {
    const scrollContainerRef = useRef(null);
    useEffect(() => {
        // Auto-scroll to the bottom
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);
    return (_jsx("div", Object.assign({ ref: scrollContainerRef, className: "overflow-auto" }, { children: chatMessages
            // We don't render the first bot "Hi" message
            .slice(/* start= */ 1, /* end= */ undefined)
            .map((message, idx) => (_jsx("div", Object.assign({ className: "bg-wds-gray-1 rounded-lg shadow-sm p-2 hover:transition-transform" }, { children: _jsx(Message, { message: message, addUserMessageToChatAndRespond: addUserMessageToChatAndRespond, userLlmOutputFeedback: message &&
                    message.sender === "Bot" &&
                    message.messageType === "Card" &&
                    message.card.provenanceLogId
                    ? allLlmOutputFeedback === null || allLlmOutputFeedback === void 0 ? void 0 : allLlmOutputFeedback.find((f) => f.provenance_log_id === message.card.provenanceLogId)
                    : undefined, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) }), idx))) })));
};
const InsightsChatBox = ({ socketReady, selectedChatSource, addUserMessageToChatAndRespond, }) => {
    var _a;
    const [searchQuery, setSearchQuery] = useState(null);
    const submitQuery = () => {
        if (!socketReady || !searchQuery)
            return;
        addUserMessageToChatAndRespond(searchQuery, selectedChatSource.id);
        setSearchQuery("");
    };
    return (_jsxs("div", Object.assign({ className: "w-full flex flex-col bg-white border border-wds-gray-3 rounded-lg py-2 px-4" }, { children: [_jsx("textarea", { className: "border-none w-full bg-white overflow-hidden text-black font-lato text-sm font-normal outline-none p-2 focus:ring-0", placeholder: 'Ask Wiser anything. Type "/" for more commands.', onChange: (e) => setSearchQuery(e.target.value), value: (_a = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.trimStart()) !== null && _a !== void 0 ? _a : "", onKeyDown: (e) => {
                    if (e.key === "Enter" && socketReady) {
                        submitQuery();
                    }
                }, rows: 4 }), _jsx("div", Object.assign({ className: "flex self-stretch justify-end items-start pt-1" }, { children: _jsxs(Button, Object.assign({ variant: "default", className: "group flex flex-row space-x-2 px-3 rounded-full items-center justify-center text-white text-sm font-bold bg-gradient-ask disabled:opacity-50", disabled: !socketReady || !searchQuery || (searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.length) === 0, onClick: () => submitQuery() }, { children: [_jsx(SparklesIcon, { className: "flex w-4 h-4 text-white fill-white" }), _jsx("span", { children: "Ask" })] })) }))] })));
};
