import { CallState } from "./types";
export var MeetingType;
(function (MeetingType) {
    MeetingType[MeetingType["GoogleMeet"] = 1] = "GoogleMeet";
    MeetingType[MeetingType["Zoom"] = 2] = "Zoom";
    MeetingType[MeetingType["LocalAudio"] = 3] = "LocalAudio";
    MeetingType[MeetingType["Gong"] = 4] = "Gong";
    MeetingType[MeetingType["MicrosoftTeams"] = 5] = "MicrosoftTeams";
})(MeetingType || (MeetingType = {}));
export const getCallSourceSupported = (meetingType) => {
    return meetingType === MeetingType.GoogleMeet;
};
export const getMeetingType = (call) => {
    return call.from_gong
        ? MeetingType.Gong
        : call.google_meet_uri
            ? MeetingType.GoogleMeet
            : call.is_zoom_call
                ? MeetingType.Zoom
                : call.microsoft_teams_uri
                    ? MeetingType.MicrosoftTeams
                    : MeetingType.LocalAudio;
};
export const getMeetingTypeEnumFromCallSource = (callSource) => {
    if (callSource === "Google Meet")
        return MeetingType.GoogleMeet;
    if (callSource === "Zoom")
        return MeetingType.Zoom;
    if (callSource === "Microsoft Teams")
        return MeetingType.MicrosoftTeams;
    if (callSource === "Uploaded from Gong")
        return MeetingType.Gong;
    return MeetingType.LocalAudio;
};
// Returns a user-presentable description of the call state.
// If we don't know, or the call looks fine, we return defaultMessage.
export const getUserReadableStatus = (callState, untrackedMessage, successMessage) => {
    if (callState === "UNKNOWN")
        return untrackedMessage;
    if (callState === "default" ||
        callState === CallState.DEFAULT ||
        callState === CallState.SOCKET_INITIALIZED)
        return "The Wiser bot was not yet asked to record this meeting. You can either toggle it into meetings manually or via your autojoin settings.";
    if (callState === CallState.JOIN_ATTEMPTED)
        return "The Wiser bot was not admitted to this meeting. Typically, this is due to the meeting not being started by the host.";
    if (callState === CallState.IN_WAITING_ROOM)
        return "The Wiser bot was not admitted to this meeting from the waiting room.";
    if (callState === CallState.KICKED_FROM_WAITING_ROOM)
        return "The Wiser bot was kicked from the waiting room.";
    if (callState == CallState.JOINED)
        return "The Wiser bot joined this meeting, but no other participants were present.";
    if (callState == CallState.PARTICIPANTS_JOINED)
        return ("The Wiser bot joined this meeting, and participants joined, but the host was not in the meeting or not fully logged in." +
            " Without the host to grant permissions, recording is impossible.");
    if (callState == CallState.HOST_PRESENT)
        return "The Wiser bot joined this meeting, but the host did not grant permission to record.";
    if (callState == CallState.HOST_SETTINGS_BLOCK_LOCAL_RECORDING)
        return "The Wiser bot joined this meeting, but the host's Zoom settings block local recording.";
    if (callState == CallState.RECORDING_PERMISSION_GRANTED)
        return "The Wiser bot joined and was granted recording permission, but there were no audible participants in the call.";
    if (callState == CallState.TRANSCRIPT_LOGGED) {
        return successMessage;
    }
    if (callState == CallState.KICKED_FROM_MEETING) {
        return "The Wiser bot was kicked from the meeting.";
    }
    if (callState === CallState.BUILT_FROM_ZOOM_CLOUD_RECORDING ||
        callState === CallState.BUILT_FROM_BOT_FULL_AUDIO_RECORDING) {
        return "The wiser bot successfully joined the meeting and recorded the call.";
    }
    if (callState === CallState.BOT_STOPPED_UNEXPECTEDLY ||
        callState === CallState.FATAL_BOT_ERROR)
        return "The Wiser bot stopped unexpectedly. Please contact support.";
    return "Unexpected state: " + callState;
};
