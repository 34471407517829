export const getOrderLabel = (fieldType, sortOrder) => {
    switch (fieldType) {
        case "number":
            return sortOrder === "ASC" ? "0 -> 9" : "9 -> 0";
        case "date":
        case "relative_date":
        case "datestring":
            return sortOrder === "ASC" ? "Earliest -> Latest" : "Latest -> Earliest";
        default:
            return sortOrder === "ASC" ? "A -> Z" : "Z -> A";
    }
};
