var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { createBrick, setBrickLinksToTarget, updateBrick, } from "../common/endpoints";
import { useMutation } from "react-query";
import CreateOrEditBriefing from "../pre_call_prep/create_or_edit_briefing";
import { Button } from "../components/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, } from "../components/modal_dialog";
import { Separator } from "../components/separator";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Tabs, TabsList, TabsTrigger } from "../components/tabs";
import BrickPreviews from "./brick_previews";
import { TabsContent } from "@radix-ui/react-tabs";
const TYPE_TO_DELIVERY_TARGETS = new Map([
    [
        "ACCOUNT",
        [
            {
                id: "AccountUI",
                name: "Account Page",
            },
            {
                id: "AccountSummary",
                name: "Account Summary (only latest will be used)",
            },
        ],
    ],
    [
        "CALL",
        [
            {
                id: "Slack",
                name: "Slack",
            },
            {
                id: "Email",
                name: "Email",
            },
        ],
    ],
    ["CALL_OVERVIEW", []],
    [
        "CONTACT",
        [
            {
                id: "ContactEmailDrafts",
                name: "Contact Email Drafts",
            },
        ],
    ],
]);
const sourceBrickTypeMap = {
    ACCOUNT: ["ACCOUNT", "TRACKER"],
    CALL: ["CALL", "ACCOUNT", "TRACKER"],
    CONTACT: ["CONTACT", "ACCOUNT"],
    MEETING_TEMPLATE_ASSIGNMENT: ["MEETING_TEMPLATE_ASSIGNMENT"],
    CALL_OVERVIEW: ["CALL_OVERVIEW"],
    CALL_KEYPOINTS: ["CALL_KEYPOINTS"],
    ACCOUNT_PREVIEW: ["ACCOUNT_PREVIEW"],
    CALL_PREVIEW: ["CALL_PREVIEW"],
    MEETING_TEMPLATE_ASSIGNMENT_PREVIEW: ["MEETING_TEMPLATE_ASSIGNMENT_PREVIEW"],
    CALL_OVERVIEW_PREVIEW: ["CALL_OVERVIEW_PREVIEW"],
    CALL_KEYPOINTS_PREVIEW: ["CALL_KEYPOINTS_PREVIEW"],
    TRACKER: ["TRACKER"],
};
const BrickEditDialog = ({ creatingBrick, setCreatingBrick, selectedBrickId, selectedType, selectedTypeLabel, allBricks, brickLinks, linkAvailability, queryClient, }) => {
    const [tempBrickForPreviewId, setTempBrickForPreviewId] = useState(null);
    const [newBrickPrompt, setNewBrickPrompt] = useState("");
    const [newBrickName, setNewBrickName] = useState("");
    const [selectedDataSources, setSelectedDataSources] = useState(new Set());
    const [selectedDeliveryTargets, setSelectedDeliveryTargets] = useState(new Set());
    const [selectedSourceBricks, setSelectedSourceBricks] = useState([]);
    const [selectedModel, setSelectedModel] = useState("OPENAI_FLAGSHIP");
    useEffect(() => {
        if (brickLinks !== undefined && selectedBrickId !== null) {
            setSelectedSourceBricks(brickLinks
                .filter((link) => link.target_brick_id === selectedBrickId)
                .map((link) => allBricks === null || allBricks === void 0 ? void 0 : allBricks.find((brick) => brick.id === link.source_brick_id))
                // While this shouldn't actually filter anything out, Typescript
                // requires it for the types to compile -- since it's possible (from a compiler
                // point of view) that the line above (allBricks?.find) could return undefined.
                // We also have to tell the compiler that the output will be a Brick, as it can't
                // infer that it can no longer be undefined.
                .filter((brick) => brick !== undefined));
        }
    }, [brickLinks, selectedBrickId]);
    const availableSourceBricks = useMemo(() => {
        if (linkAvailability === undefined || allBricks === undefined) {
            return [];
        }
        const available = linkAvailability.find((availabilityForTarget) => {
            return availabilityForTarget.target_brick_id === selectedBrickId;
        });
        return allBricks
            .map((brick) => brick.id)
            .filter((brickId) => {
            return (available === undefined ||
                !available.unavailable_source_brick_ids.includes(brickId));
        });
    }, [linkAvailability, allBricks, selectedBrickId]);
    const existingBrickNames = useMemo(() => {
        var _a;
        return new Set((_a = allBricks === null || allBricks === void 0 ? void 0 : allBricks.filter((brick) => brick.id !== selectedBrickId).map((brick) => brick.name)) !== null && _a !== void 0 ? _a : []);
    }, [allBricks, selectedBrickId]);
    const clearBrickDetails = () => {
        setNewBrickName("");
        setNewBrickPrompt("");
        setSelectedDataSources(new Set());
        setSelectedDeliveryTargets(new Set());
        setSelectedSourceBricks([]);
        setPreviewOrEditTabSelection("create_or_edit");
        setSelectedModel("OPENAI_FLAGSHIP");
    };
    useEffect(() => {
        if (selectedBrickId === null || allBricks === undefined) {
            clearBrickDetails();
            return;
        }
        const selectedBrickList = allBricks.filter((brick) => brick.id === selectedBrickId);
        if (selectedBrickList.length === 0) {
            clearBrickDetails();
            return;
        }
        const selectedBrick = selectedBrickList[0];
        setNewBrickName(selectedBrick.name);
        setNewBrickPrompt(selectedBrick.prompt);
        setSelectedDataSources(new Set(selectedBrick.data_sources));
        setSelectedDeliveryTargets(new Set(selectedBrick.delivery_targets));
        setSelectedModel(selectedBrick.model);
        // Note, the links are handled in a separate useEffect, since they depend
        // on brickLinks rather than allBricks.
    }, [selectedBrickId, allBricks]);
    const [isSaving, setIsSaving] = useState(false);
    const [previewOrEditTabSelection, setPreviewOrEditTabSelection] = useState("create_or_edit");
    useEffect(() => {
        // Clear all the settings when the modal closes.
        if (!creatingBrick) {
            clearBrickDetails();
        }
    }, [creatingBrick]);
    const potentialSourceBricks = allBricks
        .filter((brick) => sourceBrickTypeMap[selectedType].includes(brick.research_block_type))
        .filter((brick) => availableSourceBricks.includes(brick.id));
    const setBrickLinksMutation = useMutation({
        mutationFn: setBrickLinksToTarget,
        onSuccess: (response) => {
            queryClient.setQueryData(["all_bricks"], (oldData) => {
                var _a;
                return {
                    bricks: (_a = oldData === null || oldData === void 0 ? void 0 : oldData.bricks) !== null && _a !== void 0 ? _a : [],
                    brick_links: response.brick_links,
                    link_availability: response.link_availability,
                };
            });
        },
    });
    const createBrickMutation = useMutation({
        mutationFn: createBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            const prevBricks = allBricks !== null && allBricks !== void 0 ? allBricks : [];
            queryClient.setQueryData(["all_bricks"], {
                bricks: [...prevBricks, updatedBrick],
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
        }),
    });
    const updateBrickMutation = useMutation({
        mutationFn: updateBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            queryClient.setQueryData(["all_bricks"], {
                bricks: allBricks === null || allBricks === void 0 ? void 0 : allBricks.map((b) => b.id === updatedBrick.id ? updatedBrick : b),
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
        }),
    });
    return (_jsx(Dialog, Object.assign({ open: creatingBrick, onOpenChange: setCreatingBrick }, { children: _jsx(DialogContent, Object.assign({ className: "sm:w-full sm:max-w-xl lg:max-w-6xl" }, { children: _jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: selectedBrickId ? "Edit Prompt" : "Create New Prompt" }), _jsxs(DialogDescription, { children: ["This will be used to help extract information at the", " ", selectedTypeLabel, " level."] }), _jsxs(Tabs, Object.assign({ defaultValue: "create_or_edit", className: "w-full border-wds-gray-3", onValueChange: setPreviewOrEditTabSelection, value: previewOrEditTabSelection }, { children: [_jsx("div", Object.assign({ className: "border-b border-wds-gray-3 w-full mb-2" }, { children: _jsx(TabsList, Object.assign({ className: "border-none" }, { children: ["create_or_edit", "preview"].map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab, className: "outline-none px-4 py-2 justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-blue-600 data-[state=active]:border-b-2 data-[state=active]:bg-white" }, { children: tab === "create_or_edit"
                                            ? "Create/Edit"
                                            : "Preview Changes" }), tab))) })) })), _jsx(TabsContent, Object.assign({ value: "create_or_edit" }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: selectedType, name: newBrickName, setName: setNewBrickName, prompt: newBrickPrompt, setPrompt: setNewBrickPrompt, selectedDataSources: selectedDataSources, deliveryTargets: selectedDeliveryTargets, setSelectedDataSources: setSelectedDataSources, setDeliveryTargets: setSelectedDeliveryTargets, potentialSourceBricks: potentialSourceBricks, selectedSourceBricks: selectedSourceBricks, selectSourceBricks: setSelectedSourceBricks, deliveryTargetOptions: TYPE_TO_DELIVERY_TARGETS.get(selectedType), selectedModel: selectedModel, setSelectedModel: setSelectedModel }) })), _jsx(TabsContent, Object.assign({ value: "preview" }, { children: _jsx(BrickPreviews, { newPrompt: newBrickPrompt, brickId: selectedBrickId !== null && selectedBrickId !== void 0 ? selectedBrickId : tempBrickForPreviewId, brickName: newBrickName }) }))] })), _jsx(Separator, { orientation: "horizontal" }), _jsx(DialogFooter, Object.assign({ className: "sm:justify-end" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row w-full justify-between items-center" }, { children: [_jsx("div", Object.assign({ className: "flex w-full text-destructive" }, { children: existingBrickNames.has(newBrickName) &&
                                        "A prompt with this name already exists." })), _jsxs("div", Object.assign({ className: "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", onClick: () => setCreatingBrick(false) }, { children: "Cancel" })), _jsx(Button, Object.assign({ type: "button", variant: "light", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                if (!selectedBrickId) {
                                                    // If the brick does not exist, we make a preview version
                                                    const resp = yield createBrickMutation.mutateAsync({
                                                        name: newBrickName,
                                                        dataSources: [...selectedDataSources],
                                                        prompt: newBrickPrompt,
                                                        deliveryTargets: [...selectedDeliveryTargets],
                                                        researchBlockType: selectedType,
                                                        preview: true,
                                                        model: selectedModel,
                                                    });
                                                    setTempBrickForPreviewId(resp.id);
                                                }
                                                setPreviewOrEditTabSelection("preview");
                                            }) }, { children: "Preview Output" })), _jsx(Button, Object.assign({ type: "button", variant: "default", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                if (selectedBrickId) {
                                                    yield updateBrickMutation.mutateAsync({
                                                        id: selectedBrickId,
                                                        name: newBrickName,
                                                        dataSources: [...selectedDataSources],
                                                        prompt: newBrickPrompt,
                                                        deliveryTargets: [...selectedDeliveryTargets],
                                                        model: selectedModel,
                                                    });
                                                }
                                                else {
                                                    const resp = yield createBrickMutation.mutateAsync({
                                                        name: newBrickName,
                                                        dataSources: [...selectedDataSources],
                                                        prompt: newBrickPrompt,
                                                        deliveryTargets: [...selectedDeliveryTargets],
                                                        researchBlockType: selectedType,
                                                        model: selectedModel,
                                                    });
                                                }
                                                setCreatingBrick(false);
                                            }), disabled: newBrickName === "" ||
                                                newBrickPrompt === "" ||
                                                existingBrickNames.has(newBrickName) }, { children: isSaving ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin" })) : ("Save") }))] }))] })) }))] }) })) })));
};
export default BrickEditDialog;
