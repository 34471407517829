import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { useInfiniteQuery, useQuery } from "react-query";
import { getActiveTenant } from "../common/endpoints";
import { useMemo, useState } from "react";
import { DEFAULT_DEPARTMENT_TABLE_LAYOUT } from "../settings/constants";
import { debounce } from "../common/debounce";
import { useCustomSearchParams } from "../crm/use_memoised_search_params";
import { addSearchParamsToQuery } from "./department.helpers";
import { getDepartments } from "./endpoints";
import { DepartmentTable } from "./department_table";
export function DepartmentsIndex() {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const { searchParams, layouts, isLoading: layoutsLoading, } = useCustomSearchParams("departments", DEFAULT_DEPARTMENT_TABLE_LAYOUT);
    const filterQuery = addSearchParamsToQuery(searchParams, "Department", debouncedQuery);
    const pageSize = 50;
    const { data, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(["getDepartments", filterQuery, pageSize], ({ pageParam = 0 }) => getDepartments(filterQuery, pageParam + 1, pageSize), {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    //flatten the array of arrays from the useInfiniteQuery hook
    const departments = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    });
    const total = useMemo(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0) {
            return data.pages[0].total;
        }
        return 0;
    }, [data === null || data === void 0 ? void 0 : data.pages, data]);
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsx("div", Object.assign({ className: "w-60 text-black text-3xl p-2" }, { children: "Departments" })), !data ||
                isLoading ||
                layoutsLoading ||
                !activeTenant ||
                !layouts ||
                layouts.length === 0 ? (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true })) : (_jsx(DepartmentTable, { departments: departments, total: total, layouts: layouts, searchQuery: searchQuery, setSearchQuery: setSearchQuery, tenant: activeTenant, searchParams: searchParams, filterQuery: filterQuery, fetchNextPage: fetchNextPage, isFetching: isFetchingNextPage }))] })));
}
