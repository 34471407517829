var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "./class_names";
import { confirmModal } from "./common/confirmation_modal";
const RightDrawer = ({ isOpen, onClose, children, title, deleteCallback, }) => {
    return (
    // have to add font styles since the main App.js styles don't apply here since this is not in it's DOM heirarchy
    _jsx("div", Object.assign({ className: "relative font-['Lato'] font-normal text-sm" }, { children: _jsx("div", Object.assign({ className: classNames("fixed top-0 right-0 h-full w-1/4 bg-white shadow-lg", isOpen ? "right-0" : "-right-1/4") }, { children: _jsxs("div", Object.assign({ className: "w-full p-8 h-screen bg-white border-l border-zinc-200 flex-col justify-start items-start gap-8 inline-flex overflow-y-auto" }, { children: [_jsxs("div", Object.assign({ className: "w-full self-stretch h-7 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-zinc-950 text-2xl font-mediumleading-loose" }, { children: title })), _jsxs("div", Object.assign({ className: "justify-start items-center flex" }, { children: [deleteCallback ? (_jsx("button", Object.assign({ className: "w-10 h-10 rounded-full justify-center items-center flex hover:bg-wds-gray-3", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            return confirmModal(deleteCallback, "Are you sure?", "", "Remove", "Cancel");
                                        }) }, { children: _jsx(TrashIcon, { className: "text-wds-gray-5 w-5 h-5 relative" }) }))) : null, _jsx("button", Object.assign({ className: "w-10 h-10 rounded-full justify-center items-center flex hover:bg-wds-gray-3", onClick: onClose }, { children: _jsx(XMarkIcon, { className: "text-wds-gray-5 w-5 h-5 relative" }) }))] }))] })), children] })) })) })));
};
export default RightDrawer;
