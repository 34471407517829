import tw from "tailwind-styled-components";
export const AccountBlockCard = tw.div `
    flex
    p-6
    flex-col
    items-start
    gap-6
    self-stretch
    rounded-2xl
    border
    border-wds-gray-3
    bg-white
    scroll-mt-4
    w-full
    hover:border-2
    hover:shadow-[0_4px_8px_0_rgba(35,45,56,0.16)]
    hover:border-[rgba(53,110,255,0.32)]
`;
