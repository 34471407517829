var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { useNavigate } from "react-router-dom";
import { DEFAULT_DEPARTMENT_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import { useMutation, useQueryClient, } from "react-query";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
import { useMemo, useState } from "react";
import { FIELDS, getColumns, SORTABLE_FIELDS, DEPARTMENT_SCHEMA, useFormFieldMeta, } from "./department.helpers";
import DepartmentForm from "./department_form";
import { DepartmentsExport } from "./department_export";
import { createDepartment } from "./endpoints";
import { useToast } from "../components/use_toast";
import { Button } from "../components/button";
import { PlusIcon } from "@heroicons/react/24/outline";
import BaseModal2 from "../base_modal2";
import { DepartmentTableFloatingBar } from "./department_table_floating_bar";
export function DepartmentTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { toast } = useToast();
    const departments = data.departments;
    const total = data.total;
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    // Memoize the columns so they don't re-render on every render
    const columns = useMemo(() => getColumns(setSelectedDepartment), []);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = useState(getColumnOrder(columns, matchingLayout));
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(FIELDS);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const createMutation = useMutation({
        mutationFn: (values) => __awaiter(this, void 0, void 0, function* () {
            return yield createDepartment(values);
        }),
        onSuccess: (data) => {
            queryClient.invalidateQueries();
            toast({
                title: "Department created",
                description: "Department created successfully",
                variant: "green",
            });
            setCreateModalOpen(false);
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `${error}`,
                variant: "destructive",
            });
        },
    });
    const { table } = useDataTable({
        data: departments,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: {
                precall_notification_duration: false,
                precall_email_enabled: false,
                precall_slack_enabled: false,
                custom_bot_name: false,
                no_live_bot: false,
                timezone: false,
                total_internal_meetings: false,
            },
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    const formFieldMeta = useFormFieldMeta(queryClient);
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: data.fetchNextPage, total: total, totalFetched: departments.length, isFetching: data.isFetching, floatingBar: _jsx(DepartmentTableFloatingBar, { table: table }) }, { children: _jsxs(DataTableToolbarV2, Object.assign({ table: table, columnIdMap: {}, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_DEPARTMENT_TABLE_LAYOUT, onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me departments", sortRuleBuilderPanelTitle: "Sort departments by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, onSearchQueryChange: data.setSearchQuery, matchingLayout: matchingLayout }, { children: [_jsx(DepartmentsExport, { filterQuery: data.filterQuery }), _jsxs(Button, Object.assign({ onClick: () => setCreateModalOpen(true) }, { children: ["Add department", _jsx(PlusIcon, { className: "h-4 w-4 ml-2 stroke-2 text-wds-white" })] }))] })) })), _jsx(Drawer, Object.assign({ open: !!selectedDepartment, onOpenChange: () => setSelectedDepartment(undefined) }, { children: _jsxs(DrawerContent, Object.assign({ className: "sm:max-w-none sm:w-1/4" }, { children: [_jsx(DrawerTitle, Object.assign({ className: "text-wds-black" }, { children: "Department Details" })), selectedDepartment && (_jsx(DepartmentForm, { department: selectedDepartment, setSelectedDepartment: setSelectedDepartment }))] })) })), _jsx(BaseModal2, { title: "Create department", description: "Create a new department", open: createModalOpen, setOpen: (open) => setCreateModalOpen(open), submitText: "Create", submitCallback: (values) => __awaiter(this, void 0, void 0, function* () {
                    yield createMutation.mutateAsync(values);
                }), zodSchema: DEPARTMENT_SCHEMA, formFieldMeta: formFieldMeta })] }));
}
