import { createFilter, createFilterGroup } from "./api";
import { NULL_VALUE_ALLOWED_OPERATORS } from "./config";
import { FilterLogicalOperator, FilterOperator, RelativeDate, WiserBackendQueryOperator, } from "./types";
const RELATIVE_TIME_FIELDS = [
    "created_at",
    "updated_at",
    "call_time",
    "timestamp",
    "last_call_time",
    "last_login_at",
];
export const FILTER_OPERATOR_TO_BACKEND_OPERATOR_MAP = new Map([
    [FilterLogicalOperator.AND, WiserBackendQueryOperator.AND],
    [FilterLogicalOperator.OR, WiserBackendQueryOperator.OR],
    [FilterOperator.CONTAINS, WiserBackendQueryOperator.LIKE],
    [FilterOperator.DOES_NOT_CONTAIN, WiserBackendQueryOperator.NOT_LIKE],
    [FilterOperator.STARTS_WITH, WiserBackendQueryOperator.STARTS_WITH],
    [FilterOperator.ENDS_WITH, WiserBackendQueryOperator.ENDS_WITH],
    [FilterOperator.EQUALS, WiserBackendQueryOperator.EQUALS],
    [FilterOperator.NOT_EQUAL, WiserBackendQueryOperator.NOT_EQUALS],
    [
        FilterOperator.GREATER_THAN_OR_EQUAL,
        WiserBackendQueryOperator.GREATER_THAN_OR_EQUAL,
    ],
    [
        FilterOperator.LESS_THAN_OR_EQUAL,
        WiserBackendQueryOperator.LESS_THAN_OR_EQUAL,
    ],
    [FilterOperator.IS_NOT_EMPTY, WiserBackendQueryOperator.IS_NOT_NULL],
    [FilterOperator.IS_EMPTY, WiserBackendQueryOperator.IS_NULL],
    [FilterOperator.IN, WiserBackendQueryOperator.IN],
    [FilterOperator.SUBSTRING_IN, WiserBackendQueryOperator.SUBSTRING_IN],
]);
export const getWiserBackendOperator = (filterOperator) => {
    if (!FILTER_OPERATOR_TO_BACKEND_OPERATOR_MAP.has(filterOperator)) {
        throw new Error(`Operator ${filterOperator} missing in operator map`);
    }
    return FILTER_OPERATOR_TO_BACKEND_OPERATOR_MAP.get(filterOperator);
};
export const getFilterOperator = (backendOperator) => {
    const reverseOperatorMap = new Map();
    Array.from(FILTER_OPERATOR_TO_BACKEND_OPERATOR_MAP.entries()).forEach(([key, value]) => {
        reverseOperatorMap.set(value, key);
    });
    if (!reverseOperatorMap.has(backendOperator)) {
        throw new Error(`Operator ${backendOperator} missing in operator map`);
    }
    return reverseOperatorMap.get(backendOperator);
};
/**
 * This class exposes the methods to convert the filtered FE query
 * `FilterGroup` into the query engine compatible JSON query structure
 * and vice-versa.
 */
export class WiserBackendQueryConverter {
    constructor(config) {
        this.config = config;
    }
    isLogicalWiserBackendOperator(operator) {
        return [
            WiserBackendQueryOperator.AND,
            WiserBackendQueryOperator.OR,
        ].includes(operator);
    }
    getWiserBackendFilterValue(fieldType, filterValue, filterOperator) {
        if (fieldType === "date" &&
            filterValue &&
            filterOperator !== FilterOperator.IN) {
            return Math.floor(filterValue.getTime() / 1000);
        }
        return filterValue;
    }
    getWhereConditionForFilter(filter) {
        return {
            operator: getWiserBackendOperator(filter.operator),
            field: filter.field.id,
            value: this.getWiserBackendFilterValue(filter.field.type, filter.value, filter.operator),
        };
    }
    buildBackendSubquery(filter) {
        var _a, _b;
        if (!((_a = filter.field.meta) === null || _a === void 0 ? void 0 : _a.relatedModelFieldName) ||
            !((_b = filter.field.meta) === null || _b === void 0 ? void 0 : _b.model)) {
            throw new Error("Subquery cannot be formed without `model` & `relatedModelFieldName`");
        }
        const queryModel = filter.field.meta.model;
        const relatedModelFieldName = filter.field.meta.relatedModelFieldName;
        let structured_filter = undefined;
        if (filter.field.meta.proxyFieldKey && filter.field.meta.proxyFieldValue) {
            // Proxy field form two different filters in the subquery
            structured_filter = {
                where_condition: {
                    operator: WiserBackendQueryOperator.AND,
                    subconditions: [
                        {
                            where_condition: {
                                operator: WiserBackendQueryOperator.EQUALS,
                                field: filter.field.meta.proxyFieldKey,
                                value: parseInt(filter.field.id),
                            },
                        },
                        {
                            where_condition: {
                                operator: getWiserBackendOperator(filter.operator),
                                field: filter.field.meta.proxyFieldValue,
                                value: this.getWiserBackendFilterValue(filter.field.type, filter.value, filter.operator),
                            },
                        },
                    ],
                },
            };
        }
        else {
            structured_filter = {
                where_condition: this.getWhereConditionForFilter(filter),
            };
        }
        if (!structured_filter) {
            throw new Error(`Could not form structured filter for the subquery - ${filter}`);
        }
        return {
            where_condition: {
                field: "id",
                operator: WiserBackendQueryOperator.IN,
                subquery: {
                    table: queryModel,
                    select_field: relatedModelFieldName,
                    structured_filter: structured_filter,
                    order_by_fields: [],
                },
            },
        };
    }
    convertFrontendFilterToWhereCondition(frontendFilter) {
        var _a;
        if ((_a = frontendFilter.field.meta) === null || _a === void 0 ? void 0 : _a.relatedModelFieldName) {
            return this.buildBackendSubquery(frontendFilter);
        }
        return {
            where_condition: this.getWhereConditionForFilter(frontendFilter),
        };
    }
    convertBackendWhereConditionToFilter(whereCondition, parentFilterGroupId) {
        var _a, _b;
        if (this.isLogicalWiserBackendOperator(whereCondition.operator)) {
            throw new Error(`WhereCondition - ${whereCondition} cannot be converted to a Filter`);
        }
        if (whereCondition.operator === WiserBackendQueryOperator.IN &&
            whereCondition.subquery) {
            const subqueryFilter = whereCondition.subquery.structured_filter;
            // This is a special case as we don't want to directly convert subqueries into a FE filter
            // but instead convert it as per the defined config.
            // There can be two types of subqueries:
            // 1. Common type where the subquery contains a single query matching a specific field
            //  from a different model.
            // 2. Specific to CRMs where the subquery itself contains two queries for proxyFieldKey
            //  and proxyFieldValue.
            // See more details in `FilterField` type in `./types.ts`.
            // To identify subqueries of type 2, currently the best way is to check how many subqueries
            // are there in the filter. If there are two, we treat them as proxy query, and hence try to
            // find a field whose id matches the field value, and proxyFieldKey matches the subquery.
            //
            if (subqueryFilter.where_condition.operator ===
                WiserBackendQueryOperator.AND &&
                ((_a = subqueryFilter.where_condition.subconditions) === null || _a === void 0 ? void 0 : _a.length) === 2) {
                const [sub1, sub2] = subqueryFilter.where_condition.subconditions;
                const conditionsToMatch = [
                    {
                        id: `${sub1.where_condition.value}`,
                        proxyKey: sub1.where_condition.field,
                        proxyValue: sub2.where_condition.field,
                    },
                    {
                        id: `${sub2.where_condition.value}`,
                        proxyKey: sub2.where_condition.field,
                        proxyValue: sub1.where_condition.field,
                    },
                ];
                const field = this.config.fields.find((field) => conditionsToMatch.some((condition) => {
                    var _a, _b;
                    return ((_a = field.meta) === null || _a === void 0 ? void 0 : _a.proxyFieldKey) === condition.proxyKey &&
                        field.id === condition.id &&
                        ((_b = field.meta) === null || _b === void 0 ? void 0 : _b.proxyFieldValue) === condition.proxyValue;
                }));
                if (!field) {
                    throw new Error(`Could not find field for the proxy subquery - ${JSON.stringify(whereCondition)}, 
            ${JSON.stringify(this.config.fields)}`);
                }
                const proxyCondition = conditionsToMatch[0].proxyKey === ((_b = field.meta) === null || _b === void 0 ? void 0 : _b.proxyFieldKey)
                    ? sub2.where_condition
                    : sub1.where_condition;
                return createFilter({
                    field,
                    operator: getFilterOperator(proxyCondition.operator),
                    value: this.getBackendToFrontendFilterValue(proxyCondition.value, field.type, proxyCondition.operator),
                    parentFilterGroupId,
                });
            }
        }
        const finalWhereCondition = whereCondition.subquery
            ? whereCondition.subquery.structured_filter.where_condition
            : whereCondition;
        const field = this.config.fields.find((field) => field.id === finalWhereCondition.field);
        if (!field) {
            throw new Error(`Could not find field - ${JSON.stringify(whereCondition)} in the config`);
        }
        return createFilter({
            operator: getFilterOperator(finalWhereCondition.operator),
            field,
            value: this.getBackendToFrontendFilterValue(finalWhereCondition.value, field.type, finalWhereCondition.operator),
            parentFilterGroupId,
        });
    }
    getBackendToFrontendFilterValue(filterValue, fieldType, filterOperator) {
        if (filterOperator !== WiserBackendQueryOperator.IN) {
            if (fieldType === "date") {
                return new Date(parseInt(filterValue) * 1000);
            }
            else if (fieldType === "datestring") {
                return new Date(filterValue).toISOString();
            }
        }
        return filterValue;
    }
    convertQueryFilterToBackendFilter(frontendFilterGroup) {
        const operator = frontendFilterGroup.operator;
        const subconditions = frontendFilterGroup.conditions
            .filter((condition) => condition.objectType !== "FILTER" ||
            NULL_VALUE_ALLOWED_OPERATORS.includes(condition.operator) ||
            (condition.value !== null && condition.value !== undefined))
            .map((condition) => condition.objectType === "FILTER"
            ? this.convertFrontendFilterToWhereCondition(condition)
            : this.convertQueryFilterToBackendFilter(condition));
        return {
            where_condition: {
                operator: getWiserBackendOperator(operator),
                subconditions: subconditions,
            },
        };
    }
    convertBackendFilterToQueryFilter(backendFilter, parentFilterGroupId) {
        if (this.isLogicalWiserBackendOperator(backendFilter.where_condition.operator)) {
            const filterGroup = createFilterGroup(getFilterOperator(backendFilter.where_condition.operator), 
            // Will be adding conditions later as we need the id of this group for back-reference.
            /* conditions= */ [], parentFilterGroupId);
            filterGroup.conditions = backendFilter.where_condition.subconditions
                ? [
                    ...backendFilter.where_condition.subconditions.map((condition) => this.convertBackendFilterToQueryFilter(condition, filterGroup.id)),
                ]
                : [];
            return filterGroup;
        }
        return this.convertBackendWhereConditionToFilter(backendFilter.where_condition, parentFilterGroupId);
    }
}
const recursiveConvertWhereCondition = (where_condition) => {
    if (where_condition.subconditions &&
        where_condition.subconditions.length > 0) {
        where_condition.subconditions.forEach((subCondition) => {
            subCondition.where_condition = recursiveConvertWhereCondition(subCondition.where_condition);
        });
    }
    if (where_condition.operator === WiserBackendQueryOperator.IN &&
        where_condition.field &&
        RELATIVE_TIME_FIELDS.includes(where_condition.field)) {
        const from = new Date(`${new Intl.DateTimeFormat("en-CA").format(new Date())}T00:00:00`);
        const to = new Date(`${new Intl.DateTimeFormat("en-CA").format(new Date())}T00:00:00`);
        let fromDate = 0;
        let toDate = 0;
        switch (where_condition.value) {
            case RelativeDate.TODAY:
                break;
            case RelativeDate.TOMORROW:
                fromDate = 1;
                toDate = 1;
                break;
            case RelativeDate.YESTERDAY:
                fromDate = -1;
                toDate = -1;
                break;
            case RelativeDate.NEXT_WEEK:
                toDate = 6;
                break;
            case RelativeDate.LAST_WEEK:
                fromDate = -6;
                break;
            case RelativeDate.LAST_2_WEEKS:
                fromDate = -13;
                break;
            case RelativeDate.LAST_MONTH:
                fromDate = -30;
                break;
            case RelativeDate.LAST_YEAR:
                fromDate = -365;
                break;
            default:
                throw new Error(`Invalid relative date - ${where_condition.value}`);
        }
        from.setDate(from.getDate() + fromDate);
        to.setDate(to.getDate() + toDate);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        where_condition = {
            operator: WiserBackendQueryOperator.AND,
            subconditions: [
                {
                    where_condition: {
                        operator: WiserBackendQueryOperator.GREATER_THAN_OR_EQUAL,
                        field: where_condition.field,
                        value: Math.floor(from.getTime() / 1000),
                    },
                },
                {
                    where_condition: {
                        operator: WiserBackendQueryOperator.LESS_THAN_OR_EQUAL,
                        field: where_condition.field,
                        value: Math.floor(to.getTime() / 1000),
                    },
                },
            ],
        };
    }
    else if (where_condition.operator === WiserBackendQueryOperator.EQUALS &&
        where_condition.field &&
        RELATIVE_TIME_FIELDS.includes(where_condition.field)) {
        const from = new Date(where_condition.value * 1000);
        const to = new Date(where_condition.value * 1000);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        where_condition = {
            operator: WiserBackendQueryOperator.AND,
            subconditions: [
                {
                    where_condition: {
                        operator: WiserBackendQueryOperator.GREATER_THAN_OR_EQUAL,
                        field: where_condition.field,
                        value: Math.floor(from.getTime() / 1000),
                    },
                },
                {
                    where_condition: {
                        operator: WiserBackendQueryOperator.LESS_THAN_OR_EQUAL,
                        field: where_condition.field,
                        value: Math.floor(to.getTime() / 1000),
                    },
                },
            ],
        };
    }
    return where_condition;
};
export const getConvertedJsonQuery = (json_string) => {
    // This logic should be acommodated into the query engine in BE instead of FE
    // Tracked here - https://getwiser.atlassian.net/browse/WISER-2200
    const backendFilter = JSON.parse(json_string);
    if (!backendFilter.structured_filter ||
        !backendFilter.structured_filter.where_condition) {
        return json_string;
    }
    const structured_filter = backendFilter.structured_filter;
    structured_filter.where_condition = recursiveConvertWhereCondition(structured_filter.where_condition);
    backendFilter.structured_filter = structured_filter;
    return JSON.stringify(backendFilter);
};
