import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Badge from "../common/badge";
import { ScrollArea } from "../components/scroll_area";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
const CrmFieldValueRenderer = (props) => {
    var _a;
    const getDateValue = (value) => {
        const date = new Date(value).toLocaleDateString("en-US");
        if (date === "Invalid Date") {
            return null;
        }
        return date;
    };
    switch (props.crmFieldSchema.type) {
        case "boolean":
            return (_jsx("div", Object.assign({ className: "flex flex-wrap" }, { children: _jsx(Badge, { text: props.crmFieldValue === "true" ? "True" : "False", classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, props.crmObjectId) })));
        case "single_select":
            return (_jsx("div", Object.assign({ className: "flex flex-wrap" }, { children: props.crmFieldValue && (_jsx(Badge, { text: props.crmFieldValue, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, props.crmObjectId)) })));
        case "multi_select":
            return (_jsx("div", Object.assign({ className: "flex gap-1 flex-wrap" }, { children: (_a = props.crmFieldValue) === null || _a === void 0 ? void 0 : _a.split(";").filter((option) => option !== "").map((option) => (_jsx(Badge, { text: option, classes: "bg-wds-blue-1", textClasses: "text-sm text-wds-blue-4 font-medium" }, option))) })));
        case "number":
            return (_jsx("span", Object.assign({ className: "font-medium text-sm text-black" }, { children: isNaN(parseFloat(props.crmFieldValue))
                    ? null
                    : parseFloat(props.crmFieldValue).toLocaleString("en-US") })));
        case "date":
            return (_jsx("span", Object.assign({ className: "font-medium text-sm text-black" }, { children: getDateValue(props.crmFieldValue) })));
        default:
            return (_jsxs(_Fragment, { children: [props.crmFieldValueStatus === "WAITING" && (_jsx("span", Object.assign({ className: "font-medium text-sm text-gray-500 italic" }, { children: "We're analyzing your calls. Check back in ~30 minutes to see your data enriched." }))), props.crmFieldValueStatus === "PROCESSING" && (_jsx("span", Object.assign({ className: "font-medium text-sm text-gray-500 italic" }, { children: "Processing..." }))), (!props.crmFieldValueStatus ||
                        props.crmFieldValueStatus === "COMPLETED") && (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("span", Object.assign({ className: "w-full font-medium text-sm text-black line-clamp-2" }, { children: props.crmFieldValue })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: props.crmFieldValue })) })) })] }) }))] }));
    }
};
export default CrmFieldValueRenderer;
