var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { getChatTemplates } from "../common/endpoints";
import useChatMessages from "../live_cards_chat_bot/use_chat_messages";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useStreamSocket, } from "../common/stream_socket";
import ChatSourceMenu, { CURRENT_DEAL, } from "../live_cards_chat_bot/chat_source_menu";
import { useLlmOutputFeedback } from "../llm_output_feedback/use_llm_output_feedback";
import Message from "../live_cards_chat_bot/message";
import { ChatSearchSelect } from "../components/chat_search_select";
import { CSSTransition } from "react-transition-group";
export const ChatBox = ({ call, accountId, defaultChatSource, chatSources, setChatMessages, }) => {
    const clientId = useMemo(() => Math.floor(Math.random() * 1000000), []);
    const { chatMessages, addChatMessage, resetChatMessages, handlePlaybookRecommendations, handleChatMessage, handleEchoChat, handleChatProgress, } = useChatMessages();
    const applicableTo = accountId ? "Account" : "Post Call Output";
    const [query, setQuery] = useState();
    const [searchQuery, setSearchQuery] = useState(null);
    const streamSocket = useStreamSocket();
    const [socketReady, setSocketReady] = useState(false);
    const [forceSource, setForceSource] = useState(false);
    const [chatSuggestionSelected, setChatSuggestionSelected] = useState(false);
    const [divWidth, setDivWidth] = useState(0);
    // This is used to find and set the width correctly for the chat dropdown
    // This is necessary since the parent div's width is not accessible via CSS
    const divRef = useRef(null);
    // State to capture the chat message dismissal by the user to trigger a slide-out
    // animation for some fixed duration.
    const [messageDismissed, setMessageDismissed] = useState(false);
    useEffect(() => {
        if (setChatMessages) {
            setChatMessages(chatMessages);
        }
    }, [chatMessages]);
    const getChatTemplatesWrapper = (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const resp = yield getChatTemplates(applicableTo, userQuery, cursor);
        const results = resp.items.map((item) => {
            return {
                label: item.title,
                value: item.template_string,
                details: Object.assign({}, item),
            };
        });
        return {
            results,
            next_cursor: resp.next_cursor,
        };
    });
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ callId: call === null || call === void 0 ? void 0 : call.id, accountId: accountId });
    const chatInputRef = useRef(null);
    useEffect(() => {
        if (chatInputRef.current) {
            chatInputRef.current.focus();
        }
    }, [chatInputRef]);
    useEffect(() => {
        var _a;
        setSocketReady(false);
        resetChatMessages();
        streamSocket.addListener("chat_message", handleChatMessage);
        streamSocket.addListener("echo_back_chat", (message) => handleEchoChat(message, clientId));
        streamSocket.addListener("playbook_recommendations", handlePlaybookRecommendations);
        streamSocket.addListener("chat_progress", handleChatProgress);
        // We just need this to tell that the stream is started; every session
        // begins by sending a note or a placeholder.
        streamSocket.addListener("manual_note", (message) => { });
        streamSocket.addListener("display_notes", (message) => { });
        streamSocket.setOpenCallback(() => {
            setSocketReady(true);
        });
        streamSocket.setCloseCallback(() => {
            setSocketReady(false);
        });
        streamSocket.connectToStreamSocket((_a = call === null || call === void 0 ? void 0 : call.id) !== null && _a !== void 0 ? _a : 0);
        return () => {
            streamSocket.close();
        };
    }, [call]);
    const defaultSelectedChatSource = useMemo(() => {
        if (!!defaultChatSource)
            return defaultChatSource;
        if (chatSources && chatSources.length > 0)
            return chatSources[0];
        return CURRENT_DEAL;
    }, [defaultChatSource, chatSources]);
    const [selectedSource, setSelectedSource] = useState(defaultSelectedChatSource);
    const submitQuery = () => {
        if (!streamSocket || !socketReady || !searchQuery)
            return;
        const queryValue = searchQuery === (query === null || query === void 0 ? void 0 : query.label) ? query === null || query === void 0 ? void 0 : query.value : searchQuery;
        addUserMessageToChatAndRespond(
        // If the search query is the same as the selected option, use the value.
        queryValue, forceSource ? selectedSource.id : undefined, searchQuery === (query === null || query === void 0 ? void 0 : query.label) ? query === null || query === void 0 ? void 0 : query.label : undefined);
    };
    const botMessages = chatMessages.filter((message) => message.sender === "Bot");
    // The first message is always a welcome message we can ignore.
    const message = botMessages.length > 1 ? botMessages[botMessages.length - 1] : undefined;
    const addUserMessageToChatAndRespond = (userMessage, forceSource, title) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setMessageDismissed(false);
        addChatMessage({
            sender: "Bot",
            messageType: "Loading",
        });
        streamSocket.send(JSON.stringify({
            type: "get_best_card_from_string_input",
            inputString: userMessage,
            forceSource: forceSource !== null && forceSource !== void 0 ? forceSource : selectedSource.id,
            clientId: clientId,
            chatAgainstAccountId: accountId !== null && accountId !== void 0 ? accountId : (_a = call === null || call === void 0 ? void 0 : call.account) === null || _a === void 0 ? void 0 : _a.id,
            useCardInfo: selectedSource.options.useCardInfo,
            overrideTitle: title,
        }));
    });
    useEffect(() => {
        submitQuery();
    }, [chatSuggestionSelected]);
    useEffect(() => {
        // Function to update divWidth
        const updateWidth = () => {
            if (divRef.current) {
                setDivWidth(divRef.current.offsetWidth);
            }
        };
        // Call updateWidth once to set initial width
        updateWidth();
        // Add event listener for window resize
        window.addEventListener("resize", updateWidth);
        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);
    return (_jsxs("div", Object.assign({ className: "w-full flex flex-col space-y-2" }, { children: [_jsx("div", Object.assign({ className: "rounded-full border-0 p-[2px]", style: {
                    backgroundImage: `linear-gradient(to top right, #0171F5, #C026D3)`,
                } }, { children: _jsxs("div", Object.assign({ className: "flex w-full rounded-full border-0 bg-wds-gray-1 p-1 space-x-2", ref: divRef }, { children: [_jsx(ChatSearchSelect, { dataFetcher: getChatTemplatesWrapper, onSelect: (option) => {
                                if (!option)
                                    return;
                                setQuery(option);
                                const chatSource = chatSources === null || chatSources === void 0 ? void 0 : chatSources.find((chatSource) => chatSource.label === option.details.source);
                                if (chatSource && selectedSource.label !== chatSource.label) {
                                    setSelectedSource(chatSource);
                                    setForceSource(true);
                                }
                                setChatSuggestionSelected((prev) => !prev);
                            }, placeholder: "Ask Wiser anything about this deal...", selectedOption: query, width: divWidth, searchQuery: searchQuery, setSearchQuery: setSearchQuery, onKeyDown: (e) => {
                                if (e.key === "Enter") {
                                    submitQuery();
                                }
                            } }), _jsx("div", Object.assign({ className: "flex" }, { children: _jsx(ChatSourceMenu, { chatSources: chatSources, selectedSource: selectedSource, setSelectedSource: (chatSource) => {
                                    setSelectedSource(chatSource);
                                    setForceSource(true);
                                }, renderedInCard: true, className: "bg-wds-gray-1" }) })), _jsxs("button", Object.assign({ className: "group flex flex-row space-x-2 px-3 rounded-full items-center justify-center text-white text-sm font-bold bg-gradient-ask disabled:opacity-50", disabled: !socketReady || !searchQuery || (searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.length) === 0, onClick: () => submitQuery() }, { children: [_jsx(SparklesIcon, { className: "flex w-4 h-4 text-white fill-white" }), _jsx("span", { children: "Ask" })] }))] })) })), !setChatMessages && (_jsx(CSSTransition, Object.assign({ in: !messageDismissed, timeout: 150, classNames: {
                    exitActive: "opacity-50 translate-x-full hover:transition-transform duration-300",
                    exit: "opacity-100 translate-x-0",
                }, onExited: resetChatMessages, unmountOnExit: true }, { children: _jsx(_Fragment, { children: chatMessages.length > 1 && (_jsx("div", Object.assign({ className: "bg-wds-gray-1 rounded-lg shadow-lg p-2 hover:transition-transform" }, { children: _jsx(Message, { message: chatMessages[chatMessages.length - 1], addUserMessageToChatAndRespond: addUserMessageToChatAndRespond, userLlmOutputFeedback: message &&
                                message.sender === "Bot" &&
                                message.messageType === "Card" &&
                                message.card.provenanceLogId
                                ? allLlmOutputFeedback === null || allLlmOutputFeedback === void 0 ? void 0 : allLlmOutputFeedback.find((f) => f.provenance_log_id ===
                                    message.card.provenanceLogId)
                                : undefined, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation, onMessageDismiss: () => setMessageDismissed(true) }) }))) }) })))] })));
};
