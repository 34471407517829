import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import classNames from "../../../class_names";
import { Button } from "../../../components/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../../../components/tooltip";
export const AccountWikiSideNav = ({ accountBlocks, accountBrickSetsWithBricks, scrollToBlock, }) => {
    // This is hard-coded because these are static blocks rendered in the account page.
    const ACCOUNT_OVERVIEW_TABLE_OF_CONTENT_ITEM = {
        id: "account-overview",
        label: "Account Overview",
        subItems: [
            {
                id: "account-overview",
                accountBlockId: "account-overview",
                label: "Overview",
            },
            {
                id: "account-calls",
                accountBlockId: "account-calls",
                label: "Calls",
            },
            {
                id: "account-contacts",
                accountBlockId: "account-contacts",
                label: "Contacts",
            },
        ],
    };
    const [sideNavItems, setSideNavItems] = useState([]);
    // Helper function to generate sub-items for a given set of bricks
    const generateSubItems = (bricks) => bricks
        .map((brick) => {
        const accountBlock = accountBlocks.find((block) => { var _a; return ((_a = block.brick) === null || _a === void 0 ? void 0 : _a.id) === brick.id; });
        return accountBlock
            ? {
                id: brick.id.toString(),
                accountBlockId: accountBlock.id,
                label: brick.name,
            }
            : null;
    })
        .filter(Boolean);
    useEffect(() => {
        if (!accountBrickSetsWithBricks)
            return;
        const tableOfContentItems = [
            ACCOUNT_OVERVIEW_TABLE_OF_CONTENT_ITEM,
        ];
        // Process brick sets with valid sub-items
        const sortedAccountBrickSetsWithBricks = accountBrickSetsWithBricks.sort(({ order: aOrder = 0 }, { order: bOrder = 0 }) => aOrder - bOrder);
        sortedAccountBrickSetsWithBricks.forEach((brickSet) => {
            const subItems = generateSubItems(brickSet.blocks);
            if (subItems.length > 0) {
                tableOfContentItems.push({
                    id: brickSet.id.toString(),
                    label: brickSet.name,
                    subItems,
                });
            }
        });
        // Process bricks not belonging to any brick set
        const allBrickSetBrickIds = new Set(accountBrickSetsWithBricks.flatMap((brickSet) => brickSet.blocks.map((brick) => brick.id)));
        const otherBricks = accountBlocks
            .filter((block) => block.brick && !allBrickSetBrickIds.has(block.brick.id))
            .map((block) => block.brick);
        const otherSubItems = generateSubItems(otherBricks);
        if (otherSubItems.length > 0) {
            tableOfContentItems.push({
                id: "other-bricks",
                label: "Other",
                subItems: otherSubItems,
            });
        }
        setSideNavItems(tableOfContentItems);
    }, [accountBlocks, accountBrickSetsWithBricks]);
    const [selectedAccountBlockId, setSelectedAccountBlockId] = useState(null);
    if (!accountBrickSetsWithBricks) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: "flex flex-col items-center bg-wds-gray-1 border border-r-0 border-wds-gray-3 rounded-l-2xl shrink-0 w-1/5 h-full overflow-y-auto" }, { children: _jsx("div", Object.assign({ className: "flex flex-col p-6 gap-5 items-start justify-start max-w-full overflow-y-auto" }, { children: sideNavItems.map((sideNavItem) => {
                return (_jsx(AccountResearchBrickSet, { sideNavItem: sideNavItem, selectedAccountBlockId: selectedAccountBlockId, scrollToBlock: (blockId) => {
                        setSelectedAccountBlockId(blockId);
                        scrollToBlock(blockId);
                    } }, sideNavItem.id));
            }) })) })));
};
/** Renders each brick set in the table of contents of the account research page.
 * This component does not use the Tab component because it has slightly different
 * behavior than the Tab component. Specifically, the account research page does not
 * have a tab bar, and clicking on a brick in the table of contents only scrolls to
 * the brick.
 *
 * @param brickSet: The brick set to render
 * @param selectedBrickId: Optional. The id of the brick that is currently selected.
 */
const AccountResearchBrickSet = ({ sideNavItem, selectedAccountBlockId, scrollToBlock, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-2.5 max-w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-center items-center gap-2 self-stretch max-w-full" }, { children: [_jsx(BriefcaseIcon, { className: "w-3 h-3 shrink-0" }), _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("span", Object.assign({ className: "grow text-base font-medium truncate" }, { children: sideNavItem.label })) })), _jsx(TooltipContent, { children: _jsx("span", Object.assign({ className: "text-xs" }, { children: sideNavItem.label })) })] }) })] })), _jsx("div", Object.assign({ className: "flex flex-col gap-1.5 justify-center max-w-full items-start" }, { children: sideNavItem.subItems.map((subItem) => {
                    const isSelected = subItem.accountBlockId === selectedAccountBlockId;
                    return (_jsx(Button, Object.assign({ variant: "ghost", className: classNames("self-stretch justify-start", isSelected ? "pl-3 px-0 hover:bg-transparent" : "px-6 py-1.5"), onClick: () => scrollToBlock(subItem.accountBlockId) }, { children: _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("span", Object.assign({ className: classNames("text-sm truncate", isSelected
                                                ? "px-3 py-1.5 font-medium bg-wds-blue-1 text-wds-blue-3 rounded-lg"
                                                : "text-wds-gray-6") }, { children: subItem.label })) })), _jsx(TooltipContent, { children: _jsx("span", Object.assign({ className: "text-xs" }, { children: subItem.label })) })] }) }) }), `${sideNavItem.id}-${subItem.id}`));
                }) }))] })));
};
