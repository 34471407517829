import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import CallParticipant from "./call_partcipant";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "../components/button";
import { UserContext } from "../App";
import { getLiveCallWithDebugInfo } from "../common/endpoints";
const CallDetailsPanel = ({ call, fetchUpdatedCalls }) => {
    var _a;
    const { userIsStaff, userIsTenantAdmin } = useContext(UserContext);
    const [callWithDebugInfo, setCallWithDebugInfo] = useState(null);
    useEffect(() => {
        if (userIsStaff) {
            getLiveCallWithDebugInfo(call.id).then((response) => {
                setCallWithDebugInfo(response.call);
            });
        }
    }, []);
    function GCalIcn() {
        return (_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/GCal.png", className: "w-4 h-4" }));
    }
    const onGCalIconClick = () => {
        var _a;
        window.open((_a = call.calendar_event) === null || _a === void 0 ? void 0 : _a.htmlLink, "_blank");
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-start items-start gap-6 px-8" }, { children: [_jsxs(Button, Object.assign({ variant: "outline", size: "lg", onClick: onGCalIconClick, className: "font-bold font-['Lato'] text-[15px]", style: { lineHeight: "22px" } }, { children: [_jsx("div", Object.assign({ className: "mr-2" }, { children: "Open in Google Calendar" })), _jsx(GCalIcn, {})] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-start items-start w-full gap-1" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 font-lato leading-5 text-[14px] tracking-[-0.15px]", style: { fontWeight: 500 } }, { children: "Description" })), call.description ? (_jsx("div", { className: "text-wds-gray-6 font-normal leading-snug w-full h-full whitespace-pre-wrap", style: { lineHeight: "22px" }, dangerouslySetInnerHTML: {
                            __html: DOMPurify.sanitize(call.description),
                        } })) : (_jsxs("div", Object.assign({ className: "flex items-center justify-start text-wds-gray-4 font-normal leading-snug w-full text-[15px] gap-1", style: { lineHeight: "22px" } }, { children: [_jsx(InformationCircleIcon, { className: "h-5 w-5 text-wds-gray-4", "aria-hidden": "true" }), _jsx("div", { children: "No description found" })] })))] })), _jsxs("div", Object.assign({ className: "grow flex flex-col flex-wrap justify-start items-start gap-1 w-full overflow-y-auto" }, { children: [_jsx("div", Object.assign({ className: "grow flex-shrink-0 text-wds-gray-5 font-lato leading-5 text-[14px] tracking-[-0.15px]" }, { children: "Attendees" })), _jsx("div", Object.assign({ className: "flex flex-wrap justify-start items-start gap-1" }, { children: call.participants.length > 0 ? (call.participants.map((participant) => (_jsx(CallParticipant, { participant: participant, fetchUpdatedCalls: fetchUpdatedCalls }, participant.id)))) : (_jsxs("div", Object.assign({ className: "flex items-center justify-start text-wds-gray-4 font-normal leading-snug w-full text-[15px] gap-1", style: { lineHeight: "22px" } }, { children: [_jsx(InformationCircleIcon, { className: "h-5 w-5 text-wds-gray-4", "aria-hidden": "true" }), _jsx("div", { children: "No Attendees found" })] }))) }))] })), _jsxs("div", Object.assign({ className: "self-stretch h-[79px] flex-col justify-start items-start gap-1 flex overflow-y-auto" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 font-lato leading-5 text-[14px] tracking-[-0.15px]", style: { fontWeight: 500 } }, { children: "Attachments" })), ((_a = call.calendar_event) === null || _a === void 0 ? void 0 : _a.attachments) &&
                        call.calendar_event.attachments.length > 0 ? (call.calendar_event.attachments.map((attachment) => (_jsx("a", Object.assign({ href: attachment.fileUrl, target: "_blank", rel: "noopener noreferrer", className: "self-stretch justify-start items-start gap-2 inline-flex" }, { children: _jsxs("div", Object.assign({ className: "flex px-4 py-2 items-center space-x-2 rounded-full bg-gray-200 gap-2" }, { children: [_jsx("img", { className: "h-4 w-4", src: attachment.iconLink, alt: "" }), _jsx("div", Object.assign({ className: "overflow-hidden text-wds-black truncate font-lato text-base font-bold", style: { lineHeight: "22px" } }, { children: attachment.title }))] })) }), attachment.fileId)))) : (_jsxs("div", Object.assign({ className: "flex items-center justify-start text-wds-gray-4 font-normal leading-snug w-full text-[15px] gap-1", style: { lineHeight: "22px" } }, { children: [_jsx(InformationCircleIcon, { className: "h-5 w-5 text-wds-gray-4", "aria-hidden": "true" }), _jsx("div", { children: "No attachments found." })] })))] })), userIsStaff ? (_jsx("pre", Object.assign({ className: "text-wds-gray-4" }, { children: JSON.stringify(callWithDebugInfo, null, 2) }))) : (_jsx("div", {}))] })));
};
export default CallDetailsPanel;
