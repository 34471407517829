import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import FeedAssist from "../live_call/feed_assist";
import { getChatHistory } from "../common/endpoints";
import useChatMessages from "../live_cards_chat_bot/use_chat_messages";
import { SparklesIcon, } from "@heroicons/react/24/outline";
import { useStreamSocket, } from "../common/stream_socket";
import { KNOWLEDGE_BASE_CHAT_SOURCE } from "../live_cards_chat_bot/chat_source_menu";
import { useLlmOutputFeedback } from "../llm_output_feedback/use_llm_output_feedback";
export const ChatModal = ({ call, defaultChatSource }) => {
    const emptyCards = [];
    const clientId = useMemo(() => Math.floor(Math.random() * 1000000), []);
    const { chatMessages, addChatMessage, resetChatMessages, handlePlaybookRecommendations, handleChatMessage, handleEchoChat, loadChatHistory, handleChatProgress, } = useChatMessages();
    const [collapsed, setCollapse] = useState(true);
    const streamSocket = useStreamSocket();
    const [socketReady, setSocketReady] = useState(false);
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ callId: call.id });
    useEffect(() => {
        setSocketReady(false);
        resetChatMessages();
        getChatHistory(call.id).then((chatHistory) => loadChatHistory(chatHistory, clientId));
        streamSocket.addListener("chat_message", handleChatMessage);
        streamSocket.addListener("echo_back_chat", (message) => handleEchoChat(message, clientId));
        streamSocket.addListener("playbook_recommendations", handlePlaybookRecommendations);
        streamSocket.addListener("chat_progress", handleChatProgress);
        // We just need this to tell that the stream is started; every session
        // begins by sending a note or a placeholder.
        streamSocket.addListener("manual_note", (message) => { });
        streamSocket.addListener("display_notes", (message) => { });
        streamSocket.setOpenCallback(() => {
            setSocketReady(true);
        });
        streamSocket.setCloseCallback(() => {
            setSocketReady(false);
        });
        streamSocket.connectToStreamSocket(call.id);
        return () => {
            streamSocket.close();
        };
    }, [call]);
    return (_jsxs("div", Object.assign({ className: "fixed z-50 right-12 bottom-12" }, { children: [_jsx("button", Object.assign({ className: "flex h-12 w-12 rounded-full items-center justify-center", style: {
                    backgroundImage: `linear-gradient(to top right, #0171F5, #C026D3)`,
                }, onClick: () => setCollapse(!collapsed) }, { children: _jsx(SparklesIcon, { className: "flex w-5 h-5 text-white" }) })), !collapsed && (_jsx("div", Object.assign({ className: "absolute right-0 bottom-14 w-[65vw] md:w-[30vw] h-[80vh] bg-white rounded-lg border-0 p-px", style: {
                    backgroundImage: `linear-gradient(to top right, #0171F5, #C026D3)`,
                } }, { children: _jsx(FeedAssist, { allCards: emptyCards, callId: call.id, clientId: clientId, chatMessages: chatMessages, addChatMessage: addChatMessage, collapsed: collapsed, setCollapse: setCollapse, defaultChatSource: defaultChatSource !== null && defaultChatSource !== void 0 ? defaultChatSource : KNOWLEDGE_BASE_CHAT_SOURCE, socketReady: socketReady, allLlmOutputFeedback: allLlmOutputFeedback !== null && allLlmOutputFeedback !== void 0 ? allLlmOutputFeedback : [], updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) })))] })));
};
