import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { ScrollArea } from "../components/scroll_area";
const ProvenancePopover = (props) => {
    const [open, setOpen] = useState(false);
    const triggerRef = useRef(null);
    useEffect(() => {
        // A simple hook to close the popover when the trigger is not in view
        const observer = new IntersectionObserver(([entry]) => {
            if (!entry.isIntersecting && open) {
                setOpen(false);
            }
        });
        if (triggerRef.current) {
            observer.observe(triggerRef.current);
        }
        return () => {
            if (triggerRef.current) {
                observer.unobserve(triggerRef.current);
            }
        };
    }, [open]);
    return (_jsxs(Popover, Object.assign({ open: open, onOpenChange: setOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ ref: triggerRef, disabled: props.disabled }, { children: props.button })), _jsx(PopoverContent, Object.assign({ className: "border-0 p-0 transition-none", 
                // This ensures that the popover does not collide with the screen boundaries
                collisionPadding: { top: 20, right: 20, bottom: 20, left: 20 }, onCloseAutoFocus: (e) => {
                    e.preventDefault();
                } }, { children: _jsx(ScrollArea, { children: props.panel }) }))] })));
};
export default ProvenancePopover;
