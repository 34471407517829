import { getEpochTime } from "../time_helpers";
import { CallFilter } from "../types";
import { WiserBackendQueryOperator } from "../common/query_builder/types";
const get_query_engine_operator_for_data_table_operator = (operator) => {
    /*
    comparisonOperators: [
      { label: "Contains", value: "ilike" as const },
      { label: "Does not contain", value: "notIlike" as const },
      { label: "Is", value: "eq" as const },
      { label: "Is not", value: "notEq" as const },
      { label: "Starts with", value: "startsWith" as const },
      { label: "Ends with", value: "endsWith" as const },
      { label: "Is empty", value: "isNull" as const },
      { label: "Is not empty", value: "isNotNull" as const },
    ],
    selectableOperators: [
      { label: "Is", value: "eq" as const },
      { label: "Is not", value: "notEq" as const },
      { label: "Is empty", value: "isNull" as const },
      { label: "Is not empty", value: "isNotNull" as const },
    ],
    */
    switch (operator) {
        case "ilike":
            return "LIKE";
        case "notIlike":
            return "NOT_LIKE";
        case "eq":
            return "EQUALS";
        case "notEq":
            return "NOT_EQUALS";
        case "startsWith":
            return "STARTS_WITH";
        case "endsWith":
            return "ENDS_WITH";
        case "isNull":
            return "IS_NULL";
        case "isNotNull":
            return "IS_NOT_NULL";
        default:
            return "LIKE";
    }
};
const get_call_time_filter = (searchParams, location) => {
    let call_time_from = searchParams.get("from");
    let call_time_to = searchParams.get("to");
    const is_default_state = (location.pathname.includes("home") && !searchParams.has("filter")) ||
        searchParams.get("filter") === CallFilter.DATE;
    if ((!call_time_from || !call_time_to) && !is_default_state) {
        return null;
    }
    // en-CA is the format YYYY-MM-DD -- this is used to get the current date
    // in the local time zone if the user has not selected a date.
    call_time_to =
        call_time_to || new Intl.DateTimeFormat("en-CA").format(new Date());
    call_time_from =
        call_time_from || new Intl.DateTimeFormat("en-CA").format(new Date());
    const endTime = new Date(`${call_time_to}T00:00:00`);
    endTime.setHours(23, 59, 59, 999);
    const startTime = new Date(`${call_time_from}T00:00:00`);
    startTime.setHours(0, 0, 0, 0);
    return {
        where_condition: {
            operator: "AND",
            subconditions: [
                {
                    where_condition: {
                        field: "call_time",
                        operator: "LTE",
                        value: getEpochTime(endTime),
                    },
                },
                {
                    where_condition: {
                        field: "call_time",
                        operator: "GTE",
                        value: getEpochTime(startTime),
                    },
                },
            ],
        },
    };
};
const get_default_order_by_field = (searchParams, location) => {
    if (!location.pathname.includes("home") ||
        searchParams.get("filter") === CallFilter.OWNER) {
        return "call_time.desc";
    }
    return "call_time.asc";
};
const get_order_by_field = (searchParams, location) => {
    const order_by_field_with_direction = searchParams.get("sort") ||
        get_default_order_by_field(searchParams, location);
    let [order_by, order_type] = order_by_field_with_direction.split(".");
    if (order_type === "desc") {
        order_by = `-${order_by}`;
    }
    return order_by;
};
const get_accounts_filter = (searchParams) => {
    const accounts_filter = searchParams.get("accounts");
    const account_ids = [];
    if (accounts_filter) {
        accounts_filter.split("$").map((account_with_value) => {
            const [account_name, account_id] = account_with_value.split("~");
            account_ids.push(parseInt(account_id));
        });
    }
    if (account_ids.length === 0) {
        return null;
    }
    return {
        where_condition: {
            field: "account_id",
            operator: "IN",
            value: account_ids,
        },
    };
};
const get_users_filter = (searchParams, location) => {
    const users_filter = searchParams.get("users");
    const user_ids = [];
    if (users_filter) {
        users_filter.split("$").map((user_with_value) => {
            const [user_name, user_id] = user_with_value.split("~");
            user_ids.push(parseInt(user_id));
        });
    }
    if (((location.pathname.includes("home") && !searchParams.has("filter")) ||
        searchParams.get("filter") === CallFilter.DATE) &&
        user_ids.length === 0) {
        user_ids.push("REPLACE_CURRENT_USER_ID");
    }
    if (user_ids.length === 0) {
        return null;
    }
    return {
        where_condition: {
            field: "owner_id",
            operator: "IN",
            value: user_ids,
        },
    };
};
const get_contacts_filter = (searchParams) => {
    const contacts_filter = searchParams.get("contacts");
    const contact_ids = [];
    if (contacts_filter) {
        contacts_filter.split("$").map((contact_with_value) => {
            const [contact_name, contact_id] = contact_with_value.split("~");
            contact_ids.push(parseInt(contact_id));
        });
    }
    if (contact_ids.length === 0) {
        return null;
    }
    return {
        where_condition: {
            field: "id",
            operator: "IN",
            subquery: {
                table: "Participants",
                select_field: "call_id",
                structured_filter: {
                    where_condition: {
                        field: "contact_id",
                        operator: "IN",
                        value: contact_ids,
                    },
                },
            },
        },
    };
};
const get_wiser_joined_filter = (searchParams) => {
    // Using advanced filters adds ~eq to the end of the filter
    const wiser_joined_filter = (searchParams.get("wiser_joined") || "").replace("~eq", "");
    if (wiser_joined_filter.includes(".") || wiser_joined_filter === "") {
        return null;
    }
    return {
        where_condition: {
            field: "wiser_joined",
            operator: "EQUALS",
            value: wiser_joined_filter == "true",
        },
    };
};
const get_call_type_filter = (searchParams) => {
    // Using advanced filters adds ~eq to the end of the filter
    const call_type_filter = (searchParams.get("call_type") || "").replace("~eq", "");
    if (!call_type_filter) {
        return null;
    }
    return {
        where_condition: {
            field: "call_type",
            operator: "IN",
            value: call_type_filter.split("."),
        },
    };
};
const get_call_name_filter = (searchParams) => {
    // Do not search by name if transcript search or tracker search is True
    const call_name_filters = searchParams.get("name");
    if (!call_name_filters) {
        return null;
    }
    const [call_name_filter, call_name_operator] = call_name_filters.split("~");
    const q_engine_operator = get_query_engine_operator_for_data_table_operator(call_name_operator);
    if (!call_name_filter) {
        return null;
    }
    return {
        where_condition: {
            field: "name",
            operator: q_engine_operator,
            value: call_name_filter,
        },
    };
};
const get_tracker_filter = (searchParams) => {
    const tracker_filters = searchParams.get("playbook_items");
    if (!tracker_filters) {
        return null;
    }
    const [tracker_filter, tracker_filter_operator] = tracker_filters.split("~");
    if (!tracker_filter) {
        return null;
    }
    const q_engine_operator = get_query_engine_operator_for_data_table_operator(tracker_filter_operator);
    return {
        where_condition: {
            field: "id",
            operator: "IN",
            subquery: {
                table: "PlaybookItemNotes",
                select_field: "live_call_id",
                structured_filter: {
                    where_condition: {
                        field: "content",
                        operator: q_engine_operator,
                        value: tracker_filter,
                    },
                },
            },
        },
    };
};
export const convertNewSearchParamsIntoFilterQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    copySearchParams.delete("page");
    copySearchParams.delete("per_page");
    let filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    let order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    let json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "meeting_host",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
export const convertSearchParamsIntoFilterQuery = (searchParams, location) => {
    var _a;
    const order_by = get_order_by_field(searchParams, location);
    const accounts_filter = get_accounts_filter(searchParams);
    const users_filter = get_users_filter(searchParams, location);
    const contacts_filter = get_contacts_filter(searchParams);
    const wiser_joined_filter = get_wiser_joined_filter(searchParams);
    const call_type_filter = get_call_type_filter(searchParams);
    const call_name_filter = get_call_name_filter(searchParams);
    const call_time_filter = get_call_time_filter(searchParams, location);
    const tracker_filter = get_tracker_filter(searchParams);
    let structured_filter = {
        where_condition: {
            operator: "AND",
            subconditions: [],
        },
    };
    let sub_filter = {
        where_condition: {
            operator: "OR",
            subconditions: [],
        },
    };
    if (accounts_filter) {
        if (searchParams.has("filter") &&
            searchParams.get("filter") !== CallFilter.ACCOUNT &&
            searchParams.has("matchOption")) {
            sub_filter.where_condition.subconditions.push(accounts_filter);
        }
        else {
            structured_filter.where_condition.subconditions.push(accounts_filter);
        }
    }
    if (users_filter) {
        if (searchParams.has("filter") &&
            searchParams.get("filter") !== CallFilter.OWNER &&
            searchParams.has("matchOption")) {
            sub_filter.where_condition.subconditions.push(users_filter);
        }
        else {
            structured_filter.where_condition.subconditions.push(users_filter);
        }
    }
    if (contacts_filter) {
        if (searchParams.has("filter") &&
            searchParams.get("filter") !== CallFilter.CONTACT &&
            searchParams.has("matchOption")) {
            sub_filter.where_condition.subconditions.push(contacts_filter);
        }
        else {
            structured_filter.where_condition.subconditions.push(contacts_filter);
        }
    }
    if (wiser_joined_filter) {
        structured_filter.where_condition.subconditions.push(wiser_joined_filter);
    }
    if (call_type_filter) {
        structured_filter.where_condition.subconditions.push(call_type_filter);
    }
    if (call_name_filter) {
        structured_filter.where_condition.subconditions.push(call_name_filter);
    }
    if (call_time_filter) {
        structured_filter.where_condition.subconditions.push(call_time_filter);
    }
    if (tracker_filter) {
        structured_filter.where_condition.subconditions.push(tracker_filter);
    }
    if (sub_filter.where_condition.subconditions.length > 0) {
        structured_filter.where_condition.subconditions.push(sub_filter);
    }
    if (structured_filter.where_condition.subconditions.length === 0) {
        structured_filter = {};
    }
    let json_query = {
        table: "LiveCall",
        order_by_fields: [order_by],
        structured_filter,
    };
    // Transcript search is a "OR" search right now if no transcript matches
    // But transcript search is a "AND" search if any transcript matches
    // This weirdness will cause confusion and needs to be fixed in the BE to always be AND
    if (searchParams.get("transcripts")) {
        const [transcript_filter, transcript_filter_operator] = ((_a = searchParams.get("transcripts")) === null || _a === void 0 ? void 0 : _a.split("~")) || [];
        if (transcript_filter) {
            json_query.unstructured_filters = [
                {
                    table: "TranscriptChunk",
                    value: transcript_filter,
                },
            ];
        }
    }
    return JSON.stringify(json_query);
};
