var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CrmTableV2 } from "./crm_table_v2";
import { useMemo, useRef, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "../components/checkbox";
import CrmFieldValue from "./crm_field_value";
import { createCrmContact, createOrUpdateCrmContactData, getAccountData, getActiveTenant, getContactData, getCrmContact, queryCrmOwners, updateCrmContact, } from "../common/endpoints";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
import { useCrmFields } from "./use_crm_fields";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { ScrollArea } from "../components/scroll_area";
import { useCustomSearchParams } from "./use_memoised_search_params";
import useTableChatMosaic from "../live_cards_chat_bot/use_table_chat_mosaic";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { ChatWithTable } from "../live_cards_chat_bot/chat_with_table";
import { getCrmChatSource } from "../live_cards_chat_bot/chat_source_menu";
import { Mosaic } from "react-mosaic-component";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { getAccountLabel } from "../common/account_popover/account_popover_button";
const DEFAULT_CONTACTS_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_CONTACTS_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Contact",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "",
    columns: {
        "row-select": 35,
        name: 150,
        email: 150,
        linkedin_profile_url: 150,
        account: 200,
        last_call_time: 250,
        last_call_link: 150,
        last_call_summary: 400,
    },
    column_order: [],
};
export const CrmContactsV2 = () => {
    var _a, _b;
    const { searchParams, layouts, isLoading, isError } = useCustomSearchParams("Contact", DEFAULT_TABLE_LAYOUT);
    const filter = searchParams.has("filter")
        ? ((_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_CONTACTS_FILTER)
        : DEFAULT_CONTACTS_FILTER;
    const orderBy = searchParams.has("order_by")
        ? ((_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_CONTACTS_ORDER_BY)
        : DEFAULT_CONTACTS_ORDER_BY;
    // State to store the CRM contacts keyed by their ID.
    const [crmContactsMap, setCrmContactsMap] = useState(new Map());
    const searchQuery = useRef("");
    const setSearchQuery = (query) => {
        searchQuery.current = query;
        queryClient.invalidateQueries({ queryKey: ["getContactData"] });
    };
    const [visibleColumns, setVisibleColumns] = useState();
    const filterQuery = JSON.stringify({
        table: "Contact",
        structured_filter: {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    filter,
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "email",
                                        value: searchQuery.current,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: searchQuery.current,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        order_by_v2: orderBy,
    });
    const { data, fetchNextPage, isFetching, isLoading: isLoadingContacts, } = useInfiniteQuery(["getContactData", filterQuery], ({ pageParam = 0 }) => getContactData(filterQuery, pageParam + 1, 50), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        getNextPageParam: (_lastGroup, groups) => groups.length,
        onSettled: (data) => {
            if (!data)
                return;
            setCrmContactsMap(new Map(data.pages
                .flatMap((page) => page.data)
                .map((contact) => [contact.id, contact])));
        },
    });
    const contactsPaginatedData = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const total = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0 ? data === null || data === void 0 ? void 0 : data.pages[0].total : 0; }, [data]);
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        const columns = [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "flex items-center justify-center pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
                enableHiding: false,
                size: 35,
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 100 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.name = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                enableHiding: false,
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 100 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.email) !== null && _a !== void 0 ? _a : ""; }, {
                id: "email",
                header: "Email",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 60 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ newValue, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ undefined);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.email = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -2,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 60 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.linkedin_profile_url) !== null && _a !== void 0 ? _a : ""; }, {
                id: "linkedin_profile_url",
                header: "LinkedIn URL",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 500 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        yield updateCrmContact(parseInt(props.row.id), 
                        /* name= */ undefined, 
                        /* email= */ undefined, 
                        /* accountId= */ undefined, 
                        /* linkedinProfileUrl= */ newValue);
                        setCrmContactsMap((prevMap) => {
                            const updatedContact = prevMap.get(parseInt(props.row.id));
                            if (updatedContact) {
                                updatedContact.linkedin_profile_url = newValue;
                                return new Map([
                                    ...prevMap,
                                    [parseInt(props.row.id), updatedContact],
                                ]);
                            }
                            return prevMap;
                        });
                    }) })),
                meta: {
                    fieldId: -3,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 500 },
                    },
                    required: true,
                    columnInfo: {
                        columnType: "CUSTOM",
                    },
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.account_name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "account",
                header: "Account",
                cell: (props) => {
                    var _a;
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "account",
                            objectsFetcher: (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
                        }, selectedOption: props.row.original.account_id
                            ? {
                                label: (_a = props.row.original.account_name) !== null && _a !== void 0 ? _a : "",
                                value: `${props.row.original.account_id}`,
                            }
                            : undefined, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const updatedContact = yield updateCrmContact(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* accountId= */ parseInt(option.value), 
                            /* linkedinProfileUrl= */ undefined);
                            if (updatedContact.type === "error") {
                                // TODO: Surface the error message in the UI.
                                return;
                            }
                            setCrmContactsMap((prevMap) => {
                                const existingContact = prevMap.get(parseInt(props.row.id));
                                const updatedMap = new Map(prevMap);
                                updatedMap.set(updatedContact.id, Object.assign(Object.assign({}, existingContact), { account_id: updatedContact.account_id, account_name: updatedContact.account_name }));
                                return updatedMap;
                            });
                        }) }));
                },
            }),
            columnHelper.accessor("last_call_time", {
                id: "last_call_time",
                header: "Last call time",
                cell: (props) => props.getValue() ? new Date(props.getValue()).toLocaleString() : null,
            }),
            columnHelper.accessor("last_call_link", {
                id: "last_call_link",
                header: "Last call link",
                cell: (props) => (_jsx("span", Object.assign({ className: "link-styles" }, { children: props.getValue() ? (_jsx("a", Object.assign({ href: props.getValue() }, { children: "Link to call" }))) : null }))),
            }),
            columnHelper.accessor("last_call_summary", {
                id: "last_call_summary",
                header: "Last call summary",
                cell: (props) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: props.getValue() })) })) })] }) })),
            }),
            columnHelper.accessor("owner_id", {
                id: "owner_id",
                header: "Owner",
                cell: (props) => {
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "crm_owner",
                            objectsFetcher: (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                const { results } = yield queryCrmOwners(userQuery, 
                                /* ids= */ undefined, cursor);
                                return {
                                    results: results.map((owner) => ({
                                        value: owner.id.toString(),
                                        label: owner.name || owner.email,
                                    })),
                                };
                            }),
                        }, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const ownerId = parseInt(option.value, 10);
                            const response = yield updateCrmContact(parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* email= */ undefined, 
                            /* accountId= */ undefined, 
                            /* linkedinProfileUrl= */ undefined, 
                            /* roleName= */ undefined, 
                            /* ownerId= */ ownerId);
                            if (response.type === "error") {
                                return;
                            }
                            setCrmContactsMap((prevCrmContactsMap) => {
                                const updatedCrmContact = prevCrmContactsMap.get(parseInt(props.row.id));
                                if (updatedCrmContact) {
                                    updatedCrmContact.owner_id = response.owner_id;
                                    updatedCrmContact.owner_name = response.owner_name;
                                    updatedCrmContact.owner_email = response.owner_email;
                                    return new Map([
                                        ...prevCrmContactsMap,
                                        [parseInt(props.row.id), updatedCrmContact],
                                    ]);
                                }
                                return prevCrmContactsMap;
                            });
                        }), selectedOption: props.row.original.owner_id
                            ? {
                                value: props.row.original.owner_id.toString(),
                                label: props.row.original.owner_name ||
                                    props.row.original.owner_email ||
                                    "",
                            }
                            : undefined }));
                },
            }),
        ];
        return columns;
    }, []);
    const queryClient = useQueryClient();
    const accountsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => {
                const filterConditions = [
                    {
                        where_condition: {
                            field: "name",
                            operator: WiserBackendQueryOperator.LIKE,
                            value: userQuery,
                        },
                    },
                ];
                if (ids) {
                    filterConditions.push({
                        where_condition: {
                            field: "id",
                            operator: WiserBackendQueryOperator.IN,
                            value: ids,
                        },
                    });
                }
                return getAccountData(JSON.stringify({
                    table: "Account",
                    order_by_fields: ["name"],
                    structured_filter: {
                        where_condition: {
                            operator: WiserBackendQueryOperator.AND,
                            subconditions: filterConditions,
                        },
                    },
                }), 1, 200);
            },
            queryKey: ["getAccounts", cursor, userQuery, ids],
        });
        return {
            results: accountsQueryResponse.data.map((account) => {
                return {
                    label: getAccountLabel(undefined, Object.assign(Object.assign({}, account), { account_name: "" })),
                    value: account.id.toString(),
                };
            }),
        };
    });
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            [
                "owner_id",
                (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                    const { results } = yield queryCrmOwners(userQuery, ids, cursor);
                    const ownerOptions = results.map((owner) => ({
                        value: owner.id.toString(),
                        label: owner.name || owner.email,
                    }));
                    // We fetch by ids to restore the filter values in the UI after the user
                    // saves the filter. Essentially if the user applied the filter:
                    // Owner in [a, b, c] and saves, the only values in the query that get
                    // saved are the ids of a, b and c. And hence to render the name (labels)
                    // of those ids in the UI when the user revisits the page, we first fetch
                    // the Users by ids using this API.
                    // For self, we only need to add it in the result if the user saved the filter
                    // with -99 (special id for self).
                    if (!ids || ids.includes(-99)) {
                        ownerOptions.unshift({
                            value: "-99",
                            label: "Self",
                        });
                    }
                    return {
                        results: ownerOptions.filter((owner) => owner.label.toLowerCase().includes(userQuery.toLowerCase())),
                    };
                }),
            ],
        ]);
    }, []);
    const filterFields = useMemo(() => {
        const filterFields = [
            {
                id: "name",
                name: "Name",
                type: "string",
            },
            {
                id: "email",
                name: "Email",
                type: "string",
            },
            {
                id: "account_id",
                name: "Account",
                type: "reference",
            },
            {
                id: "owner_id",
                name: "Owner",
                type: "reference",
            },
        ];
        return filterFields;
    }, []);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmContactsMap.values());
    }, [crmContactsMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        const newCrmContact = yield createCrmContact(newRowData.find((rowData) => rowData.columnId === "name").value, newRowData.find((rowData) => rowData.columnId === "email").value, newRowData.find((rowData) => rowData.columnId === "linkedin_profile_url")
            .value, newRowData.find((rowData) => rowData.columnId === "account").value, crmFieldValues);
        if (newCrmContact.type === "error") {
            // TODO: Surface the error message in the UI.
            return;
        }
        const updatedCrmContact = yield getCrmContact(newCrmContact.id);
        setCrmContactsMap((prevMap) => {
            return new Map([...prevMap, [updatedCrmContact.id, updatedCrmContact]]);
        });
    });
    const onCrmFieldValueChange = (contactId, crmField, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        yield createOrUpdateCrmContactData(contactId, crmField.id, newValue);
        setCrmContactsMap((prevMap) => {
            const updatedContact = prevMap.get(contactId);
            if (updatedContact) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedContact.contact_fields) {
                    updatedContact.contact_fields[`z${crmField.id}`] = newValue;
                }
                else {
                    updatedContact.contact_fields = {
                        [`z${crmField.id}`]: newValue,
                    };
                }
                return new Map([...prevMap, [contactId, updatedContact]]);
            }
            return prevMap;
        });
    });
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        refetchOnWindowFocus: false,
    });
    const { crmFields } = useCrmFields();
    const { chatCollapsed, updateChatCollapsed, mosaicState, onResize, onRelease, } = useTableChatMosaic();
    const enableChatWithCrmTable = useFeatureFlagEnabled("CHAT_WITH_CRM_TABLE");
    if (!contactsPaginatedData ||
        isLoadingContacts ||
        !crmFields ||
        isLoading ||
        isError ||
        !layouts ||
        layouts.length === 0) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    const CrmTableElement = (_jsx(CrmTableV2, { crmObject: "Contact", crmFields: crmFields, tableRows: tableData, totalRows: total, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: referenceObjectFetcherMap, columnObjectsFetcher: [
            {
                columnId: "account",
                objectsFetcher: (userQuery, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
            },
        ], defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, onSearchQueryChange: setSearchQuery, searchParams: searchParams, layouts: layouts, fetchNextPage: fetchNextPage, isFetching: isFetching, invalidateTableData: () => {
            queryClient.invalidateQueries({
                queryKey: ["getContactData"],
            });
        }, onVisibleColumnsChange: setVisibleColumns }));
    if (!enableChatWithCrmTable) {
        return CrmTableElement;
    }
    return (_jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => {
            if (id === "table")
                return CrmTableElement;
            if (id === "chat")
                return (_jsx(ChatWithTable, { filterQuery: getConvertedJsonQuery(filterQuery), collapsed: chatCollapsed, setCollapsed: updateChatCollapsed, chatSource: getCrmChatSource("contact", tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm), visibleColumns: visibleColumns }));
            return _jsx(_Fragment, {});
        }, initialValue: mosaicState, onChange: onResize, onRelease: onRelease, value: mosaicState, className: "h-[83vh] my-mosaic" }));
};
