import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import Message from "../../../live_cards_chat_bot/message";
import { useLlmOutputFeedback } from "../../../llm_output_feedback/use_llm_output_feedback";
/**
 * This component renders the chat message responses of the account.
 */
export const AccountSearchResult = ({ account, chatMessages, }) => {
    const { allLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, } = useLlmOutputFeedback({ callId: undefined, accountId: account.id });
    const reversedChatMessages = useMemo(() => {
        return chatMessages.slice().reverse();
    }, [chatMessages]);
    return (_jsx("div", Object.assign({ className: "flex flex-col items-center bg-wds-gray-1 w-full max-h-full overflow-y-auto" }, { children: _jsx("div", Object.assign({ className: "pt-6 flex flex-col items-start gap-2.5 w-full overflow-y-auto" }, { children: reversedChatMessages.map((message, idx) => {
                return (_jsx("div", Object.assign({ className: "bg-white w-full rounded-3xl p-2 slide-in-animation duration-150" }, { children: _jsx(Message, { message: message, userLlmOutputFeedback: message &&
                            message.sender === "Bot" &&
                            message.messageType === "Card" &&
                            message.card.provenanceLogId
                            ? allLlmOutputFeedback === null || allLlmOutputFeedback === void 0 ? void 0 : allLlmOutputFeedback.find((f) => f.provenance_log_id === message.card.provenanceLogId)
                            : undefined, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }) }), chatMessages.length - idx));
            }) })) })));
};
