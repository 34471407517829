import { useState } from "react";
import { getAndAssertNodeAtPathExists, } from "react-mosaic-component";
const useTableChatMosaic = () => {
    const [mosaicState, setMosaicState] = useState({
        direction: "row",
        first: "table",
        second: "chat",
        splitPercentage: 97,
    });
    const [chatCollapsed, setChatCollapsed] = useState(true);
    const onResize = (newNode) => {
        if (!newNode)
            return;
        const shouldCollapse = chatShouldSnapClosed(newNode);
        if (shouldCollapse && !chatCollapsed) {
            setChatCollapsed(true);
        }
        else if (!shouldCollapse && chatCollapsed) {
            setChatCollapsed(false);
        }
        setMosaicState(newNode);
    };
    const onRelease = (newNode) => {
        if (newNode && chatShouldSnapClosed(newNode)) {
            setMosaicState(Object.assign(Object.assign({}, newNode), { splitPercentage: 97 }));
        }
    };
    const chatShouldSnapClosed = (currentNode) => {
        const pathToChatSplit = [];
        const chatSplit = getAndAssertNodeAtPathExists(currentNode, pathToChatSplit);
        const chatSplitPercentage = chatSplit.splitPercentage;
        if (!chatSplitPercentage)
            return false;
        return ((100 - chatSplitPercentage) / 100.0) * window.innerWidth < 400;
    };
    const updateChatCollapsed = (collapsed) => {
        setMosaicState(Object.assign(Object.assign({}, mosaicState), { splitPercentage: collapsed ? 97 : 70 }));
        setChatCollapsed(collapsed);
    };
    return {
        chatCollapsed,
        mosaicState,
        updateChatCollapsed,
        onResize,
        onRelease,
    };
};
export default useTableChatMosaic;
