/**
 * Fetches the name of the account from the given {@link account} object.
 * @param account The account object
 * @param defaultName Optional. If no account name is present, this default string would be returned
 * @returns
 */
export const getAccountName = (account, defaultName) => {
    if (account.account_name && account.account_name.length > 0)
        return account.account_name;
    if (account.name && account.name.length > 0)
        return account.name;
    return defaultName;
};
/**
 * Fetches the account name of the linked account for the given {@link call}.
 * @param call The call object.
 * @param defaultName Optional. If no account is present, this default string would be returned
 * @returns
 */
export const getAccountNameForCall = (call, defaultName) => {
    if (call.account)
        return getAccountName(call.account, call.account.website && call.account.website.length > 0
            ? call.account.website
            : call.account.linkedin_profile_url);
    return defaultName;
};
/**
 * Fetches the name of the account from the given {@link account} object.
 * @param account The account object
 * @param defaultString Optional. If no linkedin URL is present, this default string would be returned
 * @returns
 */
export const getAccountLinkedInUrl = (account, defaultString) => {
    if (account.linkedin_profile_url && account.linkedin_profile_url.length > 0)
        return account.linkedin_profile_url;
    return defaultString;
};
/**
 * Returns the description of the CRM account by parsing stored apollo and CRM data
 * @param crmAccount The CRM account object
 */
export const getCrmAccountDescription = (crmAccount) => {
    if (crmAccount.description && crmAccount.description.length > 0) {
        return crmAccount.description;
    }
};
