import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { AccountInfoAndChatBox } from "./account_info_and_chat_box";
import { AccountWikiDashboard } from "./account_wiki_dashboard";
export const AccountResearchDrawerBody = ({ account, accountBlocks, scrollToBlock, blocksRefCallback, accountBrickSetsWithBricks, allBricks, setHighlightedBlockIds, }) => {
    const [chatMessages, setChatMessages] = useState([]);
    const chatMessagesToRenderInSearchResults = useMemo(() => {
        // We drop the first message, which is the bot's initial message in the chat box.
        return chatMessages.slice(1, /* end= */ undefined);
    }, [chatMessages]);
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2.5 w-full overflow-y-auto" }, { children: [_jsx(AccountInfoAndChatBox, { account: account, setChatMessages: setChatMessages }), _jsx(AccountWikiDashboard, { account: account, accountBlocks: accountBlocks, scrollToBlock: scrollToBlock, blocksRefCallback: blocksRefCallback, accountBrickSetsWithBricks: accountBrickSetsWithBricks, allBricks: allBricks, setHighlightedBlockIds: setHighlightedBlockIds, chatMessages: chatMessagesToRenderInSearchResults })] })));
};
