var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "../components/button";
import { ArrowDownTrayIcon, ArrowPathIcon, ArrowUpTrayIcon, } from "@heroicons/react/24/outline";
import { useState } from "react";
import { downloadAllUsers, importUsers } from "./endpoints";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, } from "../components/dialog";
import { Input } from "../components/input";
import { useToast } from "../components/use_toast";
export function UsersExport({ filterQuery }) {
    const { toast } = useToast();
    const [exporting, setExporting] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const uploadFile = () => __awaiter(this, void 0, void 0, function* () {
        setUploading(true);
        const response = yield importUsers(selectedFile);
        if (response.errors.length === 0) {
            toast({
                title: "Users imported",
                description: "All Users imported successfully",
                variant: "green",
            });
            setImportModalOpen(false);
            window.location.reload();
        }
        else {
            toast({
                title: "Partial import",
                description: `Users imported: ${response.users_created}. Errors: ${response.errors.length}`,
                variant: "destructive",
            });
            console.log(response.errors);
        }
        setUploading(false);
    });
    const handleExportClick = () => __awaiter(this, void 0, void 0, function* () {
        setExporting(true);
        const response = yield downloadAllUsers(filterQuery);
        const nowTime = new Date().getTime();
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `users${nowTime}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        setExporting(false);
    });
    return (_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(Button, Object.assign({ variant: "ghost", size: "sm", disabled: exporting, onClick: () => __awaiter(this, void 0, void 0, function* () { return yield handleExportClick(); }), className: "text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: !exporting ? (_jsx(ArrowDownTrayIcon, { className: "h-4 w-4", "aria-hidden": "true" })) : (_jsx(ArrowPathIcon, { className: "h-4 w-4 animate-spin", "aria-hidden": "true" })) })), _jsx(Button, Object.assign({ variant: "ghost", size: "sm", disabled: exporting, onClick: () => setImportModalOpen(true), className: "text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: !exporting ? (_jsx(ArrowUpTrayIcon, { className: "h-4 w-4", "aria-hidden": "true" })) : (_jsx(ArrowPathIcon, { className: "h-4 w-4 animate-spin", "aria-hidden": "true" })) })), _jsx(Dialog, Object.assign({ open: importModalOpen, onOpenChange: (open) => setImportModalOpen(open) }, { children: _jsxs(DialogContent, { children: [_jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: "Import users from CSV" }), _jsx(DialogDescription, { children: "Please upload CSV in the correct format." })] }), _jsx(Input, { type: "file", name: "users", accept: ".csv", onChange: (e) => {
                                if (e.target.files && e.target.files[0]) {
                                    setSelectedFile(e.target.files[0]);
                                }
                            } }), _jsx(DialogFooter, Object.assign({ className: "sm:justify-end p-4" }, { children: _jsx(DialogClose, Object.assign({ asChild: true }, { children: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", size: "lg", className: "text-wds-gray-6 font-bold", onClick: () => setImportModalOpen(false) }, { children: "Close" })), _jsxs(Button, Object.assign({ type: "submit", size: "lg", disabled: !selectedFile || uploading, onClick: uploadFile }, { children: [uploading ? (_jsx(ArrowPathIcon, { className: "mr-2 h-4 w-4 animate-spin" })) : null, "Import"] }))] }) })) }))] }) }))] })));
}
