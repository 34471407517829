var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { ArrowPathIcon, ArrowRightIcon, ArrowTopRightOnSquareIcon, CheckIcon, VideoCameraIcon, } from "@heroicons/react/24/outline";
import classNames from "../class_names";
import CallTimeDisplay from "./call_time_display";
import AutojoinDisplay from "./auto_join_display";
import useIntersectionObserver from "../common/intesection_observer";
import { Button } from "../components/button";
import { Switch } from "../components/switch";
import AccountPopoverV2 from "../common/account_popover/account_popover_v2";
import OpportunityPopover from "../common/opportunity_popover";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import { Tabs, TabsList, TabsTrigger } from "../components/tabs";
import { getLiveCallsByDate } from "../common/endpoints";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Separator } from "../components/separator";
import { UserContext } from "../App";
import CallPage from "./call_v3";
import { formatSecsToHMS } from "../time_helpers";
import { AddCallDialog } from "./add_call_dialog";
function incrementDate(date, increment) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + increment);
    return newDate;
}
const CallListItemsWideScreenV3 = ({ homePageMetaData, showLoading, syncWithCalendar, tenant, }) => {
    const navigate = useNavigate();
    const { userEmail } = useContext(UserContext);
    const { callIdParam } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const hasCallIdParam = callIdParam !== undefined;
    const callTimeOptions = ["Recently completed", "Up next"];
    const defaultTab = searchParams.get("tab") || "Up next";
    const [selectedCallTimeOption, setSelectedCallTimeOption] = useState(defaultTab);
    const [calls, setCalls] = useState([]);
    const [datesFetched, setDatesFetched] = useState([]);
    const [hideInternalCalls, setHideInternalCalls] = useState(() => {
        const storedValue = localStorage.getItem("hideInternalCalls");
        return storedValue !== null ? JSON.parse(storedValue) : true; // Default to true if no value is stored
    });
    useEffect(() => {
        localStorage.setItem("hideInternalCalls", JSON.stringify(hideInternalCalls));
    }, [hideInternalCalls]);
    // Support for infinite scroll
    const fetchNextDate = () => __awaiter(void 0, void 0, void 0, function* () {
        let nextDate = new Date();
        if (datesFetched.length > 0) {
            nextDate =
                selectedCallTimeOption === "Recently completed"
                    ? incrementDate(datesFetched[0], -1)
                    : incrementDate(datesFetched[datesFetched.length - 1], 1);
        }
        if (nextDate < new Date(homePageMetaData.minLiveCallsDate))
            return;
        if (nextDate > new Date(homePageMetaData.maxLiveCallsDate))
            return;
        const nextDateCalls = yield getLiveCallsByDate(nextDate.toLocaleDateString("en-CA"), 0, true);
        const existingCallIds = calls.map((call) => call.id);
        setCalls([
            ...calls,
            ...nextDateCalls.calls.filter((call) => !existingCallIds.includes(call.id)),
        ].sort((a, b) => new Date(a.call_time).getTime() - new Date(b.call_time).getTime()));
        setDatesFetched([...datesFetched, nextDate].sort((a, b) => a.getTime() - b.getTime()));
    });
    const updateSelectedCall = (call) => {
        if (calls.find((c) => c.id === call.id)) {
            setCalls(calls.map((c) => {
                return c.id === call.id ? call : c;
            }));
        }
        else {
            setCalls([...calls, call].sort((a, b) => new Date(a.call_time).getTime() - new Date(b.call_time).getTime()));
        }
    };
    let filteredCalls = useMemo(() => {
        return calls
            .filter((call) => {
            if (selectedCallTimeOption === "Recently completed") {
                return new Date(call.call_end_time) < new Date();
            }
            else {
                return new Date(call.call_end_time) > new Date();
            }
        })
            .filter((call) => {
            return !hideInternalCalls || call.call_type === "External";
        });
    }, [calls, selectedCallTimeOption, hideInternalCalls]);
    useMemo(() => {
        if (filteredCalls.length === 0) {
            fetchNextDate();
        }
    }, [filteredCalls]);
    if (selectedCallTimeOption === "Recently completed") {
        filteredCalls = filteredCalls.sort((a, b) => new Date(b.call_end_time).getTime() -
            new Date(a.call_end_time).getTime());
    }
    const loadMoreRef = useIntersectionObserver(() => {
        if (datesFetched.length === 0)
            return;
        fetchNextDate();
    }, { threshold: 1.0 });
    const syncAndFetchAgain = () => __awaiter(void 0, void 0, void 0, function* () {
        yield syncWithCalendar();
        setCalls([]);
        setDatesFetched([]);
    });
    return (_jsxs("div", Object.assign({ className: "space-y-4" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "text-2xl font-semibold" }, { children: "Your calls" })), _jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 mx-1 flex hover:bg-blue-100", onClick: syncAndFetchAgain }, { children: _jsx(ArrowPathIcon, { className: "h-5 w-5 text-wds-gray-5" }) })), _jsx(AddCallDialog, {}), _jsxs(Button, Object.assign({ variant: "outline", size: "lg", className: "ml-4 font-bold h-8 bg-wds-gray-1", onClick: () => navigate(`/calls?default=true`) }, { children: ["All calls", _jsx(ArrowTopRightOnSquareIcon, { className: "w-4 h-4 ml-2 text-wds-blue-3 stroke-2" })] }))] })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(Switch, { checked: hideInternalCalls, onCheckedChange: setHideInternalCalls, checkedIcon: _jsx(CheckIcon, { className: "w-4 h-4 p-0.5 text-wds-blue-3" }), uncheckedIcon: _jsx(XMarkIcon, { className: "w-4 h-4 text-wds-gray-1 bg-wds-gray-5 rounded-full" }) }), _jsx("div", Object.assign({ className: classNames(hideInternalCalls && "font-bold") }, { children: "Hide internal calls" }))] }))] })), _jsx(Tabs, Object.assign({ defaultValue: defaultTab, onValueChange: (v) => {
                    setSelectedCallTimeOption(v);
                    setSearchParams({ tab: v });
                } }, { children: _jsx(TabsList, Object.assign({ className: "w-full bg-white h-12" }, { children: callTimeOptions.map((title, idx) => (_jsx(TabsTrigger, Object.assign({ value: title, className: "text-base m-1" }, { children: title }), idx))) })) })), _jsx("div", Object.assign({ className: "w-full space-y-4 mt-1" }, { children: filteredCalls.map((call, idx) => (_jsxs("div", Object.assign({ className: "w-full flex flex-col bg-white rounded-lg px-6 py-5 border border-wds-gray-3 items-center cursor-pointer hover:border-wds-gray-4 hover:bg-wds-gray-2", onClick: (e) => {
                        e.stopPropagation();
                        navigate(`/home/call/${call.id}?${searchParams.toString()}`);
                    } }, { children: [_jsxs("div", Object.assign({ className: "w-full flex flex-row space-x-4 items-center" }, { children: [selectedCallTimeOption === "Recently completed" && (_jsxs("div", Object.assign({ className: "relative flex items-center justify-center w-24 h-16 bg-wds-gray-3 rounded-xl flex-shrink-0" }, { children: [_jsx(VideoCameraIcon, { className: "h-5 w-5 text-wds-gray-5 fill-white stroke-0" }), call.duration_secs && (_jsx("div", Object.assign({ className: "absolute bottom-0 right-0 m-1 bg-wds-blue-6 opacity-75 text-wds-blue-1 text-xs font-bold rounded-sm px-1" }, { children: formatSecsToHMS(call.duration_secs) })))] }))), _jsxs("div", Object.assign({ className: "flex flex-col w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row font-bold text-lg space-x-2" }, { children: [_jsx("div", { children: call.name }), _jsx("div", Object.assign({ onClick: (e) => e.stopPropagation() }, { children: _jsx(AutojoinDisplay, { call: call, selectedCallId: 0, disabled: selectedCallTimeOption === "Recently completed", updateSelectedCall: updateSelectedCall }) })), !showLoading &&
                                                    idx === Math.max(0, filteredCalls.length - 4) && (_jsx("div", { ref: loadMoreRef, style: { height: "1px" } }))] })), _jsx("div", Object.assign({ className: "flex flex-row space-x-1" }, { children: _jsx(CallTimeDisplay, { showDate: true, showTime: true, showCalendarIcon: false, call: call, className: "text-wds-gray-5 text-base px-0" }) })), _jsx("div", Object.assign({ className: "space-x-1" }, { children: call.participants
                                                .filter((contact) => contact.full_name != "" || contact.email != "")
                                                .map((contact, pidx, arr) => {
                                                return pidx < 3
                                                    ? contact
                                                    : {
                                                        full_name: "+" + (arr.length - pidx).toString(),
                                                        email: "N/A",
                                                    };
                                            })
                                                .filter((contact, pidx) => {
                                                return pidx < 4;
                                            })
                                                .map((contact, pidx, arr) => (_jsxs("span", Object.assign({ className: "text-wds-blue-3 text-base font-bold" }, { children: [contact.email === userEmail
                                                        ? "You"
                                                        : contact.full_name || contact.email, pidx < arr.length - 1 ? ", " : ""] }), pidx))) }))] })), tenant && call.call_type === "External" && (_jsxs("div", Object.assign({ className: "flex-col justify-around space-y-2 border-l pl-4 w-1/3" }, { children: [_jsx("div", Object.assign({ className: "mr-2" }, { children: tenant && call.call_type === "External" && (_jsx(AccountPopoverV2, { tenant: tenant, call: call, updateSelectedCall: updateSelectedCall, variant: "v3", className: "text-xs", stopPropagation: true })) })), _jsx("div", { children: tenant && call.call_type === "External" && (_jsx(OpportunityPopover, { tenant: tenant, call: call, updateSelectedCall: updateSelectedCall, variant: "v3", className: "text-xs", stopPropagation: true })) })] }))), _jsx("div", Object.assign({ className: "flex flex-col justify-center items-center" }, { children: _jsx(ArrowRightIcon, { className: "text-wds-blue-3 h-6 w-6" }) }))] })), call.summary && (_jsxs("div", Object.assign({ className: "text-base font-wds-gray-6" }, { children: [_jsx(Separator, { className: "my-4" }), call.summary] })))] }), call.id))) })), _jsx(Drawer, Object.assign({ open: hasCallIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate(`/home?${searchParams.toString()}`);
                } }, { children: _jsxs(DrawerContent, Object.assign({ className: "h-full sm:max-w-none p-0 sm:w-5/6", hideCloseButton: true }, { children: [_jsx(DrawerTitle, Object.assign({ className: "hidden" }, { children: "Call Details" })), _jsx(CallPage, { updateSelectedCall: updateSelectedCall, onClose: () => navigate(`/home?${searchParams.toString()}`) })] })) }))] })));
};
export default {
    WideScreenV3: CallListItemsWideScreenV3,
};
