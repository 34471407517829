var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { useIsMobile } from "./hooks/use_mobile";
import { Button } from "./button";
import { Input } from "./input";
import { Separator } from "./separator";
import { Drawer, DrawerContent } from "./drawer_dialog";
import { Skeleton } from "./skeleton";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "./tooltip";
import classNames from "../class_names";
import { Bars3Icon } from "@heroicons/react/24/outline";
const SIDEBAR_COOKIE_NAME = "sidebar:state";
const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7;
const SIDEBAR_WIDTH = "16rem";
const SIDEBAR_WIDTH_MOBILE = "18rem";
const SIDEBAR_WIDTH_ICON = "3rem";
const SIDEBAR_KEYBOARD_SHORTCUT = "b";
const SidebarContext = React.createContext(null);
function useSidebar() {
    const context = React.useContext(SidebarContext);
    if (!context) {
        throw new Error("useSidebar must be used within a SidebarProvider.");
    }
    return context;
}
const SidebarProvider = React.forwardRef((_a, ref) => {
    var { defaultOpen = false, open: openProp, onOpenChange: setOpenProp, className, style, children } = _a, props = __rest(_a, ["defaultOpen", "open", "onOpenChange", "className", "style", "children"]);
    const isMobile = useIsMobile();
    const [openMobile, setOpenMobile] = React.useState(false);
    // This is the internal state of the sidebar.
    // We use openProp and setOpenProp for control from outside the component.
    const [_open, _setOpen] = React.useState(defaultOpen);
    const open = openProp !== null && openProp !== void 0 ? openProp : _open;
    const setOpen = React.useCallback((value) => {
        const openState = typeof value === "function" ? value(open) : value;
        if (setOpenProp) {
            setOpenProp(openState);
        }
        else {
            _setOpen(openState);
        }
        // This sets the cookie to keep the sidebar state.
        document.cookie = `${SIDEBAR_COOKIE_NAME}=${openState}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`;
    }, [setOpenProp, open]);
    // Helper to toggle the sidebar.
    const toggleSidebar = React.useCallback(() => {
        return isMobile
            ? setOpenMobile((open) => !open)
            : setOpen((open) => !open);
    }, [isMobile, setOpen, setOpenMobile]);
    // Adds a keyboard shortcut to toggle the sidebar.
    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === SIDEBAR_KEYBOARD_SHORTCUT &&
                (event.metaKey || event.ctrlKey)) {
                event.preventDefault();
                toggleSidebar();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [toggleSidebar]);
    // We add a state so that we can do data-state="expanded" or "collapsed".
    // This makes it easier to style the sidebar with Tailwind classes.
    const state = open ? "expanded" : "collapsed";
    const contextValue = React.useMemo(() => ({
        state,
        open,
        setOpen,
        isMobile,
        openMobile,
        setOpenMobile,
        toggleSidebar,
    }), [
        state,
        open,
        setOpen,
        isMobile,
        openMobile,
        setOpenMobile,
        toggleSidebar,
    ]);
    return (_jsx(SidebarContext.Provider, Object.assign({ value: contextValue }, { children: _jsx(TooltipProvider, Object.assign({ delayDuration: 0 }, { children: _jsx("div", Object.assign({ style: Object.assign({ "--sidebar-width": SIDEBAR_WIDTH, "--sidebar-width-icon": SIDEBAR_WIDTH_ICON }, style), className: classNames("group/sidebar-wrapper min-h-svh w-full has-[[data-variant=inset]]:bg-wds-blue-6", className), ref: ref }, props, { children: children })) })) })));
});
SidebarProvider.displayName = "SidebarProvider";
const Sidebar = React.forwardRef((_a, ref) => {
    var { side = "left", variant = "sidebar", collapsible = "offcanvas", className, children } = _a, props = __rest(_a, ["side", "variant", "collapsible", "className", "children"]);
    const { isMobile, state, openMobile, setOpenMobile } = useSidebar();
    if (collapsible === "none") {
        return (_jsx("div", Object.assign({ className: classNames("flex h-full w-[--sidebar-width] flex-col bg-wds-blue-6 text-wds-white", className), ref: ref }, props, { children: children })));
    }
    if (isMobile) {
        return (_jsx(Drawer, Object.assign({ open: openMobile, onOpenChange: setOpenMobile }, props, { children: _jsx(DrawerContent, Object.assign({ "data-sidebar": "sidebar", "data-mobile": "true", className: "w-[--sidebar-width] bg-wds-blue-6 p-0 text-wds-white [&>button]:hidden", style: {
                    "--sidebar-width": SIDEBAR_WIDTH_MOBILE,
                }, side: side }, { children: _jsx("div", Object.assign({ className: "flex h-full w-full flex-col" }, { children: children })) })) })));
    }
    return (_jsxs("div", Object.assign({ ref: ref, className: "group peer hidden md:block text-wds-white", "data-state": state, "data-collapsible": state === "collapsed" ? collapsible : "", "data-variant": variant, "data-side": side }, { children: [_jsx("div", { className: classNames("duration-200 relative h-svh w-[--sidebar-width] bg-wds-blue-6 transition-[width] ease-linear", "group-data-[collapsible=offcanvas]:w-0", "group-data-[side=right]:rotate-180", variant === "floating" || variant === "inset"
                    ? "group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4))]"
                    : "group-data-[collapsible=icon]:w-[--sidebar-width-icon]") }), _jsx("div", Object.assign({ className: classNames("duration-200 fixed inset-y-0 z-50 hidden h-svh w-[--sidebar-width] transition-[left,right,width] ease-linear md:flex", side === "left"
                    ? "left-0 group-data-[collapsible=offcanvas]:left-[calc(var(--sidebar-width)*-1)]"
                    : "right-0 group-data-[collapsible=offcanvas]:right-[calc(var(--sidebar-width)*-1)]", 
                // Adjust the padding for floating and inset variants.
                variant === "floating" || variant === "inset"
                    ? "p-2 group-data-[collapsible=icon]:w-[calc(var(--sidebar-width-icon)_+_theme(spacing.4)_+2px)]"
                    : "group-data-[collapsible=icon]:w-[--sidebar-width-icon] group-data-[side=left]:border-r group-data-[side=right]:border-l", className) }, props, { children: _jsx("div", Object.assign({ "data-sidebar": "sidebar", className: "flex h-full w-full flex-col bg-wds-blue-6 group-data-[variant=floating]:rounded-lg group-data-[variant=floating]:border group-data-[variant=floating]:border-wds-blue-6 group-data-[variant=floating]:shadow" }, { children: children })) }))] })));
});
Sidebar.displayName = "Sidebar";
const SidebarTrigger = React.forwardRef((_a, ref) => {
    var { className, onClick } = _a, props = __rest(_a, ["className", "onClick"]);
    const { toggleSidebar } = useSidebar();
    return (_jsxs(Button, Object.assign({ ref: ref, "data-sidebar": "trigger", variant: "ghost", size: "icon", className: classNames("h-7 w-7", className), onClick: (event) => {
            onClick === null || onClick === void 0 ? void 0 : onClick(event);
            toggleSidebar();
        } }, props, { children: [_jsx(Bars3Icon, { className: "h-6 w-10 text-wds-gray-6" }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Toggle Sidebar" }))] })));
});
SidebarTrigger.displayName = "SidebarTrigger";
const SidebarRail = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    const { toggleSidebar } = useSidebar();
    return (_jsx("button", Object.assign({ ref: ref, "data-sidebar": "rail", "aria-label": "Toggle Sidebar", tabIndex: -1, onClick: toggleSidebar, title: "Toggle Sidebar", className: classNames("absolute inset-y-0 z-20 hidden w-4 -translate-x-1/2 transition-all ease-linear after:absolute after:inset-y-0 after:left-1/2 after:w-[2px] hover:after:bg-wds-blue-6-border group-data-[side=left]:-right-4 group-data-[side=right]:left-0 sm:flex", "[[data-side=left]_&]:cursor-w-resize [[data-side=right]_&]:cursor-e-resize", "[[data-side=left][data-state=collapsed]_&]:cursor-e-resize [[data-side=right][data-state=collapsed]_&]:cursor-w-resize", "group-data-[collapsible=offcanvas]:translate-x-0 group-data-[collapsible=offcanvas]:after:left-full group-data-[collapsible=offcanvas]:hover:bg-wds-blue-6", "[[data-side=left][data-collapsible=offcanvas]_&]:-right-2", "[[data-side=right][data-collapsible=offcanvas]_&]:-left-2", className) }, props)));
});
SidebarRail.displayName = "SidebarRail";
const SidebarInset = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("main", Object.assign({ ref: ref, className: classNames("relative flex min-h-svh flex-1 flex-col bg-wds-blue-6", "peer-data-[variant=inset]:min-h-[calc(100svh-theme(spacing.4))] md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-xl md:peer-data-[variant=inset]:shadow", className) }, props)));
});
SidebarInset.displayName = "SidebarInset";
const SidebarInput = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(Input, Object.assign({ ref: ref, "data-sidebar": "input", className: classNames("h-8 w-full bg-wds-blue-1 shadow-none focus-visible:ring-2 focus-visible:ring-sidebar-ring", className) }, props)));
});
SidebarInput.displayName = "SidebarInput";
const SidebarHeader = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-sidebar": "header", className: classNames("flex flex-col gap-2 p-2", className) }, props)));
});
SidebarHeader.displayName = "SidebarHeader";
const SidebarFooter = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-sidebar": "footer", className: classNames("flex flex-col gap-2 p-2", className) }, props)));
});
SidebarFooter.displayName = "SidebarFooter";
const SidebarSeparator = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(Separator, Object.assign({ ref: ref, "data-sidebar": "separator", className: classNames("mx-2 w-auto bg-wds-white", className) }, props)));
});
SidebarSeparator.displayName = "SidebarSeparator";
const SidebarContent = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-sidebar": "content", className: classNames("flex min-h-0 flex-1 flex-col gap-2 overflow-auto group-data-[collapsible=icon]:overflow-hidden", className) }, props)));
});
SidebarContent.displayName = "SidebarContent";
const SidebarGroup = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-sidebar": "group", className: classNames("relative flex w-full min-w-0 flex-col p-2", className) }, props)));
});
SidebarGroup.displayName = "SidebarGroup";
const SidebarGroupLabel = React.forwardRef((_a, ref) => {
    var { className, asChild = false } = _a, props = __rest(_a, ["className", "asChild"]);
    const Comp = asChild ? Slot : "div";
    return (_jsx(Comp, Object.assign({ ref: ref, "data-sidebar": "group-label", className: classNames("duration-200 flex h-8 shrink-0 items-center rounded-lg px-2 text-xs font-medium text-wds-white/70 outline-none ring-sidebar-ring transition-[margin,opa] ease-linear focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0", "group-data-[collapsible=icon]:-mt-8 group-data-[collapsible=icon]:opacity-0", className) }, props)));
});
SidebarGroupLabel.displayName = "SidebarGroupLabel";
const SidebarGroupAction = React.forwardRef((_a, ref) => {
    var { className, asChild = false } = _a, props = __rest(_a, ["className", "asChild"]);
    const Comp = asChild ? Slot : "button";
    return (_jsx(Comp, Object.assign({ ref: ref, "data-sidebar": "group-action", className: classNames("absolute right-3 top-3.5 flex aspect-square w-5 items-center justify-center rounded-lg p-0 text-wds-white outline-none ring-sidebar-ring transition-transform hover:bg-wds-blue-5 hover:text-wds-white focus-visible:ring-2 [&>svg]:size-4 [&>svg]:shrink-0", 
        // Increases the hit area of the button on mobile.
        "after:absolute after:-inset-2 after:md:hidden", "group-data-[collapsible=icon]:hidden", className) }, props)));
});
SidebarGroupAction.displayName = "SidebarGroupAction";
const SidebarGroupContent = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-sidebar": "group-content", className: classNames("w-full text-sm", className) }, props)));
});
SidebarGroupContent.displayName = "SidebarGroupContent";
const SidebarMenu = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("ul", Object.assign({ ref: ref, "data-sidebar": "menu", className: classNames("flex w-full min-w-0 flex-col space-y-2 list-none px-0", className) }, props)));
});
SidebarMenu.displayName = "SidebarMenu";
const SidebarMenuItem = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("li", Object.assign({ ref: ref, "data-sidebar": "menu-item", className: classNames("group/menu-item relative rounded-lg", className) }, props)));
});
SidebarMenuItem.displayName = "SidebarMenuItem";
const sidebarMenuButtonVariants = cva("peer/menu-button flex w-full items-center gap-2 overflow-hidden rounded-lg p-2 text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] hover:bg-wds-blue-5 hover:text-wds-white focus-visible:ring-2 active:bg-wds-blue-5 active:text-wds-white disabled:pointer-events-none disabled:opacity-50 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-wds-blue-5 data-[active=true]:font-medium data-[active=true]:text-wds-white data-[state=open]:hover:bg-wds-blue-5 data-[state=open]:hover:text-wds-white group-data-[collapsible=icon]:!size-8 group-data-[collapsible=icon]:!p-2 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0", {
    variants: {
        variant: {
            default: "hover:bg-wds-blue-5 hover:text-wds-white rounded-lg",
            outline: "bg-background shadow-[0_0_0_1px_hsl(var(--sidebar-border))] hover:bg-wds-blue-5 hover:text-wds-white hover:shadow-[0_0_0_1px_hsl(var(--wds-blue-5))]",
        },
        size: {
            default: "h-8 text-sm",
            sm: "h-7 text-xs",
            lg: "h-12 text-sm group-data-[collapsible=icon]:!p-0",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
});
const SidebarMenuButton = React.forwardRef((_a, ref) => {
    var { asChild = false, isActive = false, variant = "default", size = "default", tooltip, className } = _a, props = __rest(_a, ["asChild", "isActive", "variant", "size", "tooltip", "className"]);
    const Comp = asChild ? Slot : "button";
    const { isMobile, state } = useSidebar();
    const button = (_jsx(Comp, Object.assign({ ref: ref, "data-sidebar": "menu-button", "data-size": size, "data-active": isActive, className: classNames(sidebarMenuButtonVariants({ variant, size }), className) }, props)));
    if (!tooltip) {
        return button;
    }
    if (typeof tooltip === "string") {
        tooltip = {
            children: tooltip,
        };
    }
    return (_jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: button })), _jsx(TooltipContent, Object.assign({ side: "right", align: "center", hidden: state !== "collapsed" || isMobile }, tooltip))] }));
});
SidebarMenuButton.displayName = "SidebarMenuButton";
const SidebarMenuAction = React.forwardRef((_a, ref) => {
    var { className, asChild = false, showOnHover = false } = _a, props = __rest(_a, ["className", "asChild", "showOnHover"]);
    const Comp = asChild ? Slot : "button";
    return (_jsx(Comp, Object.assign({ ref: ref, "data-sidebar": "menu-action", className: classNames("absolute right-1 top-1.5 flex aspect-square w-5 items-center justify-center rounded-lg p-0 text-wds-white outline-none ring-sidebar-ring transition-transform hover:bg-wds-blue-5 hover:text-wds-white focus-visible:ring-2 peer-hover/menu-button:text-wds-white [&>svg]:size-4 [&>svg]:shrink-0", 
        // Increases the hit area of the button on mobile.
        "after:absolute after:-inset-2 after:md:hidden", "peer-data-[size=sm]/menu-button:top-1", "peer-data-[size=default]/menu-button:top-1.5", "peer-data-[size=lg]/menu-button:top-2.5", "group-data-[collapsible=icon]:hidden", showOnHover &&
            "group-focus-within/menu-item:opacity-100 group-hover/menu-item:opacity-100 data-[state=open]:opacity-100 peer-data-[active=true]/menu-button:text-wds-white md:opacity-0", className) }, props)));
});
SidebarMenuAction.displayName = "SidebarMenuAction";
const SidebarMenuBadge = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ ref: ref, "data-sidebar": "menu-badge", className: classNames("absolute right-1 flex h-5 min-w-5 items-center justify-center rounded-lg px-1 text-xs font-medium tabular-nums text-wds-white select-none pointer-events-none", "peer-hover/menu-button:text-wds-white peer-data-[active=true]/menu-button:text-wds-white", "peer-data-[size=sm]/menu-button:top-1", "peer-data-[size=default]/menu-button:top-1.5", "peer-data-[size=lg]/menu-button:top-2.5", "group-data-[collapsible=icon]:hidden", className) }, props)));
});
SidebarMenuBadge.displayName = "SidebarMenuBadge";
const SidebarMenuSkeleton = React.forwardRef((_a, ref) => {
    var { className, showIcon = false } = _a, props = __rest(_a, ["className", "showIcon"]);
    // Random width between 50 to 90%.
    const width = React.useMemo(() => {
        return `${Math.floor(Math.random() * 40) + 50}%`;
    }, []);
    return (_jsxs("div", Object.assign({ ref: ref, "data-sidebar": "menu-skeleton", className: classNames("rounded-lg h-8 flex gap-2 px-2 items-center", className) }, props, { children: [showIcon && (_jsx(Skeleton, { className: "size-4 rounded-lg", "data-sidebar": "menu-skeleton-icon" })), _jsx(Skeleton, { className: "h-4 flex-1 max-w-[--skeleton-width]", "data-sidebar": "menu-skeleton-text", style: {
                    "--skeleton-width": width,
                } })] })));
});
SidebarMenuSkeleton.displayName = "SidebarMenuSkeleton";
const SidebarMenuSub = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx("ul", Object.assign({ ref: ref, "data-sidebar": "menu-sub", className: classNames("mx-3.5 flex min-w-0 translate-x-px flex-col gap-1 border-l border-wds-blue-6 px-2.5 py-0.5", "group-data-[collapsible=icon]:hidden list-none", className) }, props)));
});
SidebarMenuSub.displayName = "SidebarMenuSub";
const SidebarMenuSubItem = React.forwardRef((_a, ref) => {
    var props = __rest(_a, []);
    return _jsx("li", Object.assign({ ref: ref }, props));
});
SidebarMenuSubItem.displayName = "SidebarMenuSubItem";
const SidebarMenuSubButton = React.forwardRef((_a, ref) => {
    var { asChild = false, size = "md", isActive, className } = _a, props = __rest(_a, ["asChild", "size", "isActive", "className"]);
    const Comp = asChild ? Slot : "a";
    return (_jsx(Comp, Object.assign({ ref: ref, "data-sidebar": "menu-sub-button", "data-size": size, "data-active": isActive, className: classNames("flex h-7 min-w-0 -translate-x-px items-center gap-2 overflow-hidden rounded-lg px-2 text-wds-white outline-none ring-sidebar-ring hover:bg-wds-blue-5 hover:text-wds-white focus-visible:ring-2 active:bg-wds-blue-5 active:text-wds-white disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-wds-white", "data-[active=true]:bg-wds-blue-5 data-[active=true]:text-wds-white", size === "sm" && "text-xs", size === "md" && "text-sm", "group-data-[collapsible=icon]:hidden", className) }, props)));
});
SidebarMenuSubButton.displayName = "SidebarMenuSubButton";
export { Sidebar, SidebarContent, SidebarFooter, SidebarGroup, SidebarGroupAction, SidebarGroupContent, SidebarGroupLabel, SidebarHeader, SidebarInput, SidebarInset, SidebarMenu, SidebarMenuAction, SidebarMenuBadge, SidebarMenuButton, SidebarMenuItem, SidebarMenuSkeleton, SidebarMenuSub, SidebarMenuSubButton, SidebarMenuSubItem, SidebarProvider, SidebarRail, SidebarSeparator, SidebarTrigger, useSidebar, };
