import { jsx as _jsx } from "react/jsx-runtime";
// Websocket container class to handle the websocket connection to the server.
import { createContext, useContext, useRef } from "react";
import { startCallOrUserStream } from "./endpoints";
import WebSocketManagerSingleton, { LOCAL_SOCKET_STATUS, } from "./web_socket_manager_singleton";
const StreamSocketContext = createContext(null);
export const useStreamSocket = () => {
    const context = useContext(StreamSocketContext);
    if (!context) {
        throw new Error("useStreamSocket must be used within a StreamSocketProvider");
    }
    return context;
};
class ReconnectingWebSocket extends WebSocket {
    constructor(url) {
        super(url);
        this.wasClosedIntentionally = false;
    }
    close(code, reason) {
        this.wasClosedIntentionally = true;
        super.close(code, reason);
    }
    closeAndRetry(code, reason) {
        super.close(code, reason);
    }
}
export const StreamSocketProvider = (props) => {
    const socketRef = useRef(null);
    const listeners = useRef(new Map());
    const webSocketManager = WebSocketManagerSingleton.getInstance();
    const closeCallback = useRef(() => { });
    const openCallback = useRef(() => { });
    const connectToStreamSocket = (callId) => {
        // This socketRef is shared across the app, so when a new call is selected
        // The same socket from the previous call may still be open
        // So we need to close it, and manage the state accordingly
        if (socketRef.current) {
            socketRef.current.addEventListener("close", (event) => {
                // Don't auto-reopen the socket.
                console.log("Connection closed by user.", webSocketManager.socketStatus);
                // Mark the socket as closed, so that a new socket can be opened
                webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CLOSED;
            });
            console.log("Closing socket due to user", webSocketManager.socketStatus);
            // Mark the socket as closing so another socket cannot be opened
            webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CLOSING;
            socketRef.current.close();
            if (socketRef.current.readyState === ReconnectingWebSocket.CLOSED) {
                // If socket is already closed, mark state as closed
                console.log("Websocket already closed!");
                webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CLOSED;
            }
        }
        const socketProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        let url = socketProtocol + "//" + window.location.host + "/ws/stream/";
        startCallOrUserStream(callId).then((socketSettings) => {
            if (socketSettings.backend !== "hud") {
                url = socketSettings.backend;
            }
            console.log("Connecting to " + url, webSocketManager.socketStatus);
            var reconnectAttempts = 0;
            const reconnect_callback = () => {
                reconnectAttempts++;
                if (reconnectAttempts > 10) {
                    console.log("Failed to reconnect after 10 attempts. Aborting.");
                    return;
                }
                setTimeout(() => {
                    console.log("Recreating socket");
                    let webSocket = create_socket(url, closeCallback.current, openCallback.current, reconnect_callback, listeners.current, socketSettings.jwt, callId);
                    socketRef.current = webSocket;
                }, 1000 * reconnectAttempts);
            };
            const invalidStates = [
                LOCAL_SOCKET_STATUS.CLOSING,
                LOCAL_SOCKET_STATUS.CONNECTING,
                LOCAL_SOCKET_STATUS.OPEN,
            ];
            if (invalidStates.includes(webSocketManager.socketStatus)) {
                console.log("Cannot connect socket, invalid state", webSocketManager.socketStatus);
                return;
            }
            webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CONNECTING;
            const webSocket = create_socket(url, closeCallback.current, openCallback.current, reconnect_callback, listeners.current, socketSettings.jwt, callId);
            socketRef.current = webSocket;
        });
    };
    // Adds a listener for a particular Stream Reduce stage.
    const addListener = (stage_name, callback) => {
        let callbackList = listeners.current.get(stage_name);
        if (!callbackList) {
            callbackList = [];
        }
        listeners.current.set(stage_name, [...callbackList, callback]);
    };
    const send = (message) => {
        if (!socketRef.current) {
            return;
        }
        if (socketRef.current.readyState !== ReconnectingWebSocket.OPEN) {
            return;
        }
        socketRef.current.send(message);
    };
    const send_audio = (audioBlob, sampleRate, startMsec, endMsec, speaker) => {
        if (!socketRef.current) {
            return;
        }
        if (socketRef.current.readyState !== ReconnectingWebSocket.OPEN) {
            return;
        }
        socketRef.current.send(JSON.stringify({
            type: "audio_chunk_metadata",
            sample_rate: sampleRate,
            startTime: startMsec,
            endTime: endMsec,
            speaker: speaker,
            id: Math.random().toString(36).substring(7),
        }));
        socketRef.current.send(audioBlob);
    };
    const close = () => {
        // Mark the socket as closing, so another socket is not opened
        // Until this socket is closed
        webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CLOSING;
        console.log("Closing socket", webSocketManager.socketStatus);
        if (!socketRef.current) {
            console.log("No socket to close");
            webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CLOSED;
            return;
        }
        if (socketRef.current.readyState !== ReconnectingWebSocket.OPEN) {
            // If the socket is not open yet, we can't issue a "shutdown"
            // command to the server, so we just close the socket.
            socketRef.current.close();
            return;
        }
        console.log("Closing socket, it's open right now");
        socketRef.current.close();
        listeners.current.clear();
    };
    const setCloseCallback = (cb) => {
        closeCallback.current = cb;
    };
    const setOpenCallback = (cb) => {
        openCallback.current = cb;
    };
    return (_jsx(StreamSocketContext.Provider, Object.assign({ value: {
            connectToStreamSocket,
            addListener,
            send,
            send_audio,
            close,
            setCloseCallback,
            setOpenCallback,
        } }, { children: props.children })));
};
function create_socket(url, closeCallback, openCallback, reconnectCallback, listeners, streamJwt, callId) {
    const webSocket = new ReconnectingWebSocket(url);
    webSocket.addEventListener("error", (event) => {
        console.log("Error occurred: " + event);
        if (!webSocket.wasClosedIntentionally) {
            reconnectCallback();
        }
        webSocket.close();
    });
    webSocket.addEventListener("close", (event) => {
        const webSocketManager = WebSocketManagerSingleton.getInstance();
        webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.CLOSED;
        console.log("Connection closed", webSocketManager.socketStatus);
        closeCallback();
        if (!webSocket.wasClosedIntentionally) {
            reconnectCallback();
        }
    });
    webSocket.addEventListener("message", (event) => {
        var _a;
        const message = JSON.parse(event.data);
        // Message looks like:
        // {"type": "output_stage", "stage_name": "zoom_bot_status", "js_chunk": {"zoom_bot_status": "Started"}}
        if (!message.type) {
            console.log("Received message with no type: " + event.data);
            return;
        }
        if (message.type === "error") {
            console.log("Error: " + message.message);
        }
        else if (message.type === "server_disconnected") {
            console.log("Error: server_disconnected");
            webSocket.closeAndRetry();
            startCallOrUserStream(callId); // This should trigger the backend to start again. Idempotent.
        }
        else if (message.type !== "output_stage") {
            console.log("Received message with unexpected type: " + message.type);
            return;
        }
        if (openCallback) {
            // Really only need to call it here for the relay. For relay, opening just
            // means we are connected to the relay, not to the backend. Once we get a
            // message back, then we are really connected.
            openCallback();
        }
        let webSocketMessage = message.js_chunk;
        (_a = listeners.get(message.stage_name)) === null || _a === void 0 ? void 0 : _a.forEach((listener) => {
            listener(webSocketMessage);
        });
    });
    webSocket.addEventListener("open", (event) => {
        const webSocketManager = WebSocketManagerSingleton.getInstance();
        webSocketManager.socketStatus = LOCAL_SOCKET_STATUS.OPEN;
        console.log("Connection established", webSocketManager.socketStatus);
        if (!!!streamJwt) {
            webSocket.send(JSON.stringify({
                type: "authenticate",
                wiser_jwt: localStorage.getItem("wiser_jwt"),
            }));
            let stages = Array.from(listeners.keys()).filter((stage) => stage !== "error");
            if (callId > 0) {
                webSocket.send(JSON.stringify({
                    type: "set_active_call",
                    call_id: callId,
                    output_stages: stages,
                }));
            }
        }
        else {
            webSocket.send(JSON.stringify({
                type: "setup",
                is_backend: false,
                jwt: streamJwt,
            }));
            // I think in practice this will only send reliably if the socket is
            // already open (serving another client).
            webSocket.send(JSON.stringify({
                type: "resend_last",
            }));
        }
    });
    return webSocket;
}
