import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BriefcaseIcon, ExclamationCircleIcon, GlobeAltIcon, PencilIcon, } from "@heroicons/react/24/outline";
import CallCrmDetailsPopover, { NoCrmObjectMappedPopoverPanel, } from "../call_crm_details_popover";
import { useMemo, useState } from "react";
import CallCrmAccountModal from "../call_crm_account_modal";
import { getAccountLinkedInUrl, getAccountName, } from "../call_account_utils";
import { Link } from "react-router-dom";
import { Button } from "../../components/button";
import { getConnectedCrmLogo } from "../crm_logo";
import { getExternalCrmObjectLink } from "../../crm/common/external_crm_link";
import { useAppIntegrationData } from "../use_app_integration_data";
import { getAccountLabel } from "../crm_utils";
import { AccountPopoverButton } from "./account_popover_button";
const AccountDetails = ({ tenant, call, setCallCrmAccountModalOpen, }) => {
    var _a;
    const accountExistsInCrm = !tenant.connected_crm || !!((_a = call.account) === null || _a === void 0 ? void 0 : _a.external_id);
    const { appIntegrationData } = useAppIntegrationData(tenant.id);
    const externalCrmObjectLink = useMemo(() => {
        var _a;
        if (!tenant.connected_crm || !((_a = call.account) === null || _a === void 0 ? void 0 : _a.external_id)) {
            return undefined;
        }
        return getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], "Account", call.account.external_id);
    }, [appIntegrationData, call, tenant]);
    const accountLabel = getAccountLabel(tenant.connected_crm);
    const renderEditButton = () => {
        return (_jsx("button", Object.assign({ onClick: (e) => {
                setCallCrmAccountModalOpen(true);
                e.stopPropagation();
            }, className: "flex w-8 h-8 justify-center items-center rounded-full" }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) })));
    };
    const isOnHomeScreen = window.location.pathname === "/home";
    return (_jsx("div", Object.assign({ className: "flex flex-col items-start gap-2 max-w-sm" }, { children: call.account ? (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-start self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-start gap-4" }, { children: [call.account.logo_url ? (_jsx("img", { className: "w-9 h-9", src: call.account.logo_url })) : (_jsx(BriefcaseIcon, { className: "h-9 w-9 text-wds-gray-6" })), _jsxs("div", Object.assign({ className: "flex flex-col gap-[2px]" }, { children: [_jsx("span", Object.assign({ className: "text-base font-bold text-black" }, { children: getAccountName(call.account, call.account.website) })), !accountExistsInCrm && (_jsxs("div", Object.assign({ className: "flex items-center justify-start gap-1 text-yellow-600" }, { children: [_jsx(ExclamationCircleIcon, { className: "w-4 h-4" }), _jsxs("span", { children: ["No ", accountLabel, " associated"] }), _jsx("button", Object.assign({ onClick: (e) => {
                                                        setCallCrmAccountModalOpen(true);
                                                        e.stopPropagation();
                                                    }, className: "text-wds-blue-3" }, { children: "Resolve" }))] }))), _jsx("span", Object.assign({ className: "text-sm text-wds-gray-6 font-normal" }, { children: call.account.industry && call.account.industry.length > 0
                                                ? call.account.industry
                                                : "No industry found" }))] }))] })), renderEditButton()] })), _jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center" }, { children: _jsx("a", Object.assign({ href: call.account.website, target: "_blank", rel: "noreferrer" }, { children: _jsx(GlobeAltIcon, { className: "h-5 w-5 text-wds-blue-4" }) })) })), _jsx("a", Object.assign({ href: call.account.website, target: "_blank", className: "w-full" }, { children: _jsx("p", Object.assign({ className: "text-left break-all text-wds-blue-3 truncate text-sm font-medium" }, { children: call.account.website })) }))] })), _jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("a", Object.assign({ href: call.account.linkedin_profile_url, target: "_blank", rel: "noopener noreferrer", className: "inline-block" }, { children: _jsx("img", { className: "h-4 w-4", src: call.account.linkedin_profile_url &&
                                        call.account.linkedin_profile_url.length > 0
                                        ? "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-enable.png"
                                        : "https://storage.googleapis.com/wiser-ai-public2/linkedin-logo-disable.png" }) })) })), _jsx("a", Object.assign({ href: call.account.linkedin_profile_url, target: "_blank", className: "w-full" }, { children: _jsx("p", Object.assign({ className: "text-left break-all text-wds-blue-3 truncate text-sm font-medium", style: {
                                    // In order to render the end of the URL rather than common start
                                    direction: "rtl",
                                } }, { children: getAccountLinkedInUrl(call.account, "No linkedin profile found") })) }))] })), externalCrmObjectLink && (_jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("img", { className: "h-4 w-4", src: getConnectedCrmLogo(tenant.connected_crm) }) })), _jsx(Link, Object.assign({ to: externalCrmObjectLink, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "text-wds-blue-3 h-auto p-0 font-medium" }, { children: `Open in ${tenant.connected_crm}` })) }))] }))), _jsxs("div", Object.assign({ className: "flex items-center gap-5 self-stretch overflow-hidden" }, { children: [_jsx("div", Object.assign({ className: "flex w-8 h-8 p-2 items-center justify-center shrink-0" }, { children: _jsx("img", { className: "h-4 w-4", src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png" }) })), _jsx(Link, Object.assign({ to: isOnHomeScreen
                                ? `/home/account/${call.account.id}`
                                : `/account/${call.account.id}`, target: isOnHomeScreen ? "_self" : "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "text-wds-blue-3 h-auto p-0 font-medium" }, { children: "See more details in Wiser" })) }))] }))] })) : (_jsx(NoCrmObjectMappedPopoverPanel, { title: `No ${accountLabel} mapped`, editButtonClickHandler: () => setCallCrmAccountModalOpen(true) })) })));
};
const AccountPopoverV2 = (props) => {
    const [callCrmAccountModalOpen, setCallCrmAccountModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(CallCrmDetailsPopover, { button: _jsx(AccountPopoverButton, { account: props.call.account, tenant: props.tenant, variant: props.variant, className: props.className }), panel: _jsx(AccountDetails, { tenant: props.tenant, call: props.call, setCallCrmAccountModalOpen: setCallCrmAccountModalOpen }), stopPropagation: props.stopPropagation }), _jsx(CallCrmAccountModal, { tenant: props.tenant, call: props.call, open: callCrmAccountModalOpen, setOpen: setCallCrmAccountModalOpen, updateSelectedCall: props.updateSelectedCall })] }));
};
export default AccountPopoverV2;
