var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { deleteLiveCall } from "../common/endpoints";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, } from "../components/dialog";
import { Button } from "../components/button";
import Badge from "../common/badge";
import { CallDetailsChips, CallDetailsChipsOptions, } from "../call_heading/call_details_chips";
import AutojoinDisplay from "./auto_join_display";
const CallHeadingV3 = (props) => {
    const { call } = props;
    const deleteCall = (call) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteLiveCall(call.id);
        window.location.reload(); // TODO: find a better way to handle deletion
    });
    return (_jsxs("div", Object.assign({ className: "flex w-full px-8 pt-8 border-neutral-200 flex-col justify-start items-start bg-white" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch justify-start items-center inline-flex pr-8" }, { children: [_jsx("div", Object.assign({ className: "grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: [_jsx("div", Object.assign({ className: "text-[24px] font-medium leading-8" }, { children: call.name })), call.is_deleted && (_jsx(Badge, { text: "Deleted meeting", classes: "bg-destructive-light ml-2 border border-destructive-border rounded-full", textClasses: "text-destructive-dark text-xs" })), _jsx(AutojoinDisplay, { call: call, updateSelectedCall: props.updateSelectedCall })] })) })), _jsx(Link, Object.assign({ to: `/call/${call.id}` }, { children: _jsx(Button, Object.assign({ className: "font-bold mr-2" }, { children: "Join call" })) })), _jsxs("div", Object.assign({ className: "flex gap-1" }, { children: [_jsxs("div", Object.assign({ className: "relative group" }, { children: [_jsxs(Dialog, { children: [_jsx(DialogTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "text-destructive hover:text-destructive-dark hover:bg-destructive-foreground" }, { children: _jsx(TrashIcon, { className: "h-5 w-5" }) })) })), _jsxs(DialogContent, { children: [_jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: "Delete Call" }), _jsxs(DialogDescription, { children: ["Are you sure you want to delete the call \"", call.name, "\"?"] })] }), _jsx(DialogFooter, { children: _jsx(Button, Object.assign({ variant: "destructive", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                                yield deleteCall(call);
                                                            }) }, { children: "Delete" })) })] })] }), _jsx("div", Object.assign({ className: "absolute -left-6 mt-2 hidden w-max px-2 py-1 bg-black text-white text-sm rounded-md group-hover:block" }, { children: "Delete call" }))] })), props.onClose && (_jsx("button", Object.assign({ className: "text-wds-gray-4 hover:text-wds-gray-6", onClick: props.onClose }, { children: _jsx(XMarkIcon, { className: "h-5 w-5 m-1" }) })))] }))] })), _jsx(CallDetailsChips, { call: call, updateCall: props.updateSelectedCall, variant: CallDetailsChipsOptions.V3, className: "border-wds-blue-2 bg-wds-blue-1 font-wds-blue-4" })] })));
};
export default CallHeadingV3;
