var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { z } from "zod";
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogHeader, DialogClose, DialogFooter, } from "../components/modal_dialog";
import { Button } from "../components/button";
import { AsyncSearchMultiSelect } from "../components/async_search_multi_select";
import { Input } from "../components/input";
import { UserContext } from "../App";
import { createCallAndSetParticipants, createContactFromEmail, fetchParticipantsAutocomplete, } from "../common/endpoints";
import { useNavigate } from "react-router-dom";
import { formatToLocalISOString } from "../strings";
import { RadioGroup, RadioGroupItem } from "../components/radio_group";
import Dropdown from "../common/dropdown";
export const AddCallDialog = () => {
    const { userEmail: currentUserEmail } = useContext(UserContext);
    const [addCallOpen, setAddCallOpen] = useState(false);
    const [participantEmails, setParticipantEmails] = useState([
        currentUserEmail,
    ]);
    const [callName, setCallName] = useState("");
    const [callNameManuallySet, setCallNameManuallySet] = useState(false);
    const [meetingLink, setMeetingLink] = useState("");
    const [callTime, setCallTime] = useState(new Date());
    const [callDurationMinutes, setCallDurationMinutes] = useState(30);
    const [joinSetting, setJoinSetting] = useState("default");
    const [callType, setCallType] = useState("External");
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const participantsFetcher = (userQuery) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield fetchParticipantsAutocomplete(userQuery);
        return {
            results: results.map((result) => ({
                label: result.email,
                value: result.email,
                nonRemovable: result.email === currentUserEmail,
            })),
            next_cursor: undefined,
        };
    });
    const closeDialog = () => {
        setAddCallOpen(false);
        resetForm();
    };
    const resetForm = () => {
        setCallName("");
        setCallNameManuallySet(false);
        setParticipantEmails([currentUserEmail]);
        setMeetingLink("");
        setCallTime(new Date());
        setCallDurationMinutes(30);
        setJoinSetting("default");
        setCallType("External");
        setErrors({});
        setSubmitting(false);
    };
    const callSchema = z.object({
        participantEmails: z
            .array(z.string().email())
            .min(1, "At least one valid participant email is required."),
        callName: z.string().min(1, "Call name is required."),
        meetingLink: z
            .string()
            .url("Meeting link must be a valid URL (example https://meet.google.com/...)"),
        callTime: z.date(),
        callDurationMinutes: z
            .number()
            .min(1, "Call duration must be greater than 0.")
            .int("Call duration must be an integer."),
        callType: z.enum(["External", "Internal"]),
    });
    const handleCreateCall = () => __awaiter(void 0, void 0, void 0, function* () {
        setErrors({});
        setSubmitting(true);
        const data = {
            participantEmails,
            callName,
            meetingLink,
            callTime,
            callDurationMinutes,
            joinSetting,
            callType,
        };
        const result = callSchema.safeParse(data);
        if (!result.success) {
            const validationErrors = result.error.flatten();
            const errorMessages = {};
            for (const [key, messages] of Object.entries(validationErrors.fieldErrors)) {
                if (messages && messages.length > 0) {
                    errorMessages[key] = messages[0];
                }
            }
            setErrors(errorMessages);
            setSubmitting(false);
            return;
        }
        const response = yield createCallAndSetParticipants(callName, participantEmails, meetingLink, callTime, callDurationMinutes, joinSetting, callType);
        if (response.errors) {
            setErrors(response.errors);
            setSubmitting(false);
            return;
        }
        closeDialog();
        setSubmitting(false);
        navigate(`/call/${response.call_id}`);
    });
    useEffect(() => {
        if (!callNameManuallySet && participantEmails.length > 1) {
            setCallName(participantEmails.map((email) => email.split("@")[0]).join(" / "));
        }
    }, [participantEmails, callNameManuallySet]);
    return (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-blue-100", onClick: () => setAddCallOpen(true) }, { children: _jsx(PlusIcon, { className: "h-5 w-5 text-wds-gray-5" }) })), _jsx(Dialog, Object.assign({ open: addCallOpen, onOpenChange: (open) => setAddCallOpen(open) }, { children: _jsxs(DialogContent, Object.assign({ className: "relative px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-xl lg:max-w-4xl sm:p-6 font-normal text-sm rounded-2xl border border-neutral-200 font-['Lato']" }, { children: [_jsxs(DialogHeader, { children: [_jsx(DialogTitle, Object.assign({ className: "pt-4 pb-4" }, { children: "Add Call" })), _jsx(DialogDescription, { children: "A call is an upcoming meeting with a set of participants." })] }), _jsxs(DialogDescription, Object.assign({ className: "pt-4 pb-4" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-1" }, { children: [_jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium" }, { children: ["Participant emails", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsx(AsyncSearchMultiSelect, { className: "w-full", wrapPills: true, id: "participants", dataFetcher: participantsFetcher, onChange: (options) => {
                                                setParticipantEmails(options.map((option) => option.value));
                                            }, placeholder: "Search for participants", onCreate: (newOptionLabel) => __awaiter(void 0, void 0, void 0, function* () {
                                                yield createContactFromEmail(newOptionLabel);
                                                setParticipantEmails((prev) => [...prev, newOptionLabel]);
                                            }), selectedOptions: participantEmails.map((email) => ({
                                                label: email,
                                                value: email,
                                                nonRemovable: email === currentUserEmail,
                                            })) }), errors.participantEmails && (_jsx("div", Object.assign({ className: "text-red-500 text-xs mt-1" }, { children: errors.participantEmails }))), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium mt-4" }, { children: ["Call name", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsx(Input, { placeholder: "Call name", value: callName, onChange: (e) => setCallName(e.target.value), onBlur: () => {
                                                if (callName) {
                                                    setCallNameManuallySet(true);
                                                }
                                            } }), errors.callName && (_jsx("div", Object.assign({ className: "text-red-500 text-xs mt-1" }, { children: errors.callName }))), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium mt-4" }, { children: ["Meeting link", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsx(Input, { placeholder: "Meeting link", value: meetingLink, onChange: (e) => setMeetingLink(e.target.value) }), errors.meetingLink && (_jsx("div", Object.assign({ className: "text-red-500 text-xs mt-1" }, { children: errors.meetingLink }))), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium mt-4" }, { children: ["Call time", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsx(Input, { placeholder: "Call time", type: "datetime-local", value: callTime ? formatToLocalISOString(callTime) : "", onChange: (e) => {
                                                if (e.target.value) {
                                                    setCallTime(new Date(e.target.value));
                                                }
                                                else {
                                                    setCallTime(null);
                                                }
                                            } }), errors.callTime && (_jsx("div", Object.assign({ className: "text-red-500 text-xs mt-1" }, { children: errors.callTime }))), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium mt-4" }, { children: ["Call duration", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsx(Input, { placeholder: "Call duration (minutes)", type: "number", value: callDurationMinutes, onChange: (e) => setCallDurationMinutes(parseInt(e.target.value)) }), errors.callDurationMinutes && (_jsx("div", Object.assign({ className: "text-red-500 text-xs mt-1" }, { children: errors.callDurationMinutes }))), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium mt-4" }, { children: ["Call type", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsx(Dropdown, { options: [
                                                { value: "External", label: "External" },
                                                { value: "Internal", label: "Internal" },
                                            ], defaultSelected: "External", onOptionSelect: (option) => setCallType(option), selectedOption: callType, buttonClasses: "bg-white rounded-lg border border-neutral-200 shadow-sm" })] })), _jsxs("div", Object.assign({ className: "text-wds-gray-5 text-sm font-medium mt-4" }, { children: ["Wiser join settings", _jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))] })), _jsxs(RadioGroup, Object.assign({ className: "flex flex-col gap-2 mt-2", value: joinSetting, onValueChange: (value) => setJoinSetting(value) }, { children: [_jsxs("label", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(RadioGroupItem, { value: "default" }), "Autojoin based on the default settings"] })), _jsxs("label", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(RadioGroupItem, { value: "immediate_join" }), "Join immediately"] }))] }))] })), _jsxs(DialogFooter, { children: [_jsx(DialogClose, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "secondary", onClick: closeDialog }, { children: "Cancel" })) })), _jsx(Button, Object.assign({ onClick: handleCreateCall, disabled: submitting }, { children: submitting ? "Creating..." : "Create" }))] })] })) }))] }));
};
